import Constants from './business_constants'

export default {

  isValidOffercode(str) {
    // TODO: update validation when format is confirmed
    return str.length > 0
  },
  isValidMoney(str) {
    // Decimal and commas optional
    var regex = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/;

    return regex.test(str)
  },
  isValidLoanAmount(str) {
    const dollarAmount = parseFloat(str);

    if (dollarAmount < 2500 || dollarAmount > 25000) {
      return false
    }

    return true
  },
  isValidAcctNum(str) {
    const num = Number(str);

    if (Number.isInteger(num)) {
      if ((num.toString().length > 4) && (num.toString().length < 18)) {
        return true
      }
    }

    return false
  },
  isValidRoutingNum(str) {
    const num = Number(str);

    if (Number.isInteger(num)) {
      if (num.toString().length == 9) {
        return true
      }
    }

    return false
  },
  isValidMonthlyAmount(str) {
    const dollarAmount = parseFloat(str);

    if (dollarAmount < 0 || dollarAmount > 99999) {
      return false
    }

    return true
  },
  isValidEmail(str) {
    const emailValidationRegex2 = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if (!str.match(emailValidationRegex2)) {
      return false;
    }
    let email = str.split("@")
    if ((email.length != 2) || (email[0].length > 64) || (email[1].length > 66)) {
      return false
    }

    return true;
  },
  isValidName(str) {
    const regex = /^[a-zA-Z ^\-'`āēīōūĀĒĪŌŪ]+$/

    if (!str.match(regex)) {
      return false;
    }

    return true;
  },
  isValidSSN(str) {
    const ssnRegex = /^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/

    if (!str.match(ssnRegex)) {
      return false;
    }

    return true;
  },
  isDuplicateSSN(appSSN, coappSSN) {
    if (appSSN && coappSSN) {
      console.log('1. both ssn exists')
      if (appSSN == coappSSN) {
        console.log('2. duplicate SSN')
        return true
      }
    }

    console.log('3. not duplicate SSN')
    return false
  },
  isValidLast4SSN(str) {
    const fourNumRegex = /^[0-9]{4}$/

    if (!str.match(fourNumRegex)) {
      return false;
    } else {
      //
    }

    return true;
  },
  calculateAge(dob) { // birthday is a date
    var ageDifMs = Date.now() - dob;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  },
  isValidDate(strDate, minAge) {
    if (strDate.length != 10) {
      return false
    }

    const dateRegex = /^((0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)?[0-9]{2})*$/

    if (!strDate.match(dateRegex)) {
      return false;
    } else if (minAge) {
      const dob = new Date(strDate)
      const age = this.calculateAge(dob)

      if (age >= minAge) {
        return true
      } else {
        return false
      }
    }

    return true;
  },

  isValidDateFormat(str) {
    if (str.length != 10) {
      return false
    }
    const dateRegex = /^((0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)?[0-9]{2})*$/

    if (!str.match(dateRegex)) {
      return false
    }

    return true;
  },

  isValidPhoneNumber(str) {
    const phoneRegex = /^[0-9]{10}$/

    const num = str.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '').replace('+1', '')

    if (!num.match(phoneRegex)) {
      return false;
    }

    if (!Constants.AreaCodes.includes(parseInt(num.substring(0,3)))) {
      return false 
    }

    return true;
  },
  isValidZipcode(zip) {
    const zipcodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/

    if (!zip.match(zipcodeRegex)) {
      return false;
    }
    return true;
  },
  isValidZipRegion(zip, region) {
    if (!this.isValidZipcode(zip)) return false

    switch (region) {
      case "HI": 
        return Constants.HawaiiZipcodes.includes(Number(zip))
      case "GU": 
        return Constants.GuamZipcodes.includes(Number(zip))
      case "MP": 
        return Constants.SaipanZipcodes.includes(Number(zip))
      default:
        return Constants.CNMIZipcodes.includes(Number(zip))
    }
  },
  isValidNumber(str) {
    const numberRegex = /^[0-9]+$/

    return String(str).match(numberRegex)
  },
  isValidFile(file) {
    let validMimeTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/zip',
      'application/x-zip-compressed',
      'image/png',
      'image/jpeg',
      'image/tiff',
      'image/gif',
    ];
    let validation = false
    let maxFileSize = 4000000
    if (window.location.href.indexOf('dev.') > -1 || window.location.href.indexOf('staging.') > -1 || window.location.href.indexOf('localhost') > -1) {
      maxFileSize = 1000000
    }
    if (validMimeTypes.includes(file.type) && file.size <= maxFileSize) {
      validation = true
    }
    return validation
  },
  isValidFileWithCallback(file, callback, aid, index) {
    let validMimeTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/zip',
      'application/x-zip-compressed',
      'image/png',
      'image/jpeg',
      'image/tiff',
      'image/gif',
    ];
    let validation = false
    let maxFileSize = 4000000
    if (window.location.href.indexOf('dev.') > -1 || window.location.href.indexOf('staging.') > -1 || window.location.href.indexOf('localhost') > -1) {
      maxFileSize = 1000000
    }
    if (validMimeTypes.includes(file.type) && file.size <= maxFileSize) {
      validation = true
    }
    callback(validation, aid, index, file);
  },
  isValidUploadedFileWithCallback(file, callback, index) {
    let validMimeTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/zip',
      'application/x-zip-compressed',
      'image/png',
      'image/jpeg',
      'image/tiff',
      'image/gif',
    ];
    let validation = false
    let maxFileSize = 5000000
    if (window.location.href.indexOf('dev.') > -1 || window.location.href.indexOf('staging.') > -1 || window.location.href.indexOf('localhost') > -1) {
      maxFileSize = 1000000
    }
    if (validMimeTypes.includes(file.type) && file.size <= maxFileSize) {
      validation = true
    }
    callback(validation, index, file);
  },

  containsPOBox(addr) {
    console.log('po box', addr)
    if (addr.toLowerCase().match(/p[.\s]*o[.\s]*b[.\s]*o[.\s]*x/g)) return true

    return false
  },
}
