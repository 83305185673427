export default {
  "EthnicityOptions": [
    { label: "Cuban", value: "Cuban" },
    { label: "Mexican", value: "Mexican" },
    { label: "Puerto Rican", value: "Puerto Rican" },
    { label: "American Indian or Alaska Native", value: "American Indian or Alaska Native" },
    { label: "Asian Indian", value: "Asian Indian" },
    { label: "Chinese", value: "Chinese" },
    { label: "Filipino", value: "Filipino" },
    { label: "Japanese", value: "Japanese" },
    { label: "Korean", value: "Korean" },
    { label: "Vietnamese", value: "Vietnamese" },
    { label: "African American", value: "African American" },
    { label: "Ethiopian", value: "Ethiopian" },
    { label: "Haitian", value: "Haitian" },
    { label: "Jamaican", value: "Jamaican" },
    { label: "Nigerian", value: "Nigerian" },
    { label: "Somali", value: "Somali" },
    { label: "Guamanian or Chamorro", value: "Guamanian or Chamorro" },
    { label: "Native Hawaiian", value: "Native Hawaiian" },
    { label: "Samoan", value: "Samoan" },
    { label: "White", value: "White" },
    { label: "Other", value: "Other" },
    { label: "I do not wish to provide this information", value: "N/A" },
  ],
  "CompanyPositionsCorp": [
    { key: 'President', value: 'President' },
    { key: 'Vice President', value: 'Vice President' },
    { key: 'Secretary', value: 'Secretary' },
    { key: 'Treasurer', value: 'Treasurer' },
    { key: 'CEO', value: 'CEO' },
    { key: 'CFO', value: 'CFO' },
    { key: 'COO', value: 'COO' },
    { key: 'Other', value: 'Other' }
  ],
  "CompanyPositionsLLC": [
    { key: 'Member Manager', value: 'Member Manager' },
    { key: 'Manager', value: 'Manager' },
    { key: 'Other', value: 'Other' }
  ],
  "CompanyPositionsPartnership": [
    { key: 'Partner', value: 'Partner' },
    { key: 'General Partner', value: 'General Partner' },
    { key: 'Limited Partner', value: 'Limited Partner' },
    { key: 'Other', value: 'Other' }
  ],
  "CompanyStructure": [
    { key: 'Corporation', value: 'corp' },
    //{ key: 'Professional Corporation', value: 'prof-corp' },
    { key: 'Limited Liability Company (LLC)', value: 'llc' },
    //{ key: 'Single LLC', value: 'single-llc' },
    { key: 'General Partnership', value: 'partnership' },
    { key: 'Limited Partnership', value: 'limited' },
    { key: 'Sole Proprietorship', value: 'sole' },
    { key: 'Other (.e.g, non-profit)', value: 'other' },
  ],
  "NatureOfBusiness": [
    { key: "11 Agriculture, Forestry, Fishing and HuntingT", value: "11" },
    { key: "111 Crop ProductionT ", value: "111" },
    { key: "1111 Oilseed and Grain FarmingT", value: "1111" },
    { key: "11111 Soybean FarmingT", value: "11111" },
    { key: "111110 Soybean Farming", value: "111110" },
    { key: "11112 Oilseed (except Soybean) FarmingT ", value: "11112" },
    { key: "111120 Oilseed (except Soybean) Farming ", value: "111120" },
    { key: "11113 Dry Pea and Bean FarmingT", value: "11113" },
    { key: "111130 Dry Pea and Bean Farming ", value: "111130" },
    { key: "11114 Wheat FarmingT", value: "11114" },
    { key: "111140 Wheat Farming", value: "111140" },
    { key: "11115 Corn FarmingT", value: "11115" },
    { key: "111150 Corn Farming ", value: "111150" },
    { key: "11116 Rice FarmingT", value: "11116" },
    { key: "111160 Rice Farming", value: "111160" },
    { key: "11119 Other Grain FarmingT", value: "11119" },
    { key: "111191 Oilseed and Grain Combination Farming ", value: "111191" },
    { key: "111199 All Other Grain Farming ", value: "111199" },
    { key: "1112 Vegetable and Melon FarmingT ", value: "1112" },
    { key: "11121 Vegetable and Melon FarmingT", value: "11121" },
    { key: "111211 Potato Farming ", value: "111211" },
    { key: "111219 Other Vegetable (except Potato) and Melon Farming ", value: "111219" },
    { key: "1113 Fruit and Tree Nut FarmingT ", value: "1113" },
    { key: "11131 Orange GrovesT", value: "11131" },
    { key: "111310 Orange Groves", value: "111310" },
    { key: "11132 Citrus (except Orange) GrovesT ", value: "11132" },
    { key: "111320 Citrus (except Orange) Groves ", value: "111320" },
    { key: "11133 Noncitrus Fruit and Tree Nut FarmingT", value: "11133" },
    { key: "111331 Apple Orchards ", value: "111331" },
    { key: "111332 Grape Vineyards ", value: "111332" },
    { key: "111333 Strawberry Farming ", value: "111333" },
    { key: "111334 Berry (except Strawberry) Farming ", value: "111334" },
    { key: "111335 Tree Nut Farming ", value: "111335" },
    { key: "111336 Fruit and Tree Nut Combination Farming ", value: "111336" },
    { key: "111339 Other Noncitrus Fruit Farming ", value: "111339" },
    { key: "1114 Greenhouse, Nursery, and Floriculture ProductionT ", value: "1114" },
    { key: "11141 Food Crops Grown Under CoverT", value: "11141" },
    { key: "111411 Mushroom Production ", value: "111411" },
    { key: "111419 Other Food Crops Grown Under Cover ", value: "111419" },
    { key: "11142 Nursery and Floriculture ProductionT", value: "11142" },
    { key: "111421 Nursery and Tree Production ", value: "111421" },
    { key: "111422 Floriculture Production ", value: "111422" },
    { key: "1119 Other Crop FarmingT ", value: "1119" },
    { key: "11191 Tobacco FarmingT", value: "11191" },
    { key: "111910 Tobacco Farming", value: "111910" },
    { key: "11192 Cotton FarmingT", value: "11192" },
    { key: "111920 Cotton Farming", value: "111920" },
    { key: "11193 Sugarcane FarmingT ", value: "11193" },
    { key: "111930 Sugarcane Farming", value: "111930" },
    { key: "11194 Hay FarmingT ", value: "11194" },
    { key: "111940 Hay Farming ", value: "111940" },
    { key: "11199 All Other Crop FarmingT ", value: "11199" },
    { key: "111991 Sugar Beet Farming ", value: "111991" },
    { key: "111992 Peanut Farming ", value: "111992" },
    { key: "111998 All Other Miscellaneous Crop Farming ", value: "111998" },
    { key: "112 Animal Production and AquacultureT ", value: "112" },
    { key: "1121 Cattle Ranching and FarmingT ", value: "1121" },
    { key: "11211 Beef Cattle Ranching and Farming, including FeedlotsT ", value: "11211" },
    { key: "112111 Beef Cattle Ranching and Farming ", value: "112111" },
    { key: "112112 Cattle Feedlots ", value: "112112" },
    { key: "11212 Dairy Cattle and Milk ProductionT", value: "11212" },
    { key: "112120 Dairy Cattle and Milk Production", value: "112120" },
    { key: "11213 Dual-Purpose Cattle Ranching and FarmingT ", value: "11213" },
    { key: "112130 Dual-Purpose Cattle Ranching and Farming ", value: "112130" },
    { key: "1122 Hog and Pig FarmingT ", value: "1122" },
    { key: "11221 Hog and Pig FarmingT ", value: "11221" },
    { key: "112210 Hog and Pig Farming ", value: "112210" },
    { key: "1123 Poultry and Egg ProductionT ", value: "1123" },
    { key: "11231 Chicken Egg ProductionT ", value: "11231" },
    { key: "112310 Chicken Egg Production ", value: "112310" },
    { key: "11232 Broilers and Other Meat Type Chicken ProductionT ", value: "11232" },
    { key: "112320 Broilers and Other Meat Type Chicken Production ", value: "112320" },
    { key: "11233 Turkey ProductionT", value: "11233" },
    { key: "112330 Turkey Production", value: "112330" },
    { key: "11234 Poultry HatcheriesT ", value: "11234" },
    { key: "112340 Poultry Hatcheries", value: "112340" },
    { key: "11239 Other Poultry ProductionT ", value: "11239" },
    { key: "112390 Other Poultry Production ", value: "112390" },
    { key: "1124 Sheep and Goat FarmingT ", value: "1124" },
    { key: "11241 Sheep FarmingT ", value: "11241" },
    { key: "112410 Sheep Farming", value: "112410" },
    { key: "11242 Goat FarmingT", value: "11242" },
    { key: "112420 Goat Farming", value: "112420" },
    { key: "1125 AquacultureT ", value: "1125" },
    { key: "11251 AquacultureT ", value: "11251" },
    { key: "112511 Finfish Farming and Fish Hatcheries ", value: "112511" },
    { key: "112512 Shellfish Farming ", value: "112512" },
    { key: "112519 Other Aquaculture ", value: "112519" },
    { key: "1129 Other Animal ProductionT ", value: "1129" },
    { key: "11291 ApicultureT", value: "11291" },
    { key: "112910 Apiculture", value: "112910" },
    { key: "11292 Horses and Other Equine ProductionT ", value: "11292" },
    { key: "112920 Horses and Other Equine Production", value: "112920" },
    { key: "11293 Fur-Bearing Animal and Rabbit ProductionT ", value: "11293" },
    { key: "112930 Fur-Bearing Animal and Rabbit Production", value: "112930" },
    { key: "11299 All Other Animal ProductionT ", value: "11299" },
    { key: "112990 All Other Animal Production ", value: "112990" },
    { key: "113 Forestry and LoggingT ", value: "113" },
    { key: "1131 Timber Tract OperationsT ", value: "1131" },
    { key: "11311 Timber Tract OperationsT ", value: "11311" },
    { key: "113110 Timber Tract Operations", value: "113110" },
    { key: "1132 Forest Nurseries and Gathering of Forest ProductsT ", value: "1132" },
    { key: "11321 Forest Nurseries and Gathering of Forest ProductsT ", value: "11321" },
    { key: "113210 Forest Nurseries and Gathering of Forest Products ", value: "113210" },
    { key: "1133 LoggingT ", value: "1133" },
    { key: "11331 LoggingT ", value: "11331" },
    { key: "113310 Logging ", value: "113310" },
    { key: "114 Fishing, Hunting and TrappingT ", value: "114" },
    { key: "1141 FishingT ", value: "1141" },
    { key: "11411 FishingT ", value: "11411" },
    { key: "114111 Finfish Fishing ", value: "114111" },
    { key: "114112 Shellfish Fishing ", value: "114112" },
    { key: "114119 Other Marine Fishing ", value: "114119" },
    { key: "1142 Hunting and TrappingT ", value: "1142" },
    { key: "11421 Hunting and TrappingT ", value: "11421" },
    { key: "114210 Hunting and Trapping", value: "114210" },
    { key: "115 Support Activities for Agriculture and ForestryT ", value: "115" },
    { key: "1151 Support Activities for Crop ProductionT ", value: "1151" },
    { key: "11511 Support Activities for Crop ProductionT ", value: "11511" },
    { key: "115111 Cotton Ginning ", value: "115111" },
    { key: "115112 Soil Preparation, Planting, and Cultivating ", value: "115112" },
    { key: "115113 Crop Harvesting, Primarily by Machine ", value: "115113" },
    { key: "115114 Postharvest Crop Activities (except Cotton Ginning) ", value: "115114" },
    { key: "115115 Farm Labor Contractors and Crew Leaders ", value: "115115" },
    { key: "115116 Farm Management Services ", value: "115116" },
    { key: "1152 Support Activities for Animal ProductionT ", value: "1152" },
    { key: "11521 Support Activities for Animal ProductionT ", value: "11521" },
    { key: "115210 Support Activities for Animal Production", value: "115210" },
    { key: "1153 Support Activities for ForestryT ", value: "1153" },
    { key: "11531 Support Activities for ForestryT ", value: "11531" },
    { key: "115310 Support Activities for Forestry", value: "115310" },
    { key: "21 Mining, Quarrying, and Oil and Gas ExtractionT", value: "21" },
    { key: "211 Oil and Gas ExtractionT ", value: "211" },
    { key: "2111 Oil and Gas ExtractionT ", value: "2111" },
    { key: "21112 Crude Petroleum Extraction ", value: "21112" },
    { key: "211120 Crude Petroleum Extraction ", value: "211120" },
    { key: "21113 Natural Gas Extraction ", value: "21113" },
    { key: "211130 Natural Gas Extraction ", value: "211130" },
    { key: "212 Mining (except Oil and Gas)T ", value: "212" },
    { key: "2121 Coal MiningT ", value: "2121" },
    { key: "21211 Coal MiningT ", value: "21211" },
    { key: "212114 Surface Coal Mining", value: "212114" },
    { key: "212115 Underground Coal Mining", value: "212115" },
    { key: "2122 Metal Ore MiningT ", value: "2122" },
    { key: "21221 Iron Ore MiningT ", value: "21221" },
    { key: "212210 Iron Ore Mining", value: "212210" },
    { key: "21222 Gold Ore and Silver Ore MiningT ", value: "21222" },
    { key: "212220 Gold Ore and Silver Ore Mining", value: "212220" },
    { key: "21223 Copper, Nickel, Lead, and Zinc MiningT ", value: "21223" },
    { key: "212230 Copper, Nickel, Lead, and Zinc Mining", value: "212230" },
    { key: "21229 Other Metal Ore MiningT ", value: "21229" },
    { key: "212290 Other Metal Ore Mining", value: "212290" },
    { key: "2123 Nonmetallic Mineral Mining and QuarryingT ", value: "2123" },
    { key: "21231 Stone Mining and QuarryingT ", value: "21231" },
    { key: "212311 Dimension Stone Mining and Quarrying ", value: "212311" },
    { key: "212312 Crushed and Broken Limestone Mining and Quarrying ", value: "212312" },
    { key: "212313 Crushed and Broken Granite Mining and Quarrying ", value: "212313" },
    { key: "212319 Other Crushed and Broken Stone Mining and Quarrying ", value: "212319" },
    { key: "21232 Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and QuarryingT ", value: "21232" },
    { key: "212321 Construction Sand and Gravel Mining ", value: "212321" },
    { key: "212322 Industrial Sand Mining ", value: "212322" },
    { key: "212323 Kaolin, Clay, and Ceramic and Refractory Minerals Mining ", value: "212323" },
    { key: "21239 Other Nonmetallic Mineral Mining and QuarryingT ", value: "21239" },
    { key: "212390 Other Nonmetallic Mineral Mining and Quarrying ", value: "212390" },
    { key: "213 Support Activities for MiningT ", value: "213" },
    { key: "2131 Support Activities for MiningT ", value: "2131" },
    { key: "21311 Support Activities for MiningT ", value: "21311" },
    { key: "213111 Drilling Oil and Gas Wells", value: "213111" },
    { key: "213112 Support Activities for Oil and Gas Operations ", value: "213112" },
    { key: "213113 Support Activities for Coal Mining ", value: "213113" },
    { key: "213114 Support Activities for Metal Mining ", value: "213114" },
    { key: "213115 Support Activities for Nonmetallic Minerals (except Fuels) Mining ", value: "213115" },
    { key: "22 UtilitiesT", value: "22" },
    { key: "221 UtilitiesT ", value: "221" },
    { key: "2211 Electric Power Generation, Transmission and DistributionT", value: "2211" },
    { key: "22111 Electric Power GenerationT ", value: "22111" },
    { key: "221111 Hydroelectric Power Generation ", value: "221111" },
    { key: "221112 Fossil Fuel Electric Power Generation ", value: "221112" },
    { key: "221113 Nuclear Electric Power Generation ", value: "221113" },
    { key: "221114 Solar Electric Power Generation ", value: "221114" },
    { key: "221115 Wind Electric Power Generation ", value: "221115" },
    { key: "221116 Geothermal Electric Power Generation ", value: "221116" },
    { key: "221117 Biomass Electric Power Generation ", value: "221117" },
    { key: "221118 Other Electric Power Generation ", value: "221118" },
    { key: "22112 Electric Power Transmission, Control, and DistributionT ", value: "22112" },
    { key: "221121 Electric Bulk Power Transmission and Control ", value: "221121" },
    { key: "221122 Electric Power Distribution ", value: "221122" },
    { key: "2212 Natural Gas DistributionT ", value: "2212" },
    { key: "22121 Natural Gas DistributionT ", value: "22121" },
    { key: "221210 Natural Gas Distribution ", value: "221210" },
    { key: "2213 Water, Sewage and Other SystemsT ", value: "2213" },
    { key: "22131 Water Supply and Irrigation Systems ", value: "22131" },
    { key: "221310 Water Supply and Irrigation Systems ", value: "221310" },
    { key: "22132 Sewage Treatment Facilities ", value: "22132" },
    { key: "221320 Sewage Treatment Facilities ", value: "221320" },
    { key: "22133 Steam and Air-Conditioning Supply ", value: "22133" },
    { key: "221330 Steam and Air-Conditioning Supply ", value: "221330" },
    { key: "23 ConstructionT", value: "23" },
    { key: "236 Construction of BuildingsT ", value: "236" },
    { key: "2361 Residential Building ConstructionT ", value: "2361" },
    { key: "23611 Residential Building ConstructionT ", value: "23611" },
    { key: "236115 New Single-Family Housing Construction (except For-Sale Builders) ", value: "236115" },
    { key: "236116 New Multifamily Housing Construction (except For-Sale Builders) ", value: "236116" },
    { key: "236117 New Housing For-Sale Builders ", value: "236117" },
    { key: "236118 Residential Remodelers ", value: "236118" },
    { key: "2362 Nonresidential Building ConstructionT ", value: "2362" },
    { key: "23621 Industrial Building ConstructionT ", value: "23621" },
    { key: "236210 Industrial Building Construction ", value: "236210" },
    { key: "23622 Commercial and Institutional Building ConstructionT ", value: "23622" },
    { key: "236220 Commercial and Institutional Building Construction ", value: "236220" },
    { key: "237 Heavy and Civil Engineering ConstructionT ", value: "237" },
    { key: "2371 Utility System ConstructionT", value: "2371" },
    { key: "23711 Water and Sewer Line and Related Structures ConstructionT ", value: "23711" },
    { key: "237110 Water and Sewer Line and Related Structures Construction ", value: "237110" },
    { key: "23712 Oil and Gas Pipeline and Related Structures ConstructionT ", value: "23712" },
    { key: "237120 Oil and Gas Pipeline and Related Structures Construction ", value: "237120" },
    { key: "23713 Power and Communication Line and Related Structures ConstructionT ", value: "23713" },
    { key: "237130 Power and Communication Line and Related Structures Construction ", value: "237130" },
    { key: "2372 Land SubdivisionT ", value: "2372" },
    { key: "23721 Land SubdivisionT ", value: "23721" },
    { key: "237210 Land Subdivision ", value: "237210" },
    { key: "2373 Highway, Street, and Bridge ConstructionT ", value: "2373" },
    { key: "23731 Highway, Street, and Bridge ConstructionT ", value: "23731" },
    { key: "237310 Highway, Street, and Bridge Construction ", value: "237310" },
    { key: "2379 Other Heavy and Civil Engineering ConstructionT ", value: "2379" },
    { key: "23799 Other Heavy and Civil Engineering ConstructionT", value: "23799" },
    { key: "237990 Other Heavy and Civil Engineering Construction ", value: "237990" },
    { key: "238 Specialty Trade ContractorsT ", value: "238" },
    { key: "2381 Foundation, Structure, and Building Exterior ContractorsT ", value: "2381" },
    { key: "23811 Poured Concrete Foundation and Structure Contractors ", value: "23811" },
    { key: "238110 Poured Concrete Foundation and Structure Contractors ", value: "238110" },
    { key: "23812 Structural Steel and Precast Concrete Contractors ", value: "23812" },
    { key: "238120 Structural Steel and Precast Concrete Contractors ", value: "238120" },
    { key: "23813 Framing Contractors ", value: "23813" },
    { key: "238130 Framing Contractors ", value: "238130" },
    { key: "23814 Masonry Contractors ", value: "23814" },
    { key: "238140 Masonry Contractors ", value: "238140" },
    { key: "23815 Glass and Glazing Contractors ", value: "23815" },
    { key: "238150 Glass and Glazing Contractors ", value: "238150" },
    { key: "23816 Roofing Contractors ", value: "23816" },
    { key: "238160 Roofing Contractors ", value: "238160" },
    { key: "23817 Siding Contractors ", value: "23817" },
    { key: "238170 Siding Contractors ", value: "238170" },
    { key: "23819 Other Foundation, Structure, and Building Exterior Contractors ", value: "23819" },
    { key: "238190 Other Foundation, Structure, and Building Exterior Contractors ", value: "238190" },
    { key: "2382 Building Equipment ContractorsT ", value: "2382" },
    { key: "23821 Electrical Contractors and Other Wiring Installation ContractorsT ", value: "23821" },
    { key: "238210 Electrical Contractors and Other Wiring Installation Contractors", value: "238210" },
    { key: "23822 Plumbing, Heating, and Air-Conditioning ContractorsT ", value: "23822" },
    { key: "238220 Plumbing, Heating, and Air-Conditioning Contractors ", value: "238220" },
    { key: "23829 Other Building Equipment ContractorsT ", value: "23829" },
    { key: "238290 Other Building Equipment Contractors ", value: "238290" },
    { key: "2383 Building Finishing ContractorsT ", value: "2383" },
    { key: "23831 Drywall and Insulation ContractorsT ", value: "23831" },
    { key: "238310 Drywall and Insulation Contractors ", value: "238310" },
    { key: "23832 Painting and Wall Covering ContractorsT ", value: "23832" },
    { key: "238320 Painting and Wall Covering Contractors", value: "238320" },
    { key: "23833 Flooring ContractorsT ", value: "23833" },
    { key: "238330 Flooring Contractors", value: "238330" },
    { key: "23834 Tile and Terrazzo ContractorsT", value: "23834" },
    { key: "238340 Tile and Terrazzo Contractors", value: "238340" },
    { key: "23835 Finish Carpentry ContractorsT ", value: "23835" },
    { key: "238350 Finish Carpentry Contractors", value: "238350" },
    { key: "23839 Other Building Finishing ContractorsT ", value: "23839" },
    { key: "238390 Other Building Finishing Contractors", value: "238390" },
    { key: "2389 Other Specialty Trade ContractorsT ", value: "2389" },
    { key: "23891 Site Preparation ContractorsT ", value: "23891" },
    { key: "238910 Site Preparation Contractors", value: "238910" },
    { key: "23899 All Other Specialty Trade ContractorsT ", value: "23899" },
    { key: "238990 All Other Specialty Trade Contractors", value: "238990" },
    { key: "31-33 ManufacturingT", value: "31-33" },
    { key: "311 Food ManufacturingT ", value: "311" },
    { key: "3111 Animal Food ManufacturingT ", value: "3111" },
    { key: "31111 Animal Food ManufacturingT ", value: "31111" },
    { key: "311111 Dog and Cat Food Manufacturing ", value: "311111" },
    { key: "311119 Other Animal Food Manufacturing ", value: "311119" },
    { key: "3112 Grain and Oilseed MillingT ", value: "3112" },
    { key: "31121 Flour Milling and Malt ManufacturingT ", value: "31121" },
    { key: "311211 Flour Milling ", value: "311211" },
    { key: "311212 Rice Milling ", value: "311212" },
    { key: "311213 Malt Manufacturing ", value: "311213" },
    { key: "31122 Starch and Vegetable Fats and Oils ManufacturingT ", value: "31122" },
    { key: "311221 Wet Corn Milling and Starch Manufacturing ", value: "311221" },
    { key: "311224 Soybean and Other Oilseed Processing ", value: "311224" },
    { key: "311225 Fats and Oils Refining and Blending ", value: "311225" },
    { key: "31123 Breakfast Cereal ManufacturingT ", value: "31123" },
    { key: "311230 Breakfast Cereal Manufacturing", value: "311230" },
    { key: "3113 Sugar and Confectionery Product ManufacturingT ", value: "3113" },
    { key: "31131 Sugar ManufacturingT ", value: "31131" },
    { key: "311313 Beet Sugar Manufacturing ", value: "311313" },
    { key: "311314 Cane Sugar Manufacturing ", value: "311314" },
    { key: "31134 Nonchocolate Confectionery ManufacturingT ", value: "31134" },
    { key: "311340 Nonchocolate Confectionery Manufacturing", value: "311340" },
    { key: "31135 Chocolate and Confectionery ManufacturingT ", value: "31135" },
    { key: "311351 Chocolate and Confectionery Manufacturing from Cacao Beans ", value: "311351" },
    { key: "311352 Confectionery Manufacturing from Purchased Chocolate ", value: "311352" },
    { key: "3114 Fruit and Vegetable Preserving and Specialty Food ManufacturingT ", value: "3114" },
    { key: "31141 Frozen Food ManufacturingT ", value: "31141" },
    { key: "311411 Frozen Fruit, Juice, and Vegetable Manufacturing ", value: "311411" },
    { key: "311412 Frozen Specialty Food Manufacturing ", value: "311412" },
    { key: "31142 Fruit and Vegetable Canning, Pickling, and DryingT ", value: "31142" },
    { key: "311421 Fruit and Vegetable Canning ", value: "311421" },
    { key: "311422 Specialty Canning ", value: "311422" },
    { key: "311423 Dried and Dehydrated Food Manufacturing ", value: "311423" },
    { key: "3115 Dairy Product ManufacturingT ", value: "3115" },
    { key: "31151 Dairy Product (except Frozen) ManufacturingT ", value: "31151" },
    { key: "311511 Fluid Milk Manufacturing ", value: "311511" },
    { key: "311512 Creamery Butter Manufacturing ", value: "311512" },
    { key: "311513 Cheese Manufacturing ", value: "311513" },
    { key: "311514 Dry, Condensed, and Evaporated Dairy Product Manufacturing ", value: "311514" },
    { key: "31152 Ice Cream and Frozen Dessert ManufacturingT ", value: "31152" },
    { key: "311520 Ice Cream and Frozen Dessert Manufacturing", value: "311520" },
    { key: "3116 Animal Slaughtering and ProcessingT ", value: "3116" },
    { key: "31161 Animal Slaughtering and ProcessingT ", value: "31161" },
    { key: "311611 Animal (except Poultry) Slaughtering ", value: "311611" },
    { key: "311612 Meat Processed from Carcasses ", value: "311612" },
    { key: "311613 Rendering and Meat Byproduct Processing ", value: "311613" },
    { key: "311615 Poultry Processing ", value: "311615" },
    { key: "3117 Seafood Product Preparation and PackagingT ", value: "3117" },
    { key: "31171 Seafood Product Preparation and PackagingT ", value: "31171" },
    { key: "311710 Seafood Product Preparation and Packaging", value: "311710" },
    { key: "3118 Bakeries and Tortilla ManufacturingT ", value: "3118" },
    { key: "31181 Bread and Bakery Product ManufacturingT ", value: "31181" },
    { key: "311811 Retail Bakeries ", value: "311811" },
    { key: "311812 Commercial Bakeries ", value: "311812" },
    { key: "311813 Frozen Cakes, Pies, and Other Pastries Manufacturing ", value: "311813" },
    { key: "31182 Cookie, Cracker, and Pasta ManufacturingT ", value: "31182" },
    { key: "311821 Cookie and Cracker Manufacturing ", value: "311821" },
    { key: "311824 Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour ", value: "311824" },
    { key: "31183 Tortilla ManufacturingT ", value: "31183" },
    { key: "311830 Tortilla Manufacturing", value: "311830" },
    { key: "3119 Other Food ManufacturingT ", value: "3119" },
    { key: "31191 Snack Food ManufacturingT ", value: "31191" },
    { key: "311911 Roasted Nuts and Peanut Butter Manufacturing ", value: "311911" },
    { key: "311919 Other Snack Food Manufacturing ", value: "311919" },
    { key: "31192 Coffee and Tea ManufacturingT ", value: "31192" },
    { key: "311920 Coffee and Tea Manufacturing ", value: "311920" },
    { key: "31193 Flavoring Syrup and Concentrate ManufacturingT ", value: "31193" },
    { key: "311930 Flavoring Syrup and Concentrate Manufacturing", value: "311930" },
    { key: "31194 Seasoning and Dressing ManufacturingT ", value: "31194" },
    { key: "311941 Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing ", value: "311941" },
    { key: "311942 Spice and Extract Manufacturing ", value: "311942" },
    { key: "31199 All Other Food ManufacturingT ", value: "31199" },
    { key: "311991 Perishable Prepared Food Manufacturing ", value: "311991" },
    { key: "311999 All Other Miscellaneous Food Manufacturing ", value: "311999" },
    { key: "312 Beverage and Tobacco Product ManufacturingT ", value: "312" },
    { key: "3121 Beverage ManufacturingT ", value: "3121" },
    { key: "31211 Soft Drink and Ice ManufacturingT ", value: "31211" },
    { key: "312111 Soft Drink Manufacturing ", value: "312111" },
    { key: "312112 Bottled Water Manufacturing ", value: "312112" },
    { key: "312113 Ice Manufacturing ", value: "312113" },
    { key: "31212 BreweriesT ", value: "31212" },
    { key: "312120 Breweries", value: "312120" },
    { key: "31213 WineriesT ", value: "31213" },
    { key: "312130 Wineries ", value: "312130" },
    { key: "31214 DistilleriesT ", value: "31214" },
    { key: "312140 Distilleries ", value: "312140" },
    { key: "3122 Tobacco ManufacturingT ", value: "3122" },
    { key: "31223 Tobacco Manufacturing", value: "31223" },
    { key: "312230 Tobacco Manufacturing ", value: "312230" },
    { key: "313 Textile MillsT ", value: "313" },
    { key: "3131 Fiber, Yarn, and Thread MillsT ", value: "3131" },
    { key: "31311 Fiber, Yarn, and Thread MillsT ", value: "31311" },
    { key: "313110 Fiber, Yarn, and Thread Mills ", value: "313110" },
    { key: "3132 Fabric MillsT ", value: "3132" },
    { key: "31321 Broadwoven Fabric MillsT ", value: "31321" },
    { key: "313210 Broadwoven Fabric Mills", value: "313210" },
    { key: "31322 Narrow Fabric Mills and Schiffli Machine EmbroideryT ", value: "31322" },
    { key: "313220 Narrow Fabric Mills and Schiffli Machine Embroidery", value: "313220" },
    { key: "31323 Nonwoven Fabric MillsT ", value: "31323" },
    { key: "313230 Nonwoven Fabric Mills", value: "313230" },
    { key: "31324 Knit Fabric MillsT ", value: "31324" },
    { key: "313240 Knit Fabric Mills", value: "313240" },
    { key: "3133 Textile and Fabric Finishing and Fabric Coating MillsT ", value: "3133" },
    { key: "31331 Textile and Fabric Finishing MillsT", value: "31331" },
    { key: "313310 Textile and Fabric Finishing Mills ", value: "313310" },
    { key: "31332 Fabric Coating MillsT ", value: "31332" },
    { key: "313320 Fabric Coating Mills", value: "313320" },
    { key: "314 Textile Product MillsT ", value: "314" },
    { key: "3141 Textile Furnishings MillsT ", value: "3141" },
    { key: "31411 Carpet and Rug MillsT ", value: "31411" },
    { key: "314110 Carpet and Rug Mills", value: "314110" },
    { key: "31412 Curtain and Linen MillsT ", value: "31412" },
    { key: "314120 Curtain and Linen Mills", value: "314120" },
    { key: "3149 Other Textile Product MillsT ", value: "3149" },
    { key: "31491 Textile Bag and Canvas MillsT ", value: "31491" },
    { key: "314910 Textile Bag and Canvas Mills ", value: "314910" },
    { key: "31499 All Other Textile Product MillsT ", value: "31499" },
    { key: "314994 Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills ", value: "314994" },
    { key: "314999 All Other Miscellaneous Textile Product Mills ", value: "314999" },
    { key: "315 Apparel ManufacturingT ", value: "315" },
    { key: "3151 Apparel Knitting MillsT ", value: "3151" },
    { key: "31512 Apparel Knitting MillsT ", value: "31512" },
    { key: "315120 Apparel Knitting Mills", value: "315120" },
    { key: "3152 Cut and Sew Apparel ManufacturingT ", value: "3152" },
    { key: "31521 Cut and Sew Apparel Contractors ", value: "31521" },
    { key: "315210 Cut and Sew Apparel Contractors ", value: "315210" },
    { key: "31525 Cut and Sew Apparel Manufacturing (except Contractors) ", value: "31525" },
    { key: "315250 Cut and Sew Apparel Manufacturing (except Contractors) ", value: "315250" },
    { key: "3159 Apparel Accessories and Other Apparel ManufacturingT ", value: "3159" },
    { key: "31599 Apparel Accessories and Other Apparel ManufacturingT ", value: "31599" },
    { key: "315990 Apparel Accessories and Other Apparel Manufacturing ", value: "315990" },
    { key: "316 Leather and Allied Product ManufacturingT ", value: "316" },
    { key: "3161 Leather and Hide Tanning and FinishingT ", value: "3161" },
    { key: "31611 Leather and Hide Tanning and FinishingT ", value: "31611" },
    { key: "316110 Leather and Hide Tanning and Finishing", value: "316110" },
    { key: "3162 Footwear ManufacturingT ", value: "3162" },
    { key: "31621 Footwear ManufacturingT ", value: "31621" },
    { key: "316210 Footwear Manufacturing ", value: "316210" },
    { key: "3169 Other Leather and Allied Product ManufacturingT ", value: "3169" },
    { key: "31699 Other Leather and Allied Product ManufacturingT ", value: "31699" },
    { key: "316990 Other Leather and Allied Product Manufacturing ", value: "316990" },
    { key: "321 Wood Product ManufacturingT ", value: "321" },
    { key: "3211 Sawmills and Wood PreservationT ", value: "3211" },
    { key: "32111 Sawmills and Wood PreservationT ", value: "32111" },
    { key: "321113 Sawmills ", value: "321113" },
    { key: "321114 Wood Preservation ", value: "321114" },
    { key: "3212 Veneer, Plywood, and Engineered Wood Product ManufacturingT ", value: "3212" },
    { key: "32121 Veneer, Plywood, and Engineered Wood Product ManufacturingT ", value: "32121" },
    { key: "321211 Hardwood Veneer and Plywood Manufacturing ", value: "321211" },
    { key: "321212 Softwood Veneer and Plywood Manufacturing ", value: "321212" },
    { key: "321215 Engineered Wood Member Manufacturing ", value: "321215" },
    { key: "321219 Reconstituted Wood Product Manufacturing ", value: "321219" },
    { key: "3219 Other Wood Product ManufacturingT ", value: "3219" },
    { key: "32191 MillworkT ", value: "32191" },
    { key: "321911 Wood Window and Door Manufacturing ", value: "321911" },
    { key: "321912 Cut Stock, Resawing Lumber, and Planing ", value: "321912" },
    { key: "321918 Other Millwork (including Flooring) ", value: "321918" },
    { key: "32192 Wood Container and Pallet ManufacturingT ", value: "32192" },
    { key: "321920 Wood Container and Pallet Manufacturing", value: "321920" },
    { key: "32199 All Other Wood Product ManufacturingT ", value: "32199" },
    { key: "321991 Manufactured Home (Mobile Home) Manufacturing ", value: "321991" },
    { key: "321992 Prefabricated Wood Building Manufacturing ", value: "321992" },
    { key: "321999 All Other Miscellaneous Wood Product Manufacturing ", value: "321999" },
    { key: "322 Paper ManufacturingT ", value: "322" },
    { key: "3221 Pulp, Paper, and Paperboard MillsT ", value: "3221" },
    { key: "32211 Pulp MillsT ", value: "32211" },
    { key: "322110 Pulp Mills ", value: "322110" },
    { key: "32212 Paper MillsT ", value: "32212" },
    { key: "322120 Paper Mills ", value: "322120" },
    { key: "32213 Paperboard MillsT ", value: "32213" },
    { key: "322130 Paperboard Mills ", value: "322130" },
    { key: "3222 Converted Paper Product ManufacturingT ", value: "3222" },
    { key: "32221 Paperboard Container ManufacturingT ", value: "32221" },
    { key: "322211 Corrugated and Solid Fiber Box Manufacturing ", value: "322211" },
    { key: "322212 Folding Paperboard Box Manufacturing ", value: "322212" },
    { key: "322219 Other Paperboard Container Manufacturing ", value: "322219" },
    { key: "32222 Paper Bag and Coated and Treated Paper ManufacturingT ", value: "32222" },
    { key: "322220 Paper Bag and Coated and Treated Paper Manufacturing", value: "322220" },
    { key: "32223 Stationery Product ManufacturingT ", value: "32223" },
    { key: "322230 Stationery Product Manufacturing", value: "322230" },
    { key: "32229 Other Converted Paper Product ManufacturingT ", value: "32229" },
    { key: "322291 Sanitary Paper Product Manufacturing ", value: "322291" },
    { key: "322299 All Other Converted Paper Product Manufacturing ", value: "322299" },
    { key: "323 Printing and Related Support ActivitiesT ", value: "323" },
    { key: "3231 Printing and Related Support ActivitiesT ", value: "3231" },
    { key: "32311 PrintingT ", value: "32311" },
    { key: "323111 Commercial Printing (except Screen and Books) ", value: "323111" },
    { key: "323113 Commercial Screen Printing ", value: "323113" },
    { key: "323117 Books Printing ", value: "323117" },
    { key: "32312 Support Activities for PrintingT ", value: "32312" },
    { key: "323120 Support Activities for Printing", value: "323120" },
    { key: "324 Petroleum and Coal Products ManufacturingT ", value: "324" },
    { key: "3241 Petroleum and Coal Products ManufacturingT ", value: "3241" },
    { key: "32411 Petroleum RefineriesT ", value: "32411" },
    { key: "324110 Petroleum Refineries", value: "324110" },
    { key: "32412 Asphalt Paving, Roofing, and Saturated Materials ManufacturingT ", value: "32412" },
    { key: "324121 Asphalt Paving Mixture and Block Manufacturing ", value: "324121" },
    { key: "324122 Asphalt Shingle and Coating Materials Manufacturing ", value: "324122" },
    { key: "32419 Other Petroleum and Coal Products ManufacturingT ", value: "32419" },
    { key: "324191 Petroleum Lubricating Oil and Grease Manufacturing ", value: "324191" },
    { key: "324199 All Other Petroleum and Coal Products Manufacturing ", value: "324199" },
    { key: "325 Chemical ManufacturingT ", value: "325" },
    { key: "3251 Basic Chemical ManufacturingT ", value: "3251" },
    { key: "32511 Petrochemical ManufacturingT ", value: "32511" },
    { key: "325110 Petrochemical Manufacturing", value: "325110" },
    { key: "32512 Industrial Gas ManufacturingT ", value: "32512" },
    { key: "325120 Industrial Gas Manufacturing", value: "325120" },
    { key: "32513 Synthetic Dye and Pigment ManufacturingT ", value: "32513" },
    { key: "325130 Synthetic Dye and Pigment Manufacturing", value: "325130" },
    { key: "32518 Other Basic Inorganic Chemical ManufacturingT ", value: "32518" },
    { key: "325180 Other Basic Inorganic Chemical Manufacturing ", value: "325180" },
    { key: "32519 Other Basic Organic Chemical ManufacturingT ", value: "32519" },
    { key: "325193 Ethyl Alcohol Manufacturing ", value: "325193" },
    { key: "325194 Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing ", value: "325194" },
    { key: "325199 All Other Basic Organic Chemical Manufacturing ", value: "325199" },
    { key: "3252 Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments ManufacturingT ", value: "3252" },
    { key: "32521 Resin and Synthetic Rubber ManufacturingT ", value: "32521" },
    { key: "325211 Plastics Material and Resin Manufacturing ", value: "325211" },
    { key: "325212 Synthetic Rubber Manufacturing ", value: "325212" },
    { key: "32522 Artificial and Synthetic Fibers and Filaments ManufacturingT ", value: "32522" },
    { key: "325220 Artificial and Synthetic Fibers and Filaments Manufacturing", value: "325220" },
    { key: "3253 Pesticide, Fertilizer, and Other Agricultural Chemical ManufacturingT ", value: "3253" },
    { key: "32531 Fertilizer and Compost ManufacturingT ", value: "32531" },
    { key: "325311 Nitrogenous Fertilizer Manufacturing ", value: "325311" },
    { key: "325312 Phosphatic Fertilizer Manufacturing ", value: "325312" },
    { key: "325314 Fertilizer (Mixing Only) Manufacturing ", value: "325314" },
    { key: "325315 Compost Manufacturing", value: "325315" },
    { key: "32532 Pesticide and Other Agricultural Chemical ManufacturingT ", value: "32532" },
    { key: "325320 Pesticide and Other Agricultural Chemical Manufacturing", value: "325320" },
    { key: "3254 Pharmaceutical and Medicine ManufacturingT ", value: "3254" },
    { key: "32541 Pharmaceutical and Medicine ManufacturingT ", value: "32541" },
    { key: "325411 Medicinal and Botanical Manufacturing ", value: "325411" },
    { key: "325412 Pharmaceutical Preparation Manufacturing ", value: "325412" },
    { key: "325413 In-Vitro Diagnostic Substance Manufacturing ", value: "325413" },
    { key: "325414 Biological Product (except Diagnostic) Manufacturing ", value: "325414" },
    { key: "3255 Paint, Coating, and Adhesive ManufacturingT ", value: "3255" },
    { key: "32551 Paint and Coating ManufacturingT ", value: "32551" },
    { key: "325510 Paint and Coating Manufacturing", value: "325510" },
    { key: "32552 Adhesive ManufacturingT ", value: "32552" },
    { key: "325520 Adhesive Manufacturing", value: "325520" },
    { key: "3256 Soap, Cleaning Compound, and Toilet Preparation ManufacturingT ", value: "3256" },
    { key: "32561 Soap and Cleaning Compound ManufacturingT ", value: "32561" },
    { key: "325611 Soap and Other Detergent Manufacturing ", value: "325611" },
    { key: "325612 Polish and Other Sanitation Good Manufacturing ", value: "325612" },
    { key: "325613 Surface Active Agent Manufacturing ", value: "325613" },
    { key: "32562 Toilet Preparation ManufacturingT ", value: "32562" },
    { key: "325620 Toilet Preparation Manufacturing", value: "325620" },
    { key: "3259 Other Chemical Product and Preparation ManufacturingT ", value: "3259" },
    { key: "32591 Printing Ink ManufacturingT ", value: "32591" },
    { key: "325910 Printing Ink Manufacturing", value: "325910" },
    { key: "32592 Explosives ManufacturingT ", value: "32592" },
    { key: "325920 Explosives Manufacturing", value: "325920" },
    { key: "32599 All Other Chemical Product and Preparation ManufacturingT ", value: "32599" },
    { key: "325991 Custom Compounding of Purchased Resins ", value: "325991" },
    { key: "325992 Photographic Film, Paper, Plate, Chemical, and Copy Toner Manufacturing ", value: "325992" },
    { key: "325998 All Other Miscellaneous Chemical Product and Preparation Manufacturing ", value: "325998" },
    { key: "326 Plastics and Rubber Products ManufacturingT ", value: "326" },
    { key: "3261 Plastics Product ManufacturingT ", value: "3261" },
    { key: "32611 Plastics Packaging Materials and Unlaminated Film and Sheet ManufacturingT ", value: "32611" },
    { key: "326111 Plastics Bag and Pouch Manufacturing ", value: "326111" },
    { key: "326112 Plastics Packaging Film and Sheet (including Laminated) Manufacturing ", value: "326112" },
    { key: "326113 Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing ", value: "326113" },
    { key: "32612 Plastics Pipe, Pipe Fitting, and Unlaminated Profile Shape ManufacturingT ", value: "32612" },
    { key: "326121 Unlaminated Plastics Profile Shape Manufacturing ", value: "326121" },
    { key: "326122 Plastics Pipe and Pipe Fitting Manufacturing ", value: "326122" },
    { key: "32613 Laminated Plastics Plate, Sheet (except Packaging), and Shape ManufacturingT ", value: "32613" },
    { key: "326130 Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing", value: "326130" },
    { key: "32614 Polystyrene Foam Product ManufacturingT ", value: "32614" },
    { key: "326140 Polystyrene Foam Product Manufacturing", value: "326140" },
    { key: "32615 Urethane and Other Foam Product (except Polystyrene) ManufacturingT ", value: "32615" },
    { key: "326150 Urethane and Other Foam Product (except Polystyrene) Manufacturing", value: "326150" },
    { key: "32616 Plastics Bottle ManufacturingT ", value: "32616" },
    { key: "326160 Plastics Bottle Manufacturing", value: "326160" },
    { key: "32619 Other Plastics Product ManufacturingT ", value: "32619" },
    { key: "326191 Plastics Plumbing Fixture Manufacturing ", value: "326191" },
    { key: "326199 All Other Plastics Product Manufacturing ", value: "326199" },
    { key: "3262 Rubber Product ManufacturingT ", value: "3262" },
    { key: "32621 Tire ManufacturingT ", value: "32621" },
    { key: "326211 Tire Manufacturing (except Retreading) ", value: "326211" },
    { key: "326212 Tire Retreading ", value: "326212" },
    { key: "32622 Rubber and Plastics Hoses and Belting ManufacturingT ", value: "32622" },
    { key: "326220 Rubber and Plastics Hoses and Belting Manufacturing", value: "326220" },
    { key: "32629 Other Rubber Product ManufacturingT ", value: "32629" },
    { key: "326291 Rubber Product Manufacturing for Mechanical Use ", value: "326291" },
    { key: "326299 All Other Rubber Product Manufacturing ", value: "326299" },
    { key: "327 Nonmetallic Mineral Product ManufacturingT ", value: "327" },
    { key: "3271 Clay Product and Refractory ManufacturingT ", value: "3271" },
    { key: "32711 Pottery, Ceramics, and Plumbing Fixture ManufacturingT ", value: "32711" },
    { key: "327110 Pottery, Ceramics, and Plumbing Fixture Manufacturing ", value: "327110" },
    { key: "32712 Clay Building Material and Refractories ManufacturingT ", value: "32712" },
    { key: "327120 Clay Building Material and Refractories Manufacturing ", value: "327120" },
    { key: "3272 Glass and Glass Product ManufacturingT ", value: "3272" },
    { key: "32721 Glass and Glass Product ManufacturingT ", value: "32721" },
    { key: "327211 Flat Glass Manufacturing ", value: "327211" },
    { key: "327212 Other Pressed and Blown Glass and Glassware Manufacturing ", value: "327212" },
    { key: "327213 Glass Container Manufacturing ", value: "327213" },
    { key: "327215 Glass Product Manufacturing Made of Purchased Glass ", value: "327215" },
    { key: "3273 Cement and Concrete Product ManufacturingT ", value: "3273" },
    { key: "32731 Cement ManufacturingT ", value: "32731" },
    { key: "327310 Cement Manufacturing", value: "327310" },
    { key: "32732 Ready-Mix Concrete ManufacturingT ", value: "32732" },
    { key: "327320 Ready-Mix Concrete Manufacturing", value: "327320" },
    { key: "32733 Concrete Pipe, Brick, and Block ManufacturingT ", value: "32733" },
    { key: "327331 Concrete Block and Brick Manufacturing ", value: "327331" },
    { key: "327332 Concrete Pipe Manufacturing ", value: "327332" },
    { key: "32739 Other Concrete Product ManufacturingT ", value: "32739" },
    { key: "327390 Other Concrete Product Manufacturing ", value: "327390" },
    { key: "3274 Lime and Gypsum Product ManufacturingT ", value: "3274" },
    { key: "32741 Lime ManufacturingT ", value: "32741" },
    { key: "327410 Lime Manufacturing", value: "327410" },
    { key: "32742 Gypsum Product ManufacturingT ", value: "32742" },
    { key: "327420 Gypsum Product Manufacturing", value: "327420" },
    { key: "3279 Other Nonmetallic Mineral Product ManufacturingT ", value: "3279" },
    { key: "32791 Abrasive Product ManufacturingT ", value: "32791" },
    { key: "327910 Abrasive Product Manufacturing", value: "327910" },
    { key: "32799 All Other Nonmetallic Mineral Product ManufacturingT ", value: "32799" },
    { key: "327991 Cut Stone and Stone Product Manufacturing ", value: "327991" },
    { key: "327992 Ground or Treated Mineral and Earth Manufacturing ", value: "327992" },
    { key: "327993 Mineral Wool Manufacturing ", value: "327993" },
    { key: "327999 All Other Miscellaneous Nonmetallic Mineral Product Manufacturing ", value: "327999" },
    { key: "331 Primary Metal ManufacturingT ", value: "331" },
    { key: "3311 Iron and Steel Mills and Ferroalloy ManufacturingT ", value: "3311" },
    { key: "33111 Iron and Steel Mills and Ferroalloy ManufacturingT ", value: "33111" },
    { key: "331110 Iron and Steel Mills and Ferroalloy Manufacturing ", value: "331110" },
    { key: "3312 Steel Product Manufacturing from Purchased SteelT ", value: "3312" },
    { key: "33121 Iron and Steel Pipe and Tube Manufacturing from Purchased SteelT ", value: "33121" },
    { key: "331210 Iron and Steel Pipe and Tube Manufacturing from Purchased Steel", value: "331210" },
    { key: "33122 Rolling and Drawing of Purchased SteelT ", value: "33122" },
    { key: "331221 Rolled Steel Shape Manufacturing ", value: "331221" },
    { key: "331222 Steel Wire Drawing ", value: "331222" },
    { key: "3313 Alumina and Aluminum Production and ProcessingT ", value: "3313" },
    { key: "33131 Alumina and Aluminum Production and ProcessingT ", value: "33131" },
    { key: "331313 Alumina Refining and Primary Aluminum Production ", value: "331313" },
    { key: "331314 Secondary Smelting and Alloying of Aluminum ", value: "331314" },
    { key: "331315 Aluminum Sheet, Plate, and Foil Manufacturing ", value: "331315" },
    { key: "331318 Other Aluminum Rolling, Drawing, and Extruding ", value: "331318" },
    { key: "3314 Nonferrous Metal (except Aluminum) Production and ProcessingT ", value: "3314" },
    { key: "33141 Nonferrous Metal (except Aluminum) Smelting and RefiningT ", value: "33141" },
    { key: "331410 Nonferrous Metal (except Aluminum) Smelting and Refining ", value: "331410" },
    { key: "33142 Copper Rolling, Drawing, Extruding, and AlloyingT ", value: "33142" },
    { key: "331420 Copper Rolling, Drawing, Extruding, and Alloying", value: "331420" },
    { key: "33149 Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding, and AlloyingT ", value: "33149" },
    { key: "331491 Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding ", value: "331491" },
    { key: "331492 Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum) ", value: "331492" },
    { key: "3315 FoundriesT ", value: "3315" },
    { key: "33151 Ferrous Metal FoundriesT ", value: "33151" },
    { key: "331511 Iron Foundries ", value: "331511" },
    { key: "331512 Steel Investment Foundries ", value: "331512" },
    { key: "331513 Steel Foundries (except Investment) ", value: "331513" },
    { key: "33152 Nonferrous Metal FoundriesT ", value: "33152" },
    { key: "331523 Nonferrous Metal Die-Casting Foundries ", value: "331523" },
    { key: "331524 Aluminum Foundries (except Die-Casting) ", value: "331524" },
    { key: "331529 Other Nonferrous Metal Foundries (except Die-Casting) ", value: "331529" },
    { key: "332 Fabricated Metal Product ManufacturingT ", value: "332" },
    { key: "3321 Forging and StampingT ", value: "3321" },
    { key: "33211 Forging and StampingT ", value: "33211" },
    { key: "332111 Iron and Steel Forging ", value: "332111" },
    { key: "332112 Nonferrous Forging ", value: "332112" },
    { key: "332114 Custom Roll Forming ", value: "332114" },
    { key: "332117 Powder Metallurgy Part Manufacturing ", value: "332117" },
    { key: "332119 Metal Crown, Closure, and Other Metal Stamping (except Automotive) ", value: "332119" },
    { key: "3322 Cutlery and Handtool ManufacturingT ", value: "3322" },
    { key: "33221 Cutlery and Handtool ManufacturingT ", value: "33221" },
    { key: "332215 Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing ", value: "332215" },
    { key: "332216 Saw Blade and Handtool Manufacturing ", value: "332216" },
    { key: "3323 Architectural and Structural Metals ManufacturingT ", value: "3323" },
    { key: "33231 Plate Work and Fabricated Structural Product ManufacturingT ", value: "33231" },
    { key: "332311 Prefabricated Metal Building and Component Manufacturing ", value: "332311" },
    { key: "332312 Fabricated Structural Metal Manufacturing ", value: "332312" },
    { key: "332313 Plate Work Manufacturing ", value: "332313" },
    { key: "33232 Ornamental and Architectural Metal Products ManufacturingT ", value: "33232" },
    { key: "332321 Metal Window and Door Manufacturing ", value: "332321" },
    { key: "332322 Sheet Metal Work Manufacturing ", value: "332322" },
    { key: "332323 Ornamental and Architectural Metal Work Manufacturing ", value: "332323" },
    { key: "3324 Boiler, Tank, and Shipping Container ManufacturingT ", value: "3324" },
    { key: "33241 Power Boiler and Heat Exchanger ManufacturingT ", value: "33241" },
    { key: "332410 Power Boiler and Heat Exchanger Manufacturing", value: "332410" },
    { key: "33242 Metal Tank (Heavy Gauge) ManufacturingT ", value: "33242" },
    { key: "332420 Metal Tank (Heavy Gauge) Manufacturing", value: "332420" },
    { key: "33243 Metal Can, Box, and Other Metal Container (Light Gauge) ManufacturingT ", value: "33243" },
    { key: "332431 Metal Can Manufacturing ", value: "332431" },
    { key: "332439 Other Metal Container Manufacturing ", value: "332439" },
    { key: "3325 Hardware ManufacturingT ", value: "3325" },
    { key: "33251 Hardware ManufacturingT ", value: "33251" },
    { key: "332510 Hardware Manufacturing", value: "332510" },
    { key: "3326 Spring and Wire Product ManufacturingT ", value: "3326" },
    { key: "33261 Spring and Wire Product ManufacturingT ", value: "33261" },
    { key: "332613 Spring Manufacturing ", value: "332613" },
    { key: "332618 Other Fabricated Wire Product Manufacturing ", value: "332618" },
    { key: "3327 Machine Shops; Turned Product; and Screw, Nut, and Bolt ManufacturingT ", value: "3327" },
    { key: "33271 Machine ShopsT ", value: "33271" },
    { key: "332710 Machine Shops", value: "332710" },
    { key: "33272 Turned Product and Screw, Nut, and Bolt ManufacturingT ", value: "33272" },
    { key: "332721 Precision Turned Product Manufacturing ", value: "332721" },
    { key: "332722 Bolt, Nut, Screw, Rivet, and Washer Manufacturing ", value: "332722" },
    { key: "3328 Coating, Engraving, Heat Treating, and Allied ActivitiesT ", value: "3328" },
    { key: "33281 Coating, Engraving, Heat Treating, and Allied ActivitiesT ", value: "33281" },
    { key: "332811 Metal Heat Treating ", value: "332811" },
    { key: "332812 Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers ", value: "332812" },
    { key: "332813 Electroplating, Plating, Polishing, Anodizing, and Coloring ", value: "332813" },
    { key: "3329 Other Fabricated Metal Product ManufacturingT ", value: "3329" },
    { key: "33291 Metal Valve ManufacturingT ", value: "33291" },
    { key: "332911 Industrial Valve Manufacturing ", value: "332911" },
    { key: "332912 Fluid Power Valve and Hose Fitting Manufacturing ", value: "332912" },
    { key: "332913 Plumbing Fixture Fitting and Trim Manufacturing ", value: "332913" },
    { key: "332919 Other Metal Valve and Pipe Fitting Manufacturing ", value: "332919" },
    { key: "33299 All Other Fabricated Metal Product ManufacturingT ", value: "33299" },
    { key: "332991 Ball and Roller Bearing Manufacturing", value: "332991" },
    { key: "332992 Small Arms Ammunition Manufacturing ", value: "332992" },
    { key: "332993 Ammunition (except Small Arms) Manufacturing ", value: "332993" },
    { key: "332994 Small Arms, Ordnance, and Ordnance Accessories Manufacturing ", value: "332994" },
    { key: "332996 Fabricated Pipe and Pipe Fitting Manufacturing ", value: "332996" },
    { key: "332999 All Other Miscellaneous Fabricated Metal Product Manufacturing ", value: "332999" },
    { key: "333 Machinery ManufacturingT ", value: "333" },
    { key: "3331 Agriculture, Construction, and Mining Machinery ManufacturingT ", value: "3331" },
    { key: "33311 Agricultural Implement ManufacturingT ", value: "33311" },
    { key: "333111 Farm Machinery and Equipment Manufacturing ", value: "333111" },
    { key: "333112 Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing ", value: "333112" },
    { key: "33312 Construction Machinery ManufacturingT ", value: "33312" },
    { key: "333120 Construction Machinery Manufacturing", value: "333120" },
    { key: "33313 Mining and Oil and Gas Field Machinery ManufacturingT ", value: "33313" },
    { key: "333131 Mining Machinery and Equipment Manufacturing ", value: "333131" },
    { key: "333132 Oil and Gas Field Machinery and Equipment Manufacturing ", value: "333132" },
    { key: "3332 Industrial Machinery ManufacturingT ", value: "3332" },
    { key: "33324 Industrial Machinery ManufacturingT ", value: "33324" },
    { key: "333241 Food Product Machinery Manufacturing ", value: "333241" },
    { key: "333242 Semiconductor Machinery Manufacturing ", value: "333242" },
    { key: "333243 Sawmill, Woodworking, and Paper Machinery Manufacturing ", value: "333243" },
    { key: "333248 All Other Industrial Machinery Manufacturing ", value: "333248" },
    { key: "3333 Commercial and Service Industry Machinery ManufacturingT ", value: "3333" },
    { key: "33331 Commercial and Service Industry Machinery ManufacturingT ", value: "33331" },
    { key: "333310 Commercial and Service Industry Machinery Manufacturing ", value: "333310" },
    { key: "3334 Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment ManufacturingT ", value: "3334" },
    { key: "33341 Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment ManufacturingT ", value: "33341" },
    { key: "333413 Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing ", value: "333413" },
    { key: "333414 Heating Equipment (except Warm Air Furnaces) Manufacturing ", value: "333414" },
    { key: "333415 Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing ", value: "333415" },
    { key: "3335 Metalworking Machinery ManufacturingT ", value: "3335" },
    { key: "33351 Metalworking Machinery ManufacturingT ", value: "33351" },
    { key: "333511 Industrial Mold Manufacturing ", value: "333511" },
    { key: "333514 Special Die and Tool, Die Set, Jig, and Fixture Manufacturing ", value: "333514" },
    { key: "333515 Cutting Tool and Machine Tool Accessory Manufacturing ", value: "333515" },
    { key: "333517 Machine Tool Manufacturing ", value: "333517" },
    { key: "333519 Rolling Mill and Other Metalworking Machinery Manufacturing ", value: "333519" },
    { key: "3336 Engine, Turbine, and Power Transmission Equipment ManufacturingT ", value: "3336" },
    { key: "33361 Engine, Turbine, and Power Transmission Equipment ManufacturingT ", value: "33361" },
    { key: "333611 Turbine and Turbine Generator Set Units Manufacturing ", value: "333611" },
    { key: "333612 Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing ", value: "333612" },
    { key: "333613 Mechanical Power Transmission Equipment Manufacturing ", value: "333613" },
    { key: "333618 Other Engine Equipment Manufacturing ", value: "333618" },
    { key: "3339 Other General Purpose Machinery ManufacturingT ", value: "3339" },
    { key: "33391 Pump and Compressor ManufacturingT ", value: "33391" },
    { key: "333912 Air and Gas Compressor Manufacturing ", value: "333912" },
    { key: "333914 Measuring, Dispensing, and Other Pumping Equipment Manufacturing ", value: "333914" },
    { key: "33392 Material Handling Equipment ManufacturingT ", value: "33392" },
    { key: "333921 Elevator and Moving Stairway Manufacturing ", value: "333921" },
    { key: "333922 Conveyor and Conveying Equipment Manufacturing ", value: "333922" },
    { key: "333923 Overhead Traveling Crane, Hoist, and Monorail System Manufacturing ", value: "333923" },
    { key: "333924 Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing ", value: "333924" },
    { key: "33399 All Other General Purpose Machinery ManufacturingT ", value: "33399" },
    { key: "333991 Power-Driven Handtool Manufacturing ", value: "333991" },
    { key: "333992 Welding and Soldering Equipment Manufacturing ", value: "333992" },
    { key: "333993 Packaging Machinery Manufacturing ", value: "333993" },
    { key: "333994 Industrial Process Furnace and Oven Manufacturing ", value: "333994" },
    { key: "333995 Fluid Power Cylinder and Actuator Manufacturing ", value: "333995" },
    { key: "333996 Fluid Power Pump and Motor Manufacturing ", value: "333996" },
    { key: "333998 All Other Miscellaneous General Purpose Machinery Manufacturing ", value: "333998" },
    { key: "334 Computer and Electronic Product ManufacturingT ", value: "334" },
    { key: "3341 Computer and Peripheral Equipment ManufacturingT ", value: "3341" },
    { key: "33411 Computer and Peripheral Equipment ManufacturingT ", value: "33411" },
    { key: "334111 Electronic Computer Manufacturing ", value: "334111" },
    { key: "334112 Computer Storage Device Manufacturing ", value: "334112" },
    { key: "334118 Computer Terminal and Other Computer Peripheral Equipment Manufacturing ", value: "334118" },
    { key: "3342 Communications Equipment ManufacturingT ", value: "3342" },
    { key: "33421 Telephone Apparatus ManufacturingT ", value: "33421" },
    { key: "334210 Telephone Apparatus Manufacturing", value: "334210" },
    { key: "33422 Radio and Television Broadcasting and Wireless Communications Equipment ManufacturingT ", value: "33422" },
    { key: "334220 Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing", value: "334220" },
    { key: "33429 Other Communications Equipment ManufacturingT ", value: "33429" },
    { key: "334290 Other Communications Equipment Manufacturing", value: "334290" },
    { key: "3343 Audio and Video Equipment ManufacturingT ", value: "3343" },
    { key: "33431 Audio and Video Equipment ManufacturingT ", value: "33431" },
    { key: "334310 Audio and Video Equipment Manufacturing", value: "334310" },
    { key: "3344 Semiconductor and Other Electronic Component ManufacturingT ", value: "3344" },
    { key: "33441 Semiconductor and Other Electronic Component ManufacturingT ", value: "33441" },
    { key: "334412 Bare Printed Circuit Board Manufacturing  ", value: "334412" },
    { key: "334413 Semiconductor and Related Device Manufacturing ", value: "334413" },
    { key: "334416 Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing ", value: "334416" },
    { key: "334417 Electronic Connector Manufacturing ", value: "334417" },
    { key: "334418 Printed Circuit Assembly (Electronic Assembly) Manufacturing ", value: "334418" },
    { key: "334419 Other Electronic Component Manufacturing ", value: "334419" },
    { key: "3345 Navigational, Measuring, Electromedical, and Control Instruments ManufacturingT ", value: "3345" },
    { key: "33451 Navigational, Measuring, Electromedical, and Control Instruments ManufacturingT ", value: "33451" },
    { key: "334510 Electromedical and Electrotherapeutic Apparatus Manufacturing ", value: "334510" },
    { key: "334511 Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing ", value: "334511" },
    { key: "334512 Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use ", value: "334512" },
    { key: "334513 Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables ", value: "334513" },
    { key: "334514 Totalizing Fluid Meter and Counting Device Manufacturing ", value: "334514" },
    { key: "334515 Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals ", value: "334515" },
    { key: "334516 Analytical Laboratory Instrument Manufacturing ", value: "334516" },
    { key: "334517 Irradiation Apparatus Manufacturing ", value: "334517" },
    { key: "334519 Other Measuring and Controlling Device Manufacturing ", value: "334519" },
    { key: "3346 Manufacturing and Reproducing Magnetic and Optical MediaT ", value: "3346" },
    { key: "33461 Manufacturing and Reproducing Magnetic and Optical MediaT ", value: "33461" },
    { key: "334610 Manufacturing and Reproducing Magnetic and Optical Media ", value: "334610" },
    { key: "335 Electrical Equipment, Appliance, and Component ManufacturingT ", value: "335" },
    { key: "3351 Electric Lighting Equipment ManufacturingT ", value: "3351" },
    { key: "33513 Electric Lighting Equipment ManufacturingT ", value: "33513" },
    { key: "335131 Residential Electric Lighting Fixture Manufacturing ", value: "335131" },
    { key: "335132 Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing ", value: "335132" },
    { key: "335139 Electric Lamp Bulb and Other Lighting Equipment Manufacturing ", value: "335139" },
    { key: "3352 Household Appliance ManufacturingT ", value: "3352" },
    { key: "33521 Small Electrical Appliance ManufacturingT ", value: "33521" },
    { key: "335210 Small Electrical Appliance Manufacturing", value: "335210" },
    { key: "33522 Major Household Appliance ManufacturingT ", value: "33522" },
    { key: "335220 Major Household Appliance Manufacturing ", value: "335220" },
    { key: "3353 Electrical Equipment ManufacturingT ", value: "3353" },
    { key: "33531 Electrical Equipment ManufacturingT ", value: "33531" },
    { key: "335311 Power, Distribution, and Specialty Transformer Manufacturing ", value: "335311" },
    { key: "335312 Motor and Generator Manufacturing ", value: "335312" },
    { key: "335313 Switchgear and Switchboard Apparatus Manufacturing ", value: "335313" },
    { key: "335314 Relay and Industrial Control Manufacturing ", value: "335314" },
    { key: "3359 Other Electrical Equipment and Component ManufacturingT ", value: "3359" },
    { key: "33591 Battery ManufacturingT ", value: "33591" },
    { key: "335910 Battery Manufacturing ", value: "335910" },
    { key: "33592 Communication and Energy Wire and Cable ManufacturingT ", value: "33592" },
    { key: "335921 Fiber Optic Cable Manufacturing ", value: "335921" },
    { key: "335929 Other Communication and Energy Wire Manufacturing ", value: "335929" },
    { key: "33593 Wiring Device ManufacturingT ", value: "33593" },
    { key: "335931 Current-Carrying Wiring Device Manufacturing ", value: "335931" },
    { key: "335932 Noncurrent-Carrying Wiring Device Manufacturing ", value: "335932" },
    { key: "33599 All Other Electrical Equipment and Component ManufacturingT ", value: "33599" },
    { key: "335991 Carbon and Graphite Product Manufacturing ", value: "335991" },
    { key: "335999 All Other Miscellaneous Electrical Equipment and Component Manufacturing ", value: "335999" },
    { key: "336 Transportation Equipment ManufacturingT ", value: "336" },
    { key: "3361 Motor Vehicle ManufacturingT ", value: "3361" },
    { key: "33611 Automobile and Light Duty Motor Vehicle ManufacturingT ", value: "33611" },
    { key: "336110 Automobile and Light Duty Motor Vehicle Manufacturing ", value: "336110" },
    { key: "33612 Heavy Duty Truck ManufacturingT ", value: "33612" },
    { key: "336120 Heavy Duty Truck Manufacturing", value: "336120" },
    { key: "3362 Motor Vehicle Body and Trailer ManufacturingT ", value: "3362" },
    { key: "33621 Motor Vehicle Body and Trailer ManufacturingT ", value: "33621" },
    { key: "336211 Motor Vehicle Body Manufacturing ", value: "336211" },
    { key: "336212 Truck Trailer Manufacturing ", value: "336212" },
    { key: "336213 Motor Home Manufacturing ", value: "336213" },
    { key: "336214 Travel Trailer and Camper Manufacturing ", value: "336214" },
    { key: "3363 Motor Vehicle Parts ManufacturingT ", value: "3363" },
    { key: "33631 Motor Vehicle Gasoline Engine and Engine Parts ManufacturingT ", value: "33631" },
    { key: "336310 Motor Vehicle Gasoline Engine and Engine Parts Manufacturing", value: "336310" },
    { key: "33632 Motor Vehicle Electrical and Electronic Equipment ManufacturingT ", value: "33632" },
    { key: "336320 Motor Vehicle Electrical and Electronic Equipment Manufacturing", value: "336320" },
    { key: "33633 Motor Vehicle Steering and Suspension Components (except Spring) ManufacturingT ", value: "33633" },
    { key: "336330 Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing", value: "336330" },
    { key: "33634 Motor Vehicle Brake System ManufacturingT ", value: "33634" },
    { key: "336340 Motor Vehicle Brake System Manufacturing", value: "336340" },
    { key: "33635 Motor Vehicle Transmission and Power Train Parts ManufacturingT ", value: "33635" },
    { key: "336350 Motor Vehicle Transmission and Power Train Parts Manufacturing", value: "336350" },
    { key: "33636 Motor Vehicle Seating and Interior Trim ManufacturingT ", value: "33636" },
    { key: "336360 Motor Vehicle Seating and Interior Trim Manufacturing", value: "336360" },
    { key: "33637 Motor Vehicle Metal StampingT ", value: "33637" },
    { key: "336370 Motor Vehicle Metal Stamping", value: "336370" },
    { key: "33639 Other Motor Vehicle Parts ManufacturingT ", value: "33639" },
    { key: "336390 Other Motor Vehicle Parts Manufacturing", value: "336390" },
    { key: "3364 Aerospace Product and Parts ManufacturingT ", value: "3364" },
    { key: "33641 Aerospace Product and Parts ManufacturingT ", value: "33641" },
    { key: "336411 Aircraft Manufacturing ", value: "336411" },
    { key: "336412 Aircraft Engine and Engine Parts Manufacturing ", value: "336412" },
    { key: "336413 Other Aircraft Parts and Auxiliary Equipment Manufacturing ", value: "336413" },
    { key: "336414 Guided Missile and Space Vehicle Manufacturing ", value: "336414" },
    { key: "336415 Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing ", value: "336415" },
    { key: "336419 Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing ", value: "336419" },
    { key: "3365 Railroad Rolling Stock ManufacturingT ", value: "3365" },
    { key: "33651 Railroad Rolling Stock ManufacturingT ", value: "33651" },
    { key: "336510 Railroad Rolling Stock Manufacturing", value: "336510" },
    { key: "3366 Ship and Boat BuildingT ", value: "3366" },
    { key: "33661 Ship and Boat BuildingT ", value: "33661" },
    { key: "336611 Ship Building and Repairing ", value: "336611" },
    { key: "336612 Boat Building ", value: "336612" },
    { key: "3369 Other Transportation Equipment ManufacturingT ", value: "3369" },
    { key: "33699 Other Transportation Equipment ManufacturingT ", value: "33699" },
    { key: "336991 Motorcycle, Bicycle, and Parts Manufacturing ", value: "336991" },
    { key: "336992 Military Armored Vehicle, Tank, and Tank Component Manufacturing ", value: "336992" },
    { key: "336999 All Other Transportation Equipment Manufacturing ", value: "336999" },
    { key: "337 Furniture and Related Product ManufacturingT ", value: "337" },
    { key: "3371 Household and Institutional Furniture and Kitchen Cabinet ManufacturingT ", value: "3371" },
    { key: "33711 Wood Kitchen Cabinet and Countertop ManufacturingT ", value: "33711" },
    { key: "337110 Wood Kitchen Cabinet and Countertop Manufacturing", value: "337110" },
    { key: "33712 Household and Institutional Furniture ManufacturingT ", value: "33712" },
    { key: "337121 Upholstered Household Furniture Manufacturing ", value: "337121" },
    { key: "337122 Nonupholstered Wood Household Furniture Manufacturing ", value: "337122" },
    { key: "337126 Household Furniture (except Wood and Upholstered) Manufacturing ", value: "337126" },
    { key: "337127 Institutional Furniture Manufacturing ", value: "337127" },
    { key: "3372 Office Furniture (including Fixtures) ManufacturingT ", value: "3372" },
    { key: "33721 Office Furniture (including Fixtures) ManufacturingT ", value: "33721" },
    { key: "337211 Wood Office Furniture Manufacturing ", value: "337211" },
    { key: "337212 Custom Architectural Woodwork and Millwork Manufacturing ", value: "337212" },
    { key: "337214 Office Furniture (except Wood) Manufacturing ", value: "337214" },
    { key: "337215 Showcase, Partition, Shelving, and Locker Manufacturing ", value: "337215" },
    { key: "3379 Other Furniture Related Product ManufacturingT ", value: "3379" },
    { key: "33791 Mattress ManufacturingT ", value: "33791" },
    { key: "337910 Mattress Manufacturing", value: "337910" },
    { key: "33792 Blind and Shade ManufacturingT ", value: "33792" },
    { key: "337920 Blind and Shade Manufacturing", value: "337920" },
    { key: "339 Miscellaneous ManufacturingT ", value: "339" },
    { key: "3391 Medical Equipment and Supplies ManufacturingT ", value: "3391" },
    { key: "33911 Medical Equipment and Supplies ManufacturingT ", value: "33911" },
    { key: "339112 Surgical and Medical Instrument Manufacturing ", value: "339112" },
    { key: "339113 Surgical Appliance and Supplies Manufacturing ", value: "339113" },
    { key: "339114 Dental Equipment and Supplies Manufacturing ", value: "339114" },
    { key: "339115 Ophthalmic Goods Manufacturing ", value: "339115" },
    { key: "339116 Dental Laboratories ", value: "339116" },
    { key: "3399 Other Miscellaneous ManufacturingT ", value: "3399" },
    { key: "33991 Jewelry and Silverware ManufacturingT ", value: "33991" },
    { key: "339910 Jewelry and Silverware Manufacturing ", value: "339910" },
    { key: "33992 Sporting and Athletic Goods ManufacturingT ", value: "33992" },
    { key: "339920 Sporting and Athletic Goods Manufacturing", value: "339920" },
    { key: "33993 Doll, Toy, and Game ManufacturingT ", value: "33993" },
    { key: "339930 Doll, Toy, and Game Manufacturing", value: "339930" },
    { key: "33994 Office Supplies (except Paper) ManufacturingT ", value: "33994" },
    { key: "339940 Office Supplies (except Paper) Manufacturing", value: "339940" },
    { key: "33995 Sign ManufacturingT ", value: "33995" },
    { key: "339950 Sign Manufacturing", value: "339950" },
    { key: "33999 All Other Miscellaneous ManufacturingT ", value: "33999" },
    { key: "339991 Gasket, Packing, and Sealing Device Manufacturing ", value: "339991" },
    { key: "339992 Musical Instrument Manufacturing ", value: "339992" },
    { key: "339993 Fastener, Button, Needle, and Pin Manufacturing ", value: "339993" },
    { key: "339994 Broom, Brush, and Mop Manufacturing ", value: "339994" },
    { key: "339995 Burial Casket Manufacturing ", value: "339995" },
    { key: "339999 All Other Miscellaneous Manufacturing ", value: "339999" },
    { key: "42 Wholesale TradeT", value: "42" },
    { key: "423 Merchant Wholesalers, Durable Goods ", value: "423" },
    { key: "4231 Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers ", value: "4231" },
    { key: "42311 Automobile and Other Motor Vehicle Merchant Wholesalers ", value: "42311" },
    { key: "423110 Automobile and Other Motor Vehicle Merchant Wholesalers ", value: "423110" },
    { key: "42312 Motor Vehicle Supplies and New Parts Merchant Wholesalers ", value: "42312" },
    { key: "423120 Motor Vehicle Supplies and New Parts Merchant Wholesalers ", value: "423120" },
    { key: "42313 Tire and Tube Merchant Wholesalers ", value: "42313" },
    { key: "423130 Tire and Tube Merchant Wholesalers ", value: "423130" },
    { key: "42314 Motor Vehicle Parts (Used) Merchant Wholesalers ", value: "42314" },
    { key: "423140 Motor Vehicle Parts (Used) Merchant Wholesalers ", value: "423140" },
    { key: "4232 Furniture and Home Furnishing Merchant Wholesalers ", value: "4232" },
    { key: "42321 Furniture Merchant Wholesalers ", value: "42321" },
    { key: "423210 Furniture Merchant Wholesalers ", value: "423210" },
    { key: "42322 Home Furnishing Merchant Wholesalers ", value: "42322" },
    { key: "423220 Home Furnishing Merchant Wholesalers ", value: "423220" },
    { key: "4233 Lumber and Other Construction Materials Merchant Wholesalers ", value: "4233" },
    { key: "42331 Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers ", value: "42331" },
    { key: "423310 Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers ", value: "423310" },
    { key: "42332 Brick, Stone, and Related Construction Material Merchant Wholesalers ", value: "42332" },
    { key: "423320 Brick, Stone, and Related Construction Material Merchant Wholesalers ", value: "423320" },
    { key: "42333 Roofing, Siding, and Insulation Material Merchant Wholesalers ", value: "42333" },
    { key: "423330 Roofing, Siding, and Insulation Material Merchant Wholesalers ", value: "423330" },
    { key: "42339 Other Construction Material Merchant Wholesalers ", value: "42339" },
    { key: "423390 Other Construction Material Merchant Wholesalers ", value: "423390" },
    { key: "4234 Professional and Commercial Equipment and Supplies Merchant Wholesalers ", value: "4234" },
    { key: "42341 Photographic Equipment and Supplies Merchant Wholesalers ", value: "42341" },
    { key: "423410 Photographic Equipment and Supplies Merchant Wholesalers ", value: "423410" },
    { key: "42342 Office Equipment Merchant Wholesalers ", value: "42342" },
    { key: "423420 Office Equipment Merchant Wholesalers ", value: "423420" },
    { key: "42343 Computer and Computer Peripheral Equipment and Software Merchant Wholesalers ", value: "42343" },
    { key: "423430 Computer and Computer Peripheral Equipment and Software Merchant Wholesalers ", value: "423430" },
    { key: "42344 Other Commercial Equipment Merchant Wholesalers ", value: "42344" },
    { key: "423440 Other Commercial Equipment Merchant Wholesalers ", value: "423440" },
    { key: "42345 Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers ", value: "42345" },
    { key: "423450 Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers ", value: "423450" },
    { key: "42346 Ophthalmic Goods Merchant Wholesalers ", value: "42346" },
    { key: "423460 Ophthalmic Goods Merchant Wholesalers ", value: "423460" },
    { key: "42349 Other Professional Equipment and Supplies Merchant Wholesalers ", value: "42349" },
    { key: "423490 Other Professional Equipment and Supplies Merchant Wholesalers ", value: "423490" },
    { key: "4235 Metal and Mineral (except Petroleum) Merchant Wholesalers ", value: "4235" },
    { key: "42351 Metal Service Centers and Other Metal Merchant Wholesalers ", value: "42351" },
    { key: "423510 Metal Service Centers and Other Metal Merchant Wholesalers ", value: "423510" },
    { key: "42352 Coal and Other Mineral and Ore Merchant Wholesalers ", value: "42352" },
    { key: "423520 Coal and Other Mineral and Ore Merchant Wholesalers ", value: "423520" },
    { key: "4236 Household Appliances and Electrical and Electronic Goods Merchant Wholesalers ", value: "4236" },
    { key: "42361 Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers ", value: "42361" },
    { key: "423610 Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers ", value: "423610" },
    { key: "42362 Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers ", value: "42362" },
    { key: "423620 Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers ", value: "423620" },
    { key: "42369 Other Electronic Parts and Equipment Merchant Wholesalers ", value: "42369" },
    { key: "423690 Other Electronic Parts and Equipment Merchant Wholesalers ", value: "423690" },
    { key: "4237 Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers ", value: "4237" },
    { key: "42371 Hardware Merchant Wholesalers ", value: "42371" },
    { key: "423710 Hardware Merchant Wholesalers ", value: "423710" },
    { key: "42372 Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers ", value: "42372" },
    { key: "423720 Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers ", value: "423720" },
    { key: "42373 Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers ", value: "42373" },
    { key: "423730 Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers ", value: "423730" },
    { key: "42374 Refrigeration Equipment and Supplies Merchant Wholesalers ", value: "42374" },
    { key: "423740 Refrigeration Equipment and Supplies Merchant Wholesalers ", value: "423740" },
    { key: "4238 Machinery, Equipment, and Supplies Merchant Wholesalers ", value: "4238" },
    { key: "42381 Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers ", value: "42381" },
    { key: "423810 Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers ", value: "423810" },
    { key: "42382 Farm and Garden Machinery and Equipment Merchant Wholesalers ", value: "42382" },
    { key: "423820 Farm and Garden Machinery and Equipment Merchant Wholesalers ", value: "423820" },
    { key: "42383 Industrial Machinery and Equipment Merchant Wholesalers ", value: "42383" },
    { key: "423830 Industrial Machinery and Equipment Merchant Wholesalers ", value: "423830" },
    { key: "42384 Industrial Supplies Merchant Wholesalers ", value: "42384" },
    { key: "423840 Industrial Supplies Merchant Wholesalers", value: "423840" },
    { key: "42385 Service Establishment Equipment and Supplies Merchant Wholesalers ", value: "42385" },
    { key: "423850 Service Establishment Equipment and Supplies Merchant Wholesalers ", value: "423850" },
    { key: "42386 Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers ", value: "42386" },
    { key: "423860 Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers ", value: "423860" },
    { key: "4239 Miscellaneous Durable Goods Merchant Wholesalers ", value: "4239" },
    { key: "42391 Sporting and Recreational Goods and Supplies Merchant Wholesalers", value: "42391" },
    { key: "423910 Sporting and Recreational Goods and Supplies Merchant Wholesalers ", value: "423910" },
    { key: "42392 Toy and Hobby Goods and Supplies Merchant Wholesalers ", value: "42392" },
    { key: "423920 Toy and Hobby Goods and Supplies Merchant Wholesalers ", value: "423920" },
    { key: "42393 Recyclable Material Merchant Wholesalers ", value: "42393" },
    { key: "423930 Recyclable Material Merchant Wholesalers ", value: "423930" },
    { key: "42394 Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers ", value: "42394" },
    { key: "423940 Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers ", value: "423940" },
    { key: "42399 Other Miscellaneous Durable Goods Merchant Wholesalers ", value: "42399" },
    { key: "423990 Other Miscellaneous Durable Goods Merchant Wholesalers ", value: "423990" },
    { key: "424 Merchant Wholesalers, Nondurable Goods ", value: "424" },
    { key: "4241 Paper and Paper Product Merchant Wholesalers ", value: "4241" },
    { key: "42411 Printing and Writing Paper Merchant Wholesalers ", value: "42411" },
    { key: "424110 Printing and Writing Paper Merchant Wholesalers ", value: "424110" },
    { key: "42412 Stationery and Office Supplies Merchant Wholesalers ", value: "42412" },
    { key: "424120 Stationery and Office Supplies Merchant Wholesalers ", value: "424120" },
    { key: "42413 Industrial and Personal Service Paper Merchant Wholesalers ", value: "42413" },
    { key: "424130 Industrial and Personal Service Paper Merchant Wholesalers ", value: "424130" },
    { key: "4242 Drugs and Druggists' Sundries Merchant Wholesalers ", value: "4242" },
    { key: "42421 Drugs and Druggists' Sundries Merchant Wholesalers ", value: "42421" },
    { key: "424210 Drugs and Druggists' Sundries Merchant Wholesalers ", value: "424210" },
    { key: "4243 Apparel, Piece Goods, and Notions Merchant Wholesalers ", value: "4243" },
    { key: "42431 Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers ", value: "42431" },
    { key: "424310 Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers ", value: "424310" },
    { key: "42434 Footwear Merchant Wholesalers ", value: "42434" },
    { key: "424340 Footwear Merchant Wholesalers ", value: "424340" },
    { key: "42435 Clothing and Clothing Accessories Merchant Wholesalers", value: "42435" },
    { key: "424350 Clothing and Clothing Accessories Merchant Wholesalers", value: "424350" },
    { key: "4244 Grocery and Related Product Merchant Wholesalers ", value: "4244" },
    { key: "42441 General Line Grocery Merchant Wholesalers ", value: "42441" },
    { key: "424410 General Line Grocery Merchant Wholesalers ", value: "424410" },
    { key: "42442 Packaged Frozen Food Merchant Wholesalers ", value: "42442" },
    { key: "424420 Packaged Frozen Food Merchant Wholesalers ", value: "424420" },
    { key: "42443 Dairy Product (except Dried or Canned) Merchant Wholesalers ", value: "42443" },
    { key: "424430 Dairy Product (except Dried or Canned) Merchant Wholesalers ", value: "424430" },
    { key: "42444 Poultry and Poultry Product Merchant Wholesalers ", value: "42444" },
    { key: "424440 Poultry and Poultry Product Merchant Wholesalers ", value: "424440" },
    { key: "42445 Confectionery Merchant Wholesalers ", value: "42445" },
    { key: "424450 Confectionery Merchant Wholesalers ", value: "424450" },
    { key: "42446 Fish and Seafood Merchant Wholesalers ", value: "42446" },
    { key: "424460 Fish and Seafood Merchant Wholesalers ", value: "424460" },
    { key: "42447 Meat and Meat Product Merchant Wholesalers ", value: "42447" },
    { key: "424470 Meat and Meat Product Merchant Wholesalers ", value: "424470" },
    { key: "42448 Fresh Fruit and Vegetable Merchant Wholesalers ", value: "42448" },
    { key: "424480 Fresh Fruit and Vegetable Merchant Wholesalers ", value: "424480" },
    { key: "42449 Other Grocery and Related Products Merchant Wholesalers ", value: "42449" },
    { key: "424490 Other Grocery and Related Products Merchant Wholesalers ", value: "424490" },
    { key: "4245 Farm Product Raw Material Merchant Wholesalers ", value: "4245" },
    { key: "42451 Grain and Field Bean Merchant Wholesalers ", value: "42451" },
    { key: "424510 Grain and Field Bean Merchant Wholesalers ", value: "424510" },
    { key: "42452 Livestock Merchant Wholesalers ", value: "42452" },
    { key: "424520 Livestock Merchant Wholesalers ", value: "424520" },
    { key: "42459 Other Farm Product Raw Material Merchant Wholesalers ", value: "42459" },
    { key: "424590 Other Farm Product Raw Material Merchant Wholesalers ", value: "424590" },
    { key: "4246 Chemical and Allied Products Merchant Wholesalers ", value: "4246" },
    { key: "42461 Plastics Materials and Basic Forms and Shapes Merchant Wholesalers ", value: "42461" },
    { key: "424610 Plastics Materials and Basic Forms and Shapes Merchant Wholesalers ", value: "424610" },
    { key: "42469 Other Chemical and Allied Products Merchant Wholesalers ", value: "42469" },
    { key: "424690 Other Chemical and Allied Products Merchant Wholesalers ", value: "424690" },
    { key: "4247 Petroleum and Petroleum Products Merchant Wholesalers ", value: "4247" },
    { key: "42471 Petroleum Bulk Stations and Terminals ", value: "42471" },
    { key: "424710 Petroleum Bulk Stations and Terminals ", value: "424710" },
    { key: "42472 Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals) ", value: "42472" },
    { key: "424720 Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals) ", value: "424720" },
    { key: "4248 Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers ", value: "4248" },
    { key: "42481 Beer and Ale Merchant Wholesalers ", value: "42481" },
    { key: "424810 Beer and Ale Merchant Wholesalers ", value: "424810" },
    { key: "42482 Wine and Distilled Alcoholic Beverage Merchant Wholesalers ", value: "42482" },
    { key: "424820 Wine and Distilled Alcoholic Beverage Merchant Wholesalers ", value: "424820" },
    { key: "4249 Miscellaneous Nondurable Goods Merchant Wholesalers ", value: "4249" },
    { key: "42491 Farm Supplies Merchant Wholesalers ", value: "42491" },
    { key: "424910 Farm Supplies Merchant Wholesalers ", value: "424910" },
    { key: "42492 Book, Periodical, and Newspaper Merchant Wholesalers ", value: "42492" },
    { key: "424920 Book, Periodical, and Newspaper Merchant Wholesalers ", value: "424920" },
    { key: "42493 Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers ", value: "42493" },
    { key: "424930 Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers ", value: "424930" },
    { key: "42494 Tobacco Product and Electronic Cigarette Merchant Wholesalers ", value: "42494" },
    { key: "424940 Tobacco Product and Electronic Cigarette Merchant Wholesalers ", value: "424940" },
    { key: "42495 Paint, Varnish, and Supplies Merchant Wholesalers ", value: "42495" },
    { key: "424950 Paint, Varnish, and Supplies Merchant Wholesalers ", value: "424950" },
    { key: "42499 Other Miscellaneous Nondurable Goods Merchant Wholesalers ", value: "42499" },
    { key: "424990 Other Miscellaneous Nondurable Goods Merchant Wholesalers ", value: "424990" },
    { key: "425 Wholesale Trade Agents and Brokers ", value: "425" },
    { key: "4251 Wholesale Trade Agents and Brokers ", value: "4251" },
    { key: "42512 Wholesale Trade Agents and Brokers ", value: "42512" },
    { key: "425120 Wholesale Trade Agents and Brokers ", value: "425120" },
    { key: "44-45 Retail TradeT", value: "44-45" },
    { key: "441 Motor Vehicle and Parts Dealers ", value: "441" },
    { key: "4411 Automobile Dealers ", value: "4411" },
    { key: "44111 New Car Dealers ", value: "44111" },
    { key: "441110 New Car Dealers ", value: "441110" },
    { key: "44112 Used Car Dealers ", value: "44112" },
    { key: "441120 Used Car Dealers ", value: "441120" },
    { key: "4412 Other Motor Vehicle Dealers ", value: "4412" },
    { key: "44121 Recreational Vehicle Dealers ", value: "44121" },
    { key: "441210 Recreational Vehicle Dealers ", value: "441210" },
    { key: "44122 Motorcycle, Boat, and Other Motor Vehicle Dealers ", value: "44122" },
    { key: "441222 Boat Dealers ", value: "441222" },
    { key: "441227 Motorcycle, ATV, and All Other Motor Vehicle Dealers ", value: "441227" },
    { key: "4413 Automotive Parts, Accessories, and Tire Retailers ", value: "4413" },
    { key: "44133 Automotive Parts and Accessories Retailers ", value: "44133" },
    { key: "441330 Automotive Parts and Accessories Retailers ", value: "441330" },
    { key: "44134 Tire Dealers ", value: "44134" },
    { key: "441340 Tire Dealers ", value: "441340" },
    { key: "444 Building Material and Garden Equipment and Supplies Dealers ", value: "444" },
    { key: "4441 Building Material and Supplies Dealers ", value: "4441" },
    { key: "44411 Home Centers ", value: "44411" },
    { key: "444110 Home Centers ", value: "444110" },
    { key: "44412 Paint and Wallpaper Retailers ", value: "44412" },
    { key: "444120 Paint and Wallpaper Retailers ", value: "444120" },
    { key: "44414 Hardware Retailers ", value: "44414" },
    { key: "444140 Hardware Retailers ", value: "444140" },
    { key: "44418 Other Building Material Dealers ", value: "44418" },
    { key: "444180 Other Building Material Dealers ", value: "444180" },
    { key: "4442 Lawn and Garden Equipment and Supplies Retailers ", value: "4442" },
    { key: "44423 Outdoor Power Equipment Retailers ", value: "44423" },
    { key: "444230 Outdoor Power Equipment Retailers ", value: "444230" },
    { key: "44424 Nursery, Garden Center, and Farm Supply Retailers ", value: "44424" },
    { key: "444240 Nursery, Garden Center, and Farm Supply Retailers ", value: "444240" },
    { key: "445 Food and Beverage Retailers ", value: "445" },
    { key: "4451 Grocery and Convenience Retailers ", value: "4451" },
    { key: "44511 Supermarkets and Other Grocery Retailers (except Convenience Retailers) ", value: "44511" },
    { key: "445110 Supermarkets and Other Grocery Retailers (except Convenience Retailers) ", value: "445110" },
    { key: "44513 Convenience Retailers and Vending Machine Operators ", value: "44513" },
    { key: "445131 Convenience Retailers ", value: "445131" },
    { key: "445132 Vending Machine Operators ", value: "445132" },
    { key: "4452 Specialty Food Retailers ", value: "4452" },
    { key: "44523 Fruit and Vegetable Retailers ", value: "44523" },
    { key: "445230 Fruit and Vegetable Retailers ", value: "445230" },
    { key: "44524 Meat Retailers ", value: "44524" },
    { key: "445240 Meat Retailers ", value: "445240" },
    { key: "44525 Fish and Seafood Retailers ", value: "44525" },
    { key: "445250 Fish and Seafood Retailers ", value: "445250" },
    { key: "44529 Other Specialty Food Retailers ", value: "44529" },
    { key: "445291 Baked Goods Retailers ", value: "445291" },
    { key: "445292 Confectionery and Nut Retailers ", value: "445292" },
    { key: "445298 All Other Specialty Food Retailers ", value: "445298" },
    { key: "4453 Beer, Wine, and Liquor Retailers ", value: "4453" },
    { key: "44532 Beer, Wine, and Liquor Retailers ", value: "44532" },
    { key: "445320 Beer, Wine, and Liquor Retailers ", value: "445320" },
    { key: "449 Furniture, Home Furnishings, Electronics, and Appliance Retailers ", value: "449" },
    { key: "4491 Furniture and Home Furnishings Retailers ", value: "4491" },
    { key: "44911 Furniture Retailers ", value: "44911" },
    { key: "449110 Furniture Retailers ", value: "449110" },
    { key: "44912 Home Furnishings Retailers ", value: "44912" },
    { key: "449121 Floor Covering Retailers ", value: "449121" },
    { key: "449122 Window Treatment Retailers ", value: "449122" },
    { key: "449129 All Other Home Furnishings Retailers ", value: "449129" },
    { key: "4492 Electronics and Appliance Retailers ", value: "4492" },
    { key: "44921 Electronics and Appliance Retailers ", value: "44921" },
    { key: "449210 Electronics and Appliance Retailers ", value: "449210" },
    { key: "455 General Merchandise Retailers ", value: "455" },
    { key: "4551 Department Stores ", value: "4551" },
    { key: "45511 Department Stores ", value: "45511" },
    { key: "455110 Department Stores ", value: "455110" },
    { key: "4552 Warehouse Clubs, Supercenters, and Other General Merchandise Retailers ", value: "4552" },
    { key: "45521 Warehouse Clubs, Supercenters, and Other General Merchandise Retailers ", value: "45521" },
    { key: "455211 Warehouse Clubs and Supercenters ", value: "455211" },
    { key: "455219 All Other General Merchandise Retailers ", value: "455219" },
    { key: "456 Health and Personal Care Retailers ", value: "456" },
    { key: "4561 Health and Personal Care Retailers ", value: "4561" },
    { key: "45611 Pharmacies and Drug Retailers ", value: "45611" },
    { key: "456110 Pharmacies and Drug Retailers ", value: "456110" },
    { key: "45612 Cosmetics, Beauty Supplies, and Perfume Retailers ", value: "45612" },
    { key: "456120 Cosmetics, Beauty Supplies, and Perfume Retailers ", value: "456120" },
    { key: "45613 Optical Goods Retailers ", value: "45613" },
    { key: "456130 Optical Goods Retailers ", value: "456130" },
    { key: "45619 Other Health and Personal Care Retailers ", value: "45619" },
    { key: "456191 Food (Health) Supplement Retailers ", value: "456191" },
    { key: "456199 All Other Health and Personal Care Retailers ", value: "456199" },
    { key: "457 Gasoline Stations and Fuel Dealers ", value: "457" },
    { key: "4571 Gasoline Stations ", value: "4571" },
    { key: "45711 Gasoline Stations with Convenience Stores ", value: "45711" },
    { key: "457110 Gasoline Stations with Convenience Stores ", value: "457110" },
    { key: "45712 Other Gasoline Stations ", value: "45712" },
    { key: "457120 Other Gasoline Stations ", value: "457120" },
    { key: "4572 Fuel Dealers ", value: "4572" },
    { key: "45721 Fuel Dealers ", value: "45721" },
    { key: "457210 Fuel Dealers ", value: "457210" },
    { key: "458 Clothing, Clothing Accessories, Shoe, and Jewelry Retailers ", value: "458" },
    { key: "4581 Clothing and Clothing Accessories Retailers ", value: "4581" },
    { key: "45811 Clothing and Clothing Accessories Retailers ", value: "45811" },
    { key: "458110 Clothing and Clothing Accessories Retailers ", value: "458110" },
    { key: "4582 Shoe Retailers ", value: "4582" },
    { key: "45821 Shoe Retailers ", value: "45821" },
    { key: "458210 Shoe Retailers ", value: "458210" },
    { key: "4583 Jewelry, Luggage, and Leather Goods Retailers ", value: "4583" },
    { key: "45831 Jewelry Retailers ", value: "45831" },
    { key: "458310 Jewelry Retailers ", value: "458310" },
    { key: "45832 Luggage and Leather Goods Retailers ", value: "45832" },
    { key: "458320 Luggage and Leather Goods Retailers ", value: "458320" },
    { key: "459 Sporting Goods, Hobby, Musical Instrument, Book, and Miscellaneous Retailers ", value: "459" },
    { key: "4591 Sporting Goods, Hobby, and Musical Instrument Retailers ", value: "4591" },
    { key: "45911 Sporting Goods Retailers ", value: "45911" },
    { key: "459110 Sporting Goods Retailers ", value: "459110" },
    { key: "45912 Hobby, Toy, and Game Retailers ", value: "45912" },
    { key: "459120 Hobby, Toy, and Game Retailers ", value: "459120" },
    { key: "45913 Sewing, Needlework, and Piece Goods Retailers ", value: "45913" },
    { key: "459130 Sewing, Needlework, and Piece Goods Retailers ", value: "459130" },
    { key: "45914 Musical Instrument and Supplies Retailers ", value: "45914" },
    { key: "459140 Musical Instrument and Supplies Retailers ", value: "459140" },
    { key: "4592 Book Retailers and News Dealers ", value: "4592" },
    { key: "45921 Book Retailers and News Dealers ", value: "45921" },
    { key: "459210 Book Retailers and News Dealers ", value: "459210" },
    { key: "4593 Florists ", value: "4593" },
    { key: "45931 Florists ", value: "45931" },
    { key: "459310 Florists ", value: "459310" },
    { key: "4594 Office Supplies, Stationery, and Gift Retailers ", value: "4594" },
    { key: "45941 Office Supplies and Stationery Retailers ", value: "45941" },
    { key: "459410 Office Supplies and Stationery Retailers ", value: "459410" },
    { key: "45942 Gift, Novelty, and Souvenir Retailers ", value: "45942" },
    { key: "459420 Gift, Novelty, and Souvenir Retailers ", value: "459420" },
    { key: "4595 Used Merchandise Retailers ", value: "4595" },
    { key: "45951 Used Merchandise Retailers ", value: "45951" },
    { key: "459510 Used Merchandise Retailers ", value: "459510" },
    { key: "4599 Other Miscellaneous Retailers ", value: "4599" },
    { key: "45991 Pet and Pet Supplies Retailers ", value: "45991" },
    { key: "459910 Pet and Pet Supplies Retailers ", value: "459910" },
    { key: "45992 Art Dealers ", value: "45992" },
    { key: "459920 Art Dealers ", value: "459920" },
    { key: "45993 Manufactured (Mobile) Home Dealers ", value: "45993" },
    { key: "459930 Manufactured (Mobile) Home Dealers ", value: "459930" },
    { key: "45999 All Other Miscellaneous Retailers ", value: "45999" },
    { key: "459991 Tobacco, Electronic Cigarette, and Other Smoking Supplies Retailers ", value: "459991" },
    { key: "459999 All Other Miscellaneous Retailers ", value: "459999" },
    { key: "48-49 Transportation and WarehousingT", value: "48-49" },
    { key: "481 Air TransportationT ", value: "481" },
    { key: "4811 Scheduled Air TransportationT ", value: "4811" },
    { key: "48111 Scheduled Air TransportationT ", value: "48111" },
    { key: "481111 Scheduled Passenger Air Transportation ", value: "481111" },
    { key: "481112 Scheduled Freight Air Transportation ", value: "481112" },
    { key: "4812 Nonscheduled Air TransportationT ", value: "4812" },
    { key: "48121 Nonscheduled Air TransportationT ", value: "48121" },
    { key: "481211 Nonscheduled Chartered Passenger Air Transportation ", value: "481211" },
    { key: "481212 Nonscheduled Chartered Freight Air Transportation ", value: "481212" },
    { key: "481219 Other Nonscheduled Air Transportation ", value: "481219" },
    { key: "482 Rail TransportationT ", value: "482" },
    { key: "4821 Rail TransportationT ", value: "4821" },
    { key: "48211 Rail TransportationT ", value: "48211" },
    { key: "482111 Line-Haul Railroads ", value: "482111" },
    { key: "482112 Short Line Railroads ", value: "482112" },
    { key: "483 Water TransportationT ", value: "483" },
    { key: "4831 Deep Sea, Coastal, and Great Lakes Water TransportationT ", value: "4831" },
    { key: "48311 Deep Sea, Coastal, and Great Lakes Water TransportationT ", value: "48311" },
    { key: "483111 Deep Sea Freight Transportation ", value: "483111" },
    { key: "483112 Deep Sea Passenger Transportation ", value: "483112" },
    { key: "483113 Coastal and Great Lakes Freight Transportation ", value: "483113" },
    { key: "483114 Coastal and Great Lakes Passenger Transportation ", value: "483114" },
    { key: "4832 Inland Water TransportationT ", value: "4832" },
    { key: "48321 Inland Water TransportationT ", value: "48321" },
    { key: "483211 Inland Water Freight Transportation ", value: "483211" },
    { key: "483212 Inland Water Passenger Transportation ", value: "483212" },
    { key: "484 Truck TransportationT ", value: "484" },
    { key: "4841 General Freight TruckingT ", value: "4841" },
    { key: "48411 General Freight Trucking, LocalT ", value: "48411" },
    { key: "484110 General Freight Trucking, Local ", value: "484110" },
    { key: "48412 General Freight Trucking, Long-DistanceT ", value: "48412" },
    { key: "484121 General Freight Trucking, Long-Distance, Truckload ", value: "484121" },
    { key: "484122 General Freight Trucking, Long-Distance, Less Than Truckload ", value: "484122" },
    { key: "4842 Specialized Freight TruckingT ", value: "4842" },
    { key: "48421 Used Household and Office Goods MovingT ", value: "48421" },
    { key: "484210 Used Household and Office Goods Moving", value: "484210" },
    { key: "48422 Specialized Freight (except Used Goods) Trucking, LocalT ", value: "48422" },
    { key: "484220 Specialized Freight (except Used Goods) Trucking, Local ", value: "484220" },
    { key: "48423 Specialized Freight (except Used Goods) Trucking, Long-DistanceT ", value: "48423" },
    { key: "484230 Specialized Freight (except Used Goods) Trucking, Long-Distance ", value: "484230" },
    { key: "485 Transit and Ground Passenger TransportationT ", value: "485" },
    { key: "4851 Urban Transit SystemsT ", value: "4851" },
    { key: "48511 Urban Transit SystemsT ", value: "48511" },
    { key: "485111 Mixed Mode Transit Systems ", value: "485111" },
    { key: "485112 Commuter Rail Systems ", value: "485112" },
    { key: "485113 Bus and Other Motor Vehicle Transit Systems ", value: "485113" },
    { key: "485119 Other Urban Transit Systems ", value: "485119" },
    { key: "4852 Interurban and Rural Bus TransportationT ", value: "4852" },
    { key: "48521 Interurban and Rural Bus TransportationT ", value: "48521" },
    { key: "485210 Interurban and Rural Bus Transportation", value: "485210" },
    { key: "4853 Taxi and Limousine ServiceT ", value: "4853" },
    { key: "48531 Taxi and Ridesharing ServicesT ", value: "48531" },
    { key: "485310 Taxi and Ridesharing Services ", value: "485310" },
    { key: "48532 Limousine ServiceT ", value: "48532" },
    { key: "485320 Limousine Service", value: "485320" },
    { key: "4854 School and Employee Bus TransportationT ", value: "4854" },
    { key: "48541 School and Employee Bus TransportationT ", value: "48541" },
    { key: "485410 School and Employee Bus Transportation", value: "485410" },
    { key: "4855 Charter Bus IndustryT ", value: "4855" },
    { key: "48551 Charter Bus IndustryT ", value: "48551" },
    { key: "485510 Charter Bus Industry", value: "485510" },
    { key: "4859 Other Transit and Ground Passenger TransportationT ", value: "4859" },
    { key: "48599 Other Transit and Ground Passenger TransportationT ", value: "48599" },
    { key: "485991 Special Needs Transportation ", value: "485991" },
    { key: "485999 All Other Transit and Ground Passenger Transportation ", value: "485999" },
    { key: "486 Pipeline TransportationT ", value: "486" },
    { key: "4861 Pipeline Transportation of Crude OilT ", value: "4861" },
    { key: "48611 Pipeline Transportation of Crude OilT ", value: "48611" },
    { key: "486110 Pipeline Transportation of Crude Oil", value: "486110" },
    { key: "4862 Pipeline Transportation of Natural GasT ", value: "4862" },
    { key: "48621 Pipeline Transportation of Natural GasT ", value: "48621" },
    { key: "486210 Pipeline Transportation of Natural Gas", value: "486210" },
    { key: "4869 Other Pipeline TransportationT ", value: "4869" },
    { key: "48691 Pipeline Transportation of Refined Petroleum ProductsT ", value: "48691" },
    { key: "486910 Pipeline Transportation of Refined Petroleum Products", value: "486910" },
    { key: "48699 All Other Pipeline TransportationT ", value: "48699" },
    { key: "486990 All Other Pipeline Transportation", value: "486990" },
    { key: "487 Scenic and Sightseeing TransportationT ", value: "487" },
    { key: "4871 Scenic and Sightseeing Transportation, LandT ", value: "4871" },
    { key: "48711 Scenic and Sightseeing Transportation, LandT ", value: "48711" },
    { key: "487110 Scenic and Sightseeing Transportation, Land", value: "487110" },
    { key: "4872 Scenic and Sightseeing Transportation, WaterT ", value: "4872" },
    { key: "48721 Scenic and Sightseeing Transportation, WaterT ", value: "48721" },
    { key: "487210 Scenic and Sightseeing Transportation, Water", value: "487210" },
    { key: "4879 Scenic and Sightseeing Transportation, OtherT ", value: "4879" },
    { key: "48799 Scenic and Sightseeing Transportation, OtherT ", value: "48799" },
    { key: "487990 Scenic and Sightseeing Transportation, Other", value: "487990" },
    { key: "488 Support Activities for TransportationT ", value: "488" },
    { key: "4881 Support Activities for Air TransportationT ", value: "4881" },
    { key: "48811 Airport OperationsT ", value: "48811" },
    { key: "488111 Air Traffic Control", value: "488111" },
    { key: "488119 Other Airport Operations ", value: "488119" },
    { key: "48819 Other Support Activities for Air TransportationT ", value: "48819" },
    { key: "488190 Other Support Activities for Air Transportation", value: "488190" },
    { key: "4882 Support Activities for Rail TransportationT ", value: "4882" },
    { key: "48821 Support Activities for Rail TransportationT ", value: "48821" },
    { key: "488210 Support Activities for Rail Transportation", value: "488210" },
    { key: "4883 Support Activities for Water TransportationT ", value: "4883" },
    { key: "48831 Port and Harbor OperationsT ", value: "48831" },
    { key: "488310 Port and Harbor Operations", value: "488310" },
    { key: "48832 Marine Cargo HandlingT ", value: "48832" },
    { key: "488320 Marine Cargo Handling", value: "488320" },
    { key: "48833 Navigational Services to ShippingT ", value: "48833" },
    { key: "488330 Navigational Services to Shipping ", value: "488330" },
    { key: "48839 Other Support Activities for Water TransportationT ", value: "48839" },
    { key: "488390 Other Support Activities for Water Transportation", value: "488390" },
    { key: "4884 Support Activities for Road TransportationT ", value: "4884" },
    { key: "48841 Motor Vehicle TowingT ", value: "48841" },
    { key: "488410 Motor Vehicle Towing", value: "488410" },
    { key: "48849 Other Support Activities for Road TransportationT ", value: "48849" },
    { key: "488490 Other Support Activities for Road Transportation ", value: "488490" },
    { key: "4885 Freight Transportation ArrangementT ", value: "4885" },
    { key: "48851 Freight Transportation ArrangementT ", value: "48851" },
    { key: "488510 Freight Transportation Arrangement ", value: "488510" },
    { key: "4889 Other Support Activities for TransportationT ", value: "4889" },
    { key: "48899 Other Support Activities for TransportationT ", value: "48899" },
    { key: "488991 Packing and Crating ", value: "488991" },
    { key: "488999 All Other Support Activities for Transportation ", value: "488999" },
    { key: "491 Postal ServiceT ", value: "491" },
    { key: "4911 Postal ServiceT ", value: "4911" },
    { key: "49111 Postal ServiceT ", value: "49111" },
    { key: "491110 Postal Service", value: "491110" },
    { key: "492 Couriers and MessengersT ", value: "492" },
    { key: "4921 Couriers and Express Delivery ServicesT ", value: "4921" },
    { key: "49211 Couriers and Express Delivery ServicesT ", value: "49211" },
    { key: "492110 Couriers and Express Delivery Services", value: "492110" },
    { key: "4922 Local Messengers and Local DeliveryT ", value: "4922" },
    { key: "49221 Local Messengers and Local DeliveryT ", value: "49221" },
    { key: "492210 Local Messengers and Local Delivery", value: "492210" },
    { key: "493 Warehousing and StorageT ", value: "493" },
    { key: "4931 Warehousing and StorageT ", value: "4931" },
    { key: "49311 General Warehousing and StorageT ", value: "49311" },
    { key: "493110 General Warehousing and Storage ", value: "493110" },
    { key: "49312 Refrigerated Warehousing and StorageT ", value: "49312" },
    { key: "493120 Refrigerated Warehousing and Storage", value: "493120" },
    { key: "49313 Farm Product Warehousing and StorageT", value: "49313" },
    { key: "493130 Farm Product Warehousing and Storage", value: "493130" },
    { key: "49319 Other Warehousing and StorageT ", value: "49319" },
    { key: "493190 Other Warehousing and Storage", value: "493190" },
    { key: "51 InformationT", value: "51" },
    { key: "512 Motion Picture and Sound Recording IndustriesT ", value: "512" },
    { key: "5121 Motion Picture and Video IndustriesT ", value: "5121" },
    { key: "51211 Motion Picture and Video ProductionT ", value: "51211" },
    { key: "512110 Motion Picture and Video Production ", value: "512110" },
    { key: "51212 Motion Picture and Video DistributionT ", value: "51212" },
    { key: "512120 Motion Picture and Video Distribution", value: "512120" },
    { key: "51213 Motion Picture and Video ExhibitionT ", value: "51213" },
    { key: "512131 Motion Picture Theaters (except Drive-Ins) ", value: "512131" },
    { key: "512132 Drive-In Motion Picture Theaters ", value: "512132" },
    { key: "51219 Postproduction Services and Other Motion Picture and Video IndustriesT ", value: "51219" },
    { key: "512191 Teleproduction and Other Postproduction Services ", value: "512191" },
    { key: "512199 Other Motion Picture and Video Industries ", value: "512199" },
    { key: "5122 Sound Recording IndustriesT ", value: "5122" },
    { key: "51223 Music PublishersT ", value: "51223" },
    { key: "512230 Music Publishers", value: "512230" },
    { key: "51224 Sound Recording StudiosT ", value: "51224" },
    { key: "512240 Sound Recording Studios", value: "512240" },
    { key: "51225 Record Production and DistributionT", value: "51225" },
    { key: "512250 Record Production and Distribution", value: "512250" },
    { key: "51229 Other Sound Recording IndustriesT ", value: "51229" },
    { key: "512290 Other Sound Recording Industries", value: "512290" },
    { key: "513 Publishing IndustriesT ", value: "513" },
    { key: "5131 Newspaper, Periodical, Book, and Directory PublishersT ", value: "5131" },
    { key: "51311 Newspaper PublishersT ", value: "51311" },
    { key: "513110 Newspaper Publishers ", value: "513110" },
    { key: "51312 Periodical PublishersT ", value: "51312" },
    { key: "513120 Periodical Publishers ", value: "513120" },
    { key: "51313 Book PublishersT ", value: "51313" },
    { key: "513130 Book Publishers ", value: "513130" },
    { key: "51314 Directory and Mailing List PublishersT ", value: "51314" },
    { key: "513140 Directory and Mailing List Publishers ", value: "513140" },
    { key: "51319 Other PublishersT ", value: "51319" },
    { key: "513191 Greeting Card Publishers ", value: "513191" },
    { key: "513199 All Other Publishers ", value: "513199" },
    { key: "5132 Software PublishersT ", value: "5132" },
    { key: "51321 Software PublishersT ", value: "51321" },
    { key: "513210 Software Publishers", value: "513210" },
    { key: "516 Broadcasting and Content ProvidersT ", value: "516" },
    { key: "5161 Radio and Television Broadcasting StationsT ", value: "5161" },
    { key: "51611 Radio Broadcasting StationsT ", value: "51611" },
    { key: "516110 Radio Broadcasting Stations ", value: "516110" },
    { key: "51612 Television Broadcasting StationsT ", value: "51612" },
    { key: "516120 Television Broadcasting Stations", value: "516120" },
    { key: "5162 Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content ProvidersT ", value: "5162" },
    { key: "51621 Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content ProvidersT ", value: "51621" },
    { key: "516210 Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers", value: "516210" },
    { key: "517 TelecommunicationsT ", value: "517" },
    { key: "5171 Wired and Wireless Telecommunications (except Satellite) ", value: "5171" },
    { key: "51711 Wired and Wireless Telecommunications Carriers (except Satellite) ", value: "51711" },
    { key: "517111 Wired Telecommunications Carriers ", value: "517111" },
    { key: "517112 Wireless Telecommunications Carriers (except Satellite)", value: "517112" },
    { key: "51712 Telecommunications Resellers and Agents for Wireless Telecommunication Services", value: "51712" },
    { key: "517121 Telecommunications Resellers", value: "517121" },
    { key: "517122 Agents for Wireless Telecommunications Services", value: "517122" },
    { key: "5174 Satellite TelecommunicationsT ", value: "5174" },
    { key: "51741 Satellite TelecommunicationsT ", value: "51741" },
    { key: "517410 Satellite Telecommunications", value: "517410" },
    { key: "5178 All Other Telecommunications ", value: "5178" },
    { key: "51781 All Other Telecommunications ", value: "51781" },
    { key: "517810 All Other Telecommunications ", value: "517810" },
    { key: "518 Computing Infrastructure Providers, Data Processing, Web Hosting, and Related ServicesT ", value: "518" },
    { key: "5182 Computing Infrastructure Providers, Data Processing, Web Hosting, and Related ServicesT ", value: "5182" },
    { key: "51821 Computing Infrastructure Providers, Data Processing, Web Hosting, and Related ServicesT ", value: "51821" },
    { key: "518210 Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services", value: "518210" },
    { key: "519 Web Search Portals, Libraries, Archives, and Other Information ServicesT ", value: "519" },
    { key: "5192 Web Search Portals, Libraries, Archives, and Other Information ServicesT ", value: "5192" },
    { key: "51921 Libraries and ArchivesT ", value: "51921" },
    { key: "519210 Libraries and Archives ", value: "519210" },
    { key: "51929 Web Search Portals and All Other Information ServicesT ", value: "51929" },
    { key: "519290 Web Search Portals and All Other Information Services", value: "519290" },
    { key: "52 Finance and InsuranceT", value: "52" },
    { key: "521 Monetary Authorities-Central BankT ", value: "521" },
    { key: "5211 Monetary Authorities-Central BankT ", value: "5211" },
    { key: "52111 Monetary Authorities-Central BankT ", value: "52111" },
    { key: "521110 Monetary Authorities-Central Bank", value: "521110" },
    { key: "522 Credit Intermediation and Related ActivitiesT ", value: "522" },
    { key: "5221 Depository Credit Intermediation ", value: "5221" },
    { key: "52211 Commercial Banking ", value: "52211" },
    { key: "522110 Commercial Banking ", value: "522110" },
    { key: "52213 Credit Unions ", value: "52213" },
    { key: "522130 Credit Unions ", value: "522130" },
    { key: "52218 Savings Institutions and Other Depository Credit Intermediation ", value: "52218" },
    { key: "522180 Savings Institutions and Other Depository Credit Intermediation ", value: "522180" },
    { key: "5222 Nondepository Credit Intermediation ", value: "5222" },
    { key: "52221 Credit Card Issuing ", value: "52221" },
    { key: "522210 Credit Card Issuing ", value: "522210" },
    { key: "52222 Sales Financing ", value: "52222" },
    { key: "522220 Sales Financing ", value: "522220" },
    { key: "52229 Other Nondepository Credit Intermediation ", value: "52229" },
    { key: "522291 Consumer Lending ", value: "522291" },
    { key: "522292 Real Estate Credit ", value: "522292" },
    { key: "522299 International, Secondary Market, and All Other Nondepository Credit Intermediation ", value: "522299" },
    { key: "5223 Activities Related to Credit Intermediation ", value: "5223" },
    { key: "52231 Mortgage and Nonmortgage Loan Brokers ", value: "52231" },
    { key: "522310 Mortgage and Nonmortgage Loan Brokers ", value: "522310" },
    { key: "52232 Financial Transactions Processing, Reserve, and Clearinghouse Activities ", value: "52232" },
    { key: "522320 Financial Transactions Processing, Reserve, and Clearinghouse Activities ", value: "522320" },
    { key: "52239 Other Activities Related to Credit Intermediation ", value: "52239" },
    { key: "522390 Other Activities Related to Credit Intermediation ", value: "522390" },
    { key: "523 Securities, Commodity Contracts, and Other Financial Investments and Related ActivitiesT ", value: "523" },
    { key: "5231 Securities and Commodity Contracts Intermediation and BrokerageT ", value: "5231" },
    { key: "52315 Investment Banking and Securities Intermediation ", value: "52315" },
    { key: "523150 Investment Banking and Securities Intermediation ", value: "523150" },
    { key: "52316 Commodity Contracts Intermediation ", value: "52316" },
    { key: "523160 Commodity Contracts Intermediation ", value: "523160" },
    { key: "5232 Securities and Commodity ExchangesT ", value: "5232" },
    { key: "52321 Securities and Commodity ExchangesT ", value: "52321" },
    { key: "523210 Securities and Commodity Exchanges", value: "523210" },
    { key: "5239 Other Financial Investment ActivitiesT ", value: "5239" },
    { key: "52391 Miscellaneous Intermediation ", value: "52391" },
    { key: "523910 Miscellaneous Intermediation ", value: "523910" },
    { key: "52394 Portfolio Management and Investment Advice ", value: "52394" },
    { key: "523940 Portfolio Management and Investment Advice ", value: "523940" },
    { key: "52399 All Other Financial Investment Activities ", value: "52399" },
    { key: "523991 Trust, Fiduciary, and Custody Activities ", value: "523991" },
    { key: "523999 Miscellaneous Financial Investment Activities ", value: "523999" },
    { key: "524 Insurance Carriers and Related ActivitiesT ", value: "524" },
    { key: "5241 Insurance CarriersT ", value: "5241" },
    { key: "52411 Direct Life, Health, and Medical Insurance Carriers ", value: "52411" },
    { key: "524113 Direct Life Insurance Carriers ", value: "524113" },
    { key: "524114 Direct Health and Medical Insurance Carriers ", value: "524114" },
    { key: "52412 Direct Insurance (except Life, Health, and Medical) Carriers ", value: "52412" },
    { key: "524126 Direct Property and Casualty Insurance Carriers ", value: "524126" },
    { key: "524127 Direct Title Insurance Carriers ", value: "524127" },
    { key: "524128 Other Direct Insurance (except Life, Health, and Medical) Carriers ", value: "524128" },
    { key: "52413 Reinsurance Carriers ", value: "52413" },
    { key: "524130 Reinsurance Carriers ", value: "524130" },
    { key: "5242 Agencies, Brokerages, and Other Insurance Related ActivitiesT ", value: "5242" },
    { key: "52421 Insurance Agencies and Brokerages ", value: "52421" },
    { key: "524210 Insurance Agencies and Brokerages ", value: "524210" },
    { key: "52429 Other Insurance Related Activities ", value: "52429" },
    { key: "524291 Claims Adjusting ", value: "524291" },
    { key: "524292 Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds ", value: "524292" },
    { key: "524298 All Other Insurance Related Activities ", value: "524298" },
    { key: "525 Funds, Trusts, and Other Financial Vehicles ", value: "525" },
    { key: "5251 Insurance and Employee Benefit Funds ", value: "5251" },
    { key: "52511 Pension Funds ", value: "52511" },
    { key: "525110 Pension Funds ", value: "525110" },
    { key: "52512 Health and Welfare Funds ", value: "52512" },
    { key: "525120 Health and Welfare Funds ", value: "525120" },
    { key: "52519 Other Insurance Funds ", value: "52519" },
    { key: "525190 Other Insurance Funds ", value: "525190" },
    { key: "5259 Other Investment Pools and Funds", value: "5259" },
    { key: "52591 Open-End Investment Funds ", value: "52591" },
    { key: "525910 Open-End Investment Funds ", value: "525910" },
    { key: "52592 Trusts, Estates, and Agency Accounts ", value: "52592" },
    { key: "525920 Trusts, Estates, and Agency Accounts ", value: "525920" },
    { key: "52599 Other Financial Vehicles ", value: "52599" },
    { key: "525990 Other Financial Vehicles ", value: "525990" },
    { key: "53 Real Estate and Rental and LeasingT", value: "53" },
    { key: "531 Real EstateT ", value: "531" },
    { key: "5311 Lessors of Real EstateT ", value: "5311" },
    { key: "53111 Lessors of Residential Buildings and Dwellings ", value: "53111" },
    { key: "531110 Lessors of Residential Buildings and Dwellings ", value: "531110" },
    { key: "53112 Lessors of Nonresidential Buildings (except Miniwarehouses) ", value: "53112" },
    { key: "531120 Lessors of Nonresidential Buildings (except Miniwarehouses) ", value: "531120" },
    { key: "53113 Lessors of Miniwarehouses and Self-Storage Units ", value: "53113" },
    { key: "531130 Lessors of Miniwarehouses and Self-Storage Units ", value: "531130" },
    { key: "53119 Lessors of Other Real Estate Property ", value: "53119" },
    { key: "531190 Lessors of Other Real Estate Property ", value: "531190" },
    { key: "5312 Offices of Real Estate Agents and BrokersT ", value: "5312" },
    { key: "53121 Offices of Real Estate Agents and BrokersT ", value: "53121" },
    { key: "531210 Offices of Real Estate Agents and Brokers", value: "531210" },
    { key: "5313 Activities Related to Real EstateT ", value: "5313" },
    { key: "53131 Real Estate Property Managers ", value: "53131" },
    { key: "531311 Residential Property Managers ", value: "531311" },
    { key: "531312 Nonresidential Property Managers ", value: "531312" },
    { key: "53132 Offices of Real Estate Appraisers ", value: "53132" },
    { key: "531320 Offices of Real Estate Appraisers ", value: "531320" },
    { key: "53139 Other Activities Related to Real Estate ", value: "53139" },
    { key: "531390 Other Activities Related to Real Estate ", value: "531390" },
    { key: "532 Rental and Leasing ServicesT ", value: "532" },
    { key: "5321 Automotive Equipment Rental and LeasingT ", value: "5321" },
    { key: "53211 Passenger Car Rental and LeasingT ", value: "53211" },
    { key: "532111 Passenger Car Rental ", value: "532111" },
    { key: "532112 Passenger Car Leasing ", value: "532112" },
    { key: "53212 Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and LeasingT ", value: "53212" },
    { key: "532120 Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing ", value: "532120" },
    { key: "5322 Consumer Goods RentalT ", value: "5322" },
    { key: "53221 Consumer Electronics and Appliances RentalT ", value: "53221" },
    { key: "532210 Consumer Electronics and Appliances Rental", value: "532210" },
    { key: "53228 Other Consumer Goods RentalT ", value: "53228" },
    { key: "532281 Formal Wear and Costume Rental", value: "532281" },
    { key: "532282 Video Tape and Disc Rental", value: "532282" },
    { key: "532283 Home Health Equipment Rental ", value: "532283" },
    { key: "532284 Recreational Goods Rental ", value: "532284" },
    { key: "532289 All Other Consumer Goods Rental ", value: "532289" },
    { key: "5323 General Rental CentersT ", value: "5323" },
    { key: "53231 General Rental CentersT ", value: "53231" },
    { key: "532310 General Rental Centers", value: "532310" },
    { key: "5324 Commercial and Industrial Machinery and Equipment Rental and LeasingT ", value: "5324" },
    { key: "53241 Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and LeasingT ", value: "53241" },
    { key: "532411 Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing ", value: "532411" },
    { key: "532412 Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing ", value: "532412" },
    { key: "53242 Office Machinery and Equipment Rental and LeasingT ", value: "53242" },
    { key: "532420 Office Machinery and Equipment Rental and Leasing", value: "532420" },
    { key: "53249 Other Commercial and Industrial Machinery and Equipment Rental and LeasingT ", value: "53249" },
    { key: "532490 Other Commercial and Industrial Machinery and Equipment Rental and Leasing ", value: "532490" },
    { key: "533 Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)T ", value: "533" },
    { key: "5331 Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)T ", value: "5331" },
    { key: "53311 Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)T ", value: "53311" },
    { key: "533110 Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)", value: "533110" },
    { key: "54 Professional, Scientific, and Technical ServicesT", value: "54" },
    { key: "541 Professional, Scientific, and Technical ServicesT ", value: "541" },
    { key: "5411 Legal ServicesT", value: "5411" },
    { key: "54111 Offices of LawyersT ", value: "54111" },
    { key: "541110 Offices of Lawyers", value: "541110" },
    { key: "54112 Offices of NotariesT ", value: "54112" },
    { key: "541120 Offices of Notaries", value: "541120" },
    { key: "54119 Other Legal ServicesT ", value: "54119" },
    { key: "541191 Title Abstract and Settlement Offices ", value: "541191" },
    { key: "541199 All Other Legal Services ", value: "541199" },
    { key: "5412 Accounting, Tax Preparation, Bookkeeping, and Payroll ServicesT ", value: "5412" },
    { key: "54121 Accounting, Tax Preparation, Bookkeeping, and Payroll ServicesT ", value: "54121" },
    { key: "541211 Offices of Certified Public Accountants ", value: "541211" },
    { key: "541213 Tax Preparation Services ", value: "541213" },
    { key: "541214 Payroll Services ", value: "541214" },
    { key: "541219 Other Accounting Services ", value: "541219" },
    { key: "5413 Architectural, Engineering, and Related ServicesT ", value: "5413" },
    { key: "54131 Architectural ServicesT ", value: "54131" },
    { key: "541310 Architectural Services", value: "541310" },
    { key: "54132 Landscape Architectural ServicesT ", value: "54132" },
    { key: "541320 Landscape Architectural Services", value: "541320" },
    { key: "54133 Engineering ServicesT ", value: "54133" },
    { key: "541330 Engineering Services", value: "541330" },
    { key: "54134 Drafting ServicesT ", value: "54134" },
    { key: "541340 Drafting Services", value: "541340" },
    { key: "54135 Building Inspection ServicesT ", value: "54135" },
    { key: "541350 Building Inspection Services", value: "541350" },
    { key: "54136 Geophysical Surveying and Mapping ServicesT ", value: "54136" },
    { key: "541360 Geophysical Surveying and Mapping Services", value: "541360" },
    { key: "54137 Surveying and Mapping (except Geophysical) ServicesT ", value: "54137" },
    { key: "541370 Surveying and Mapping (except Geophysical) Services", value: "541370" },
    { key: "54138 Testing Laboratories and ServicesT ", value: "54138" },
    { key: "541380 Testing Laboratories and Services", value: "541380" },
    { key: "5414 Specialized Design ServicesT ", value: "5414" },
    { key: "54141 Interior Design ServicesT ", value: "54141" },
    { key: "541410 Interior Design Services", value: "541410" },
    { key: "54142 Industrial Design ServicesT ", value: "54142" },
    { key: "541420 Industrial Design Services", value: "541420" },
    { key: "54143 Graphic Design ServicesT ", value: "54143" },
    { key: "541430 Graphic Design Services", value: "541430" },
    { key: "54149 Other Specialized Design ServicesT ", value: "54149" },
    { key: "541490 Other Specialized Design Services", value: "541490" },
    { key: "5415 Computer Systems Design and Related ServicesT ", value: "5415" },
    { key: "54151 Computer Systems Design and Related ServicesT ", value: "54151" },
    { key: "541511 Custom Computer Programming Services ", value: "541511" },
    { key: "541512 Computer Systems Design Services ", value: "541512" },
    { key: "541513 Computer Facilities Management Services ", value: "541513" },
    { key: "541519 Other Computer Related Services", value: "541519" },
    { key: "5416 Management, Scientific, and Technical Consulting ServicesT ", value: "5416" },
    { key: "54161 Management Consulting ServicesT ", value: "54161" },
    { key: "541611 Administrative Management and General Management Consulting Services ", value: "541611" },
    { key: "541612 Human Resources Consulting Services ", value: "541612" },
    { key: "541613 Marketing Consulting Services ", value: "541613" },
    { key: "541614 Process, Physical Distribution, and Logistics Consulting Services ", value: "541614" },
    { key: "541618 Other Management Consulting Services ", value: "541618" },
    { key: "54162 Environmental Consulting ServicesT ", value: "54162" },
    { key: "541620 Environmental Consulting Services", value: "541620" },
    { key: "54169 Other Scientific and Technical Consulting ServicesT ", value: "54169" },
    { key: "541690 Other Scientific and Technical Consulting Services", value: "541690" },
    { key: "5417 Scientific Research and Development ServicesT ", value: "5417" },
    { key: "54171 Research and Development in the Physical, Engineering, and Life SciencesT ", value: "54171" },
    { key: "541713 Research and Development in Nanotechnology ", value: "541713" },
    { key: "541714 Research and Development in Biotechnology (except Nanobiotechnology)", value: "541714" },
    { key: "541715 Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology) ", value: "541715" },
    { key: "54172 Research and Development in the Social Sciences and HumanitiesT ", value: "54172" },
    { key: "541720 Research and Development in the Social Sciences and Humanities ", value: "541720" },
    { key: "5418 Advertising, Public Relations, and Related ServicesT ", value: "5418" },
    { key: "54181 Advertising AgenciesT ", value: "54181" },
    { key: "541810 Advertising Agencies", value: "541810" },
    { key: "54182 Public Relations AgenciesT ", value: "54182" },
    { key: "541820 Public Relations Agencies", value: "541820" },
    { key: "54183 Media Buying AgenciesT ", value: "54183" },
    { key: "541830 Media Buying Agencies", value: "541830" },
    { key: "54184 Media RepresentativesT ", value: "54184" },
    { key: "541840 Media Representatives", value: "541840" },
    { key: "54185 Indoor and Outdoor Display AdvertisingT ", value: "54185" },
    { key: "541850 Indoor and Outdoor Display Advertising", value: "541850" },
    { key: "54186 Direct Mail AdvertisingT ", value: "54186" },
    { key: "541860 Direct Mail Advertising", value: "541860" },
    { key: "54187 Advertising Material Distribution ServicesT ", value: "54187" },
    { key: "541870 Advertising Material Distribution Services", value: "541870" },
    { key: "54189 Other Services Related to AdvertisingT ", value: "54189" },
    { key: "541890 Other Services Related to Advertising ", value: "541890" },
    { key: "5419 Other Professional, Scientific, and Technical ServicesT ", value: "5419" },
    { key: "54191 Marketing Research and Public Opinion PollingT ", value: "54191" },
    { key: "541910 Marketing Research and Public Opinion Polling", value: "541910" },
    { key: "54192 Photographic ServicesT ", value: "54192" },
    { key: "541921 Photography Studios, Portrait ", value: "541921" },
    { key: "541922 Commercial Photography ", value: "541922" },
    { key: "54193 Translation and Interpretation ServicesT ", value: "54193" },
    { key: "541930 Translation and Interpretation Services", value: "541930" },
    { key: "54194 Veterinary ServicesT ", value: "54194" },
    { key: "541940 Veterinary Services ", value: "541940" },
    { key: "54199 All Other Professional, Scientific, and Technical ServicesT ", value: "54199" },
    { key: "541990 All Other Professional, Scientific, and Technical Services", value: "541990" },
    { key: "55 Management of Companies and EnterprisesT ", value: "55" },
    { key: "551 Management of Companies and EnterprisesT ", value: "551" },
    { key: "5511 Management of Companies and EnterprisesT ", value: "5511" },
    { key: "55111 Management of Companies and EnterprisesT ", value: "55111" },
    { key: "551111 Offices of Bank Holding Companies ", value: "551111" },
    { key: "551112 Offices of Other Holding Companies ", value: "551112" },
    { key: "551114 Corporate, Subsidiary, and Regional Managing Offices ", value: "551114" },
    { key: "56 Administrative and Support and Waste Management and Remediation ServicesT", value: "56" },
    { key: "561 Administrative and Support ServicesT ", value: "561" },
    { key: "5611 Office Administrative ServicesT ", value: "5611" },
    { key: "56111 Office Administrative ServicesT ", value: "56111" },
    { key: "561110 Office Administrative Services", value: "561110" },
    { key: "5612 Facilities Support ServicesT ", value: "5612" },
    { key: "56121 Facilities Support ServicesT ", value: "56121" },
    { key: "561210 Facilities Support Services", value: "561210" },
    { key: "5613 Employment ServicesT ", value: "5613" },
    { key: "56131 Employment Placement Agencies and Executive Search ServicesT ", value: "56131" },
    { key: "561311 Employment Placement Agencies ", value: "561311" },
    { key: "561312 Executive Search Services ", value: "561312" },
    { key: "56132 Temporary Help ServicesT ", value: "56132" },
    { key: "561320 Temporary Help Services", value: "561320" },
    { key: "56133 Professional Employer OrganizationsT ", value: "56133" },
    { key: "561330 Professional Employer Organizations", value: "561330" },
    { key: "5614 Business Support ServicesT ", value: "5614" },
    { key: "56141 Document Preparation ServicesT ", value: "56141" },
    { key: "561410 Document Preparation Services", value: "561410" },
    { key: "56142 Telephone Call CentersT ", value: "56142" },
    { key: "561421 Telephone Answering Services ", value: "561421" },
    { key: "561422 Telemarketing Bureaus and Other Contact Centers ", value: "561422" },
    { key: "56143 Business Service CentersT ", value: "56143" },
    { key: "561431 Private Mail Centers ", value: "561431" },
    { key: "561439 Other Business Service Centers (including Copy Shops) ", value: "561439" },
    { key: "56144 Collection AgenciesT ", value: "56144" },
    { key: "561440 Collection Agencies", value: "561440" },
    { key: "56145 Credit BureausT ", value: "56145" },
    { key: "561450 Credit Bureaus", value: "561450" },
    { key: "56149 Other Business Support ServicesT ", value: "56149" },
    { key: "561491 Repossession Services ", value: "561491" },
    { key: "561492 Court Reporting and Stenotype Services ", value: "561492" },
    { key: "561499 All Other Business Support Services ", value: "561499" },
    { key: "5615 Travel Arrangement and Reservation ServicesT ", value: "5615" },
    { key: "56151 Travel AgenciesT ", value: "56151" },
    { key: "561510 Travel Agencies", value: "561510" },
    { key: "56152 Tour OperatorsT ", value: "56152" },
    { key: "561520 Tour Operators", value: "561520" },
    { key: "56159 Other Travel Arrangement and Reservation ServicesT ", value: "56159" },
    { key: "561591 Convention and Visitors Bureaus ", value: "561591" },
    { key: "561599 All Other Travel Arrangement and Reservation Services ", value: "561599" },
    { key: "5616 Investigation and Security ServicesT ", value: "5616" },
    { key: "56161 Investigation, Guard, and Armored Car ServicesT ", value: "56161" },
    { key: "561611 Investigation and Personal Background Check Services ", value: "561611" },
    { key: "561612 Security Guards and Patrol Services ", value: "561612" },
    { key: "561613 Armored Car Services ", value: "561613" },
    { key: "56162 Security Systems ServicesT ", value: "56162" },
    { key: "561621 Security Systems Services (except Locksmiths) ", value: "561621" },
    { key: "561622 Locksmiths ", value: "561622" },
    { key: "5617 Services to Buildings and DwellingsT ", value: "5617" },
    { key: "56171 Exterminating and Pest Control ServicesT ", value: "56171" },
    { key: "561710 Exterminating and Pest Control Services", value: "561710" },
    { key: "56172 Janitorial ServicesT ", value: "56172" },
    { key: "561720 Janitorial Services ", value: "561720" },
    { key: "56173 Landscaping ServicesT ", value: "56173" },
    { key: "561730 Landscaping Services", value: "561730" },
    { key: "56174 Carpet and Upholstery Cleaning ServicesT ", value: "56174" },
    { key: "561740 Carpet and Upholstery Cleaning Services", value: "561740" },
    { key: "56179 Other Services to Buildings and DwellingsT ", value: "56179" },
    { key: "561790 Other Services to Buildings and Dwellings ", value: "561790" },
    { key: "5619 Other Support ServicesT ", value: "5619" },
    { key: "56191 Packaging and Labeling ServicesT ", value: "56191" },
    { key: "561910 Packaging and Labeling Services", value: "561910" },
    { key: "56192 Convention and Trade Show OrganizersT ", value: "56192" },
    { key: "561920 Convention and Trade Show Organizers", value: "561920" },
    { key: "56199 All Other Support ServicesT ", value: "56199" },
    { key: "561990 All Other Support Services", value: "561990" },
    { key: "562 Waste Management and Remediation ServicesT ", value: "562" },
    { key: "5621 Waste Collection ", value: "5621" },
    { key: "56211 Waste Collection ", value: "56211" },
    { key: "562111 Solid Waste Collection ", value: "562111" },
    { key: "562112 Hazardous Waste Collection ", value: "562112" },
    { key: "562119 Other Waste Collection ", value: "562119" },
    { key: "5622 Waste Treatment and Disposal ", value: "5622" },
    { key: "56221 Waste Treatment and Disposal ", value: "56221" },
    { key: "562211 Hazardous Waste Treatment and Disposal ", value: "562211" },
    { key: "562212 Solid Waste Landfill ", value: "562212" },
    { key: "562213 Solid Waste Combustors and Incinerators ", value: "562213" },
    { key: "562219 Other Nonhazardous Waste Treatment and Disposal ", value: "562219" },
    { key: "5629 Remediation and Other Waste Management Services ", value: "5629" },
    { key: "56291 Remediation Services ", value: "56291" },
    { key: "562910 Remediation Services ", value: "562910" },
    { key: "56292 Materials Recovery Facilities ", value: "56292" },
    { key: "562920 Materials Recovery Facilities ", value: "562920" },
    { key: "56299 All Other Waste Management Services ", value: "56299" },
    { key: "562991 Septic Tank and Related Services ", value: "562991" },
    { key: "562998 All Other Miscellaneous Waste Management Services ", value: "562998" },
    { key: "61 Educational ServicesT", value: "61" },
    { key: "611 Educational ServicesT ", value: "611" },
    { key: "6111 Elementary and Secondary SchoolsT ", value: "6111" },
    { key: "61111 Elementary and Secondary Schools ", value: "61111" },
    { key: "611110 Elementary and Secondary Schools ", value: "611110" },
    { key: "6112 Junior CollegesT ", value: "6112" },
    { key: "61121 Junior CollegesT ", value: "61121" },
    { key: "611210 Junior Colleges ", value: "611210" },
    { key: "6113 Colleges, Universities, and Professional SchoolsT ", value: "6113" },
    { key: "61131 Colleges, Universities, and Professional SchoolsT ", value: "61131" },
    { key: "611310 Colleges, Universities, and Professional Schools ", value: "611310" },
    { key: "6114 Business Schools and Computer and Management TrainingT ", value: "6114" },
    { key: "61141 Business and Secretarial SchoolsT ", value: "61141" },
    { key: "611410 Business and Secretarial Schools ", value: "611410" },
    { key: "61142 Computer TrainingT ", value: "61142" },
    { key: "611420 Computer Training ", value: "611420" },
    { key: "61143 Professional and Management Development TrainingT ", value: "61143" },
    { key: "611430 Professional and Management Development Training ", value: "611430" },
    { key: "6115 Technical and Trade SchoolsT ", value: "6115" },
    { key: "61151 Technical and Trade SchoolsT ", value: "61151" },
    { key: "611511 Cosmetology and Barber Schools ", value: "611511" },
    { key: "611512 Flight Training ", value: "611512" },
    { key: "611513 Apprenticeship Training ", value: "611513" },
    { key: "611519 Other Technical and Trade Schools ", value: "611519" },
    { key: "6116 Other Schools and InstructionT ", value: "6116" },
    { key: "61161 Fine Arts SchoolsT ", value: "61161" },
    { key: "611610 Fine Arts Schools ", value: "611610" },
    { key: "61162 Sports and Recreation InstructionT ", value: "61162" },
    { key: "611620 Sports and Recreation Instruction ", value: "611620" },
    { key: "61163 Language SchoolsT ", value: "61163" },
    { key: "611630 Language Schools ", value: "611630" },
    { key: "61169 All Other Schools and InstructionT ", value: "61169" },
    { key: "611691 Exam Preparation and Tutoring ", value: "611691" },
    { key: "611692 Automobile Driving Schools ", value: "611692" },
    { key: "611699 All Other Miscellaneous Schools and Instruction ", value: "611699" },
    { key: "6117 Educational Support ServicesT ", value: "6117" },
    { key: "61171 Educational Support ServicesT ", value: "61171" },
    { key: "611710 Educational Support Services", value: "611710" },
    { key: "62 Health Care and Social AssistanceT", value: "62" },
    { key: "621 Ambulatory Health Care ServicesT ", value: "621" },
    { key: "6211 Offices of PhysiciansT ", value: "6211" },
    { key: "62111 Offices of PhysiciansT ", value: "62111" },
    { key: "621111 Offices of Physicians (except Mental Health Specialists) ", value: "621111" },
    { key: "621112 Offices of Physicians, Mental Health Specialists ", value: "621112" },
    { key: "6212 Offices of DentistsT ", value: "6212" },
    { key: "62121 Offices of DentistsT ", value: "62121" },
    { key: "621210 Offices of Dentists ", value: "621210" },
    { key: "6213 Offices of Other Health PractitionersT ", value: "6213" },
    { key: "62131 Offices of ChiropractorsT ", value: "62131" },
    { key: "621310 Offices of Chiropractors ", value: "621310" },
    { key: "62132 Offices of OptometristsT ", value: "62132" },
    { key: "621320 Offices of Optometrists", value: "621320" },
    { key: "62133 Offices of Mental Health Practitioners (except Physicians)T ", value: "62133" },
    { key: "621330 Offices of Mental Health Practitioners (except Physicians) ", value: "621330" },
    { key: "62134 Offices of Physical, Occupational and Speech Therapists, and AudiologistsT ", value: "62134" },
    { key: "621340 Offices of Physical, Occupational and Speech Therapists, and Audiologists ", value: "621340" },
    { key: "62139 Offices of All Other Health PractitionersT ", value: "62139" },
    { key: "621391 Offices of Podiatrists ", value: "621391" },
    { key: "621399 Offices of All Other Miscellaneous Health Practitioners ", value: "621399" },
    { key: "6214 Outpatient Care CentersT ", value: "6214" },
    { key: "62141 Family Planning CentersT ", value: "62141" },
    { key: "621410 Family Planning Centers ", value: "621410" },
    { key: "62142 Outpatient Mental Health and Substance Abuse CentersT ", value: "62142" },
    { key: "621420 Outpatient Mental Health and Substance Abuse Centers ", value: "621420" },
    { key: "62149 Other Outpatient Care CentersT ", value: "62149" },
    { key: "621491 HMO Medical Centers ", value: "621491" },
    { key: "621492 Kidney Dialysis Centers ", value: "621492" },
    { key: "621493 Freestanding Ambulatory Surgical and Emergency Centers ", value: "621493" },
    { key: "621498 All Other Outpatient Care Centers ", value: "621498" },
    { key: "6215 Medical and Diagnostic LaboratoriesT ", value: "6215" },
    { key: "62151 Medical and Diagnostic LaboratoriesT ", value: "62151" },
    { key: "621511 Medical Laboratories ", value: "621511" },
    { key: "621512 Diagnostic Imaging Centers ", value: "621512" },
    { key: "6216 Home Health Care ServicesT ", value: "6216" },
    { key: "62161 Home Health Care ServicesT ", value: "62161" },
    { key: "621610 Home Health Care Services", value: "621610" },
    { key: "6219 Other Ambulatory Health Care ServicesT ", value: "6219" },
    { key: "62191 Ambulance ServicesT ", value: "62191" },
    { key: "621910 Ambulance Services ", value: "621910" },
    { key: "62199 All Other Ambulatory Health Care ServicesT ", value: "62199" },
    { key: "621991 Blood and Organ Banks ", value: "621991" },
    { key: "621999 All Other Miscellaneous Ambulatory Health Care Services ", value: "621999" },
    { key: "622 HospitalsT ", value: "622" },
    { key: "6221 General Medical and Surgical HospitalsT ", value: "6221" },
    { key: "62211 General Medical and Surgical HospitalsT ", value: "62211" },
    { key: "622110 General Medical and Surgical Hospitals ", value: "622110" },
    { key: "6222 Psychiatric and Substance Abuse HospitalsT ", value: "6222" },
    { key: "62221 Psychiatric and Substance Abuse HospitalsT ", value: "62221" },
    { key: "622210 Psychiatric and Substance Abuse Hospitals ", value: "622210" },
    { key: "6223 Specialty (except Psychiatric and Substance Abuse) HospitalsT ", value: "6223" },
    { key: "62231 Specialty (except Psychiatric and Substance Abuse) HospitalsT ", value: "62231" },
    { key: "622310 Specialty (except Psychiatric and Substance Abuse) Hospitals ", value: "622310" },
    { key: "623 Nursing and Residential Care FacilitiesT ", value: "623" },
    { key: "6231 Nursing Care Facilities (Skilled Nursing Facilities)T ", value: "6231" },
    { key: "62311 Nursing Care Facilities (Skilled Nursing Facilities)T ", value: "62311" },
    { key: "623110 Nursing Care Facilities (Skilled Nursing Facilities) ", value: "623110" },
    { key: "6232 Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse FacilitiesT", value: "6232" },
    { key: "62321 Residential Intellectual and Developmental Disability FacilitiesT ", value: "62321" },
    { key: "623210 Residential Intellectual and Developmental Disability Facilities ", value: "623210" },
    { key: "62322 Residential Mental Health and Substance Abuse FacilitiesT ", value: "62322" },
    { key: "623220 Residential Mental Health and Substance Abuse Facilities ", value: "623220" },
    { key: "6233 Continuing Care Retirement Communities and Assisted Living Facilities for the ElderlyT ", value: "6233" },
    { key: "62331 Continuing Care Retirement Communities and Assisted Living Facilities for the ElderlyT ", value: "62331" },
    { key: "623311 Continuing Care Retirement Communities ", value: "623311" },
    { key: "623312 Assisted Living Facilities for the Elderly ", value: "623312" },
    { key: "6239 Other Residential Care FacilitiesT ", value: "6239" },
    { key: "62399 Other Residential Care FacilitiesT ", value: "62399" },
    { key: "623990 Other Residential Care Facilities ", value: "623990" },
    { key: "624 Social AssistanceT ", value: "624" },
    { key: "6241 Individual and Family ServicesT ", value: "6241" },
    { key: "62411 Child and Youth ServicesT ", value: "62411" },
    { key: "624110 Child and Youth Services ", value: "624110" },
    { key: "62412 Services for the Elderly and Persons with DisabilitiesT ", value: "62412" },
    { key: "624120 Services for the Elderly and Persons with Disabilities ", value: "624120" },
    { key: "62419 Other Individual and Family ServicesT ", value: "62419" },
    { key: "624190 Other Individual and Family Services ", value: "624190" },
    { key: "6242 Community Food and Housing, and Emergency and Other Relief ServicesT ", value: "6242" },
    { key: "62421 Community Food ServicesT ", value: "62421" },
    { key: "624210 Community Food Services ", value: "624210" },
    { key: "62422 Community Housing ServicesT ", value: "62422" },
    { key: "624221 Temporary Shelters ", value: "624221" },
    { key: "624229 Other Community Housing Services ", value: "624229" },
    { key: "62423 Emergency and Other Relief ServicesT ", value: "62423" },
    { key: "624230 Emergency and Other Relief Services ", value: "624230" },
    { key: "6243 Vocational Rehabilitation ServicesT ", value: "6243" },
    { key: "62431 Vocational Rehabilitation ServicesT ", value: "62431" },
    { key: "624310 Vocational Rehabilitation Services ", value: "624310" },
    { key: "6244 Child Care ServicesT ", value: "6244" },
    { key: "62441 Child Care ServicesT ", value: "62441" },
    { key: "624410 Child Care Services ", value: "624410" },
    { key: "71 Arts, Entertainment, and RecreationT", value: "71" },
    { key: "711 Performing Arts, Spectator Sports, and Related IndustriesT ", value: "711" },
    { key: "7111 Performing Arts CompaniesT ", value: "7111" },
    { key: "71111 Theater Companies and Dinner TheatersT ", value: "71111" },
    { key: "711110 Theater Companies and Dinner Theaters ", value: "711110" },
    { key: "71112 Dance CompaniesT ", value: "71112" },
    { key: "711120 Dance Companies ", value: "711120" },
    { key: "71113 Musical Groups and ArtistsT ", value: "71113" },
    { key: "711130 Musical Groups and Artists ", value: "711130" },
    { key: "71119 Other Performing Arts CompaniesT ", value: "71119" },
    { key: "711190 Other Performing Arts Companies ", value: "711190" },
    { key: "7112 Spectator SportsT ", value: "7112" },
    { key: "71121 Spectator SportsT ", value: "71121" },
    { key: "711211 Sports Teams and Clubs ", value: "711211" },
    { key: "711212 Racetracks ", value: "711212" },
    { key: "711219 Other Spectator Sports ", value: "711219" },
    { key: "7113 Promoters of Performing Arts, Sports, and Similar EventsT ", value: "7113" },
    { key: "71131 Promoters of Performing Arts, Sports, and Similar Events with FacilitiesT ", value: "71131" },
    { key: "711310 Promoters of Performing Arts, Sports, and Similar Events with Facilities ", value: "711310" },
    { key: "71132 Promoters of Performing Arts, Sports, and Similar Events without FacilitiesT ", value: "71132" },
    { key: "711320 Promoters of Performing Arts, Sports, and Similar Events without Facilities ", value: "711320" },
    { key: "7114 Agents and Managers for Artists, Athletes, Entertainers, and Other Public FiguresT ", value: "7114" },
    { key: "71141 Agents and Managers for Artists, Athletes, Entertainers, and Other Public FiguresT ", value: "71141" },
    { key: "711410 Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures", value: "711410" },
    { key: "7115 Independent Artists, Writers, and PerformersT ", value: "7115" },
    { key: "71151 Independent Artists, Writers, and PerformersT ", value: "71151" },
    { key: "711510 Independent Artists, Writers, and Performers ", value: "711510" },
    { key: "712 Museums, Historical Sites, and Similar InstitutionsT ", value: "712" },
    { key: "7121 Museums, Historical Sites, and Similar InstitutionsT ", value: "7121" },
    { key: "71211 MuseumsT ", value: "71211" },
    { key: "712110 Museums ", value: "712110" },
    { key: "71212 Historical SitesT ", value: "71212" },
    { key: "712120 Historical Sites", value: "712120" },
    { key: "71213 Zoos and Botanical GardensT ", value: "71213" },
    { key: "712130 Zoos and Botanical Gardens ", value: "712130" },
    { key: "71219 Nature Parks and Other Similar InstitutionsT ", value: "71219" },
    { key: "712190 Nature Parks and Other Similar Institutions", value: "712190" },
    { key: "713 Amusement, Gambling, and Recreation IndustriesT ", value: "713" },
    { key: "7131 Amusement Parks and ArcadesT ", value: "7131" },
    { key: "71311 Amusement and Theme ParksT ", value: "71311" },
    { key: "713110 Amusement and Theme Parks ", value: "713110" },
    { key: "71312 Amusement ArcadesT ", value: "71312" },
    { key: "713120 Amusement Arcades", value: "713120" },
    { key: "7132 Gambling IndustriesT ", value: "7132" },
    { key: "71321 Casinos (except Casino Hotels)T ", value: "71321" },
    { key: "713210 Casinos (except Casino Hotels)", value: "713210" },
    { key: "71329 Other Gambling IndustriesT ", value: "71329" },
    { key: "713290 Other Gambling Industries ", value: "713290" },
    { key: "7139 Other Amusement and Recreation IndustriesT ", value: "7139" },
    { key: "71391 Golf Courses and Country ClubsT ", value: "71391" },
    { key: "713910 Golf Courses and Country Clubs", value: "713910" },
    { key: "71392 Skiing FacilitiesT ", value: "71392" },
    { key: "713920 Skiing Facilities", value: "713920" },
    { key: "71393 MarinasT ", value: "71393" },
    { key: "713930 Marinas", value: "713930" },
    { key: "71394 Fitness and Recreational Sports CentersT ", value: "71394" },
    { key: "713940 Fitness and Recreational Sports Centers ", value: "713940" },
    { key: "71395 Bowling CentersT ", value: "71395" },
    { key: "713950 Bowling Centers", value: "713950" },
    { key: "71399 All Other Amusement and Recreation IndustriesT ", value: "71399" },
    { key: "713990 All Other Amusement and Recreation Industries ", value: "713990" },
    { key: "72 Accommodation and Food ServicesT", value: "72" },
    { key: "721 AccommodationT ", value: "721" },
    { key: "7211 Traveler AccommodationT ", value: "7211" },
    { key: "72111 Hotels (except Casino Hotels) and MotelsT ", value: "72111" },
    { key: "721110 Hotels (except Casino Hotels) and Motels ", value: "721110" },
    { key: "72112 Casino HotelsT ", value: "72112" },
    { key: "721120 Casino Hotels", value: "721120" },
    { key: "72119 Other Traveler AccommodationT ", value: "72119" },
    { key: "721191 Bed-and-Breakfast Inns ", value: "721191" },
    { key: "721199 All Other Traveler Accommodation ", value: "721199" },
    { key: "7212 RV (Recreational Vehicle) Parks and Recreational CampsT ", value: "7212" },
    { key: "72121 RV (Recreational Vehicle) Parks and Recreational CampsT ", value: "72121" },
    { key: "721211 RV (Recreational Vehicle) Parks and Campgrounds ", value: "721211" },
    { key: "721214 Recreational and Vacation Camps (except Campgrounds) ", value: "721214" },
    { key: "7213 Rooming and Boarding Houses, Dormitories, and Workers' CampsT ", value: "7213" },
    { key: "72131 Rooming and Boarding Houses, Dormitories, and Workers' CampsT ", value: "72131" },
    { key: "721310 Rooming and Boarding Houses, Dormitories, and Workers' Camps ", value: "721310" },
    { key: "722 Food Services and Drinking PlacesT ", value: "722" },
    { key: "7223 Special Food ServicesT ", value: "7223" },
    { key: "72231 Food Service ContractorsT ", value: "72231" },
    { key: "722310 Food Service Contractors", value: "722310" },
    { key: "72232 CaterersT ", value: "72232" },
    { key: "722320 Caterers", value: "722320" },
    { key: "72233 Mobile Food ServicesT ", value: "72233" },
    { key: "722330 Mobile Food Services", value: "722330" },
    { key: "7224 Drinking Places (Alcoholic Beverages)T ", value: "7224" },
    { key: "72241 Drinking Places (Alcoholic Beverages)T ", value: "72241" },
    { key: "722410 Drinking Places (Alcoholic Beverages) ", value: "722410" },
    { key: "7225 Restaurants and Other Eating PlacesT", value: "7225" },
    { key: "72251 Restaurants and Other Eating PlacesT", value: "72251" },
    { key: "722511 Full-Service Restaurants ", value: "722511" },
    { key: "722513 Limited-Service Restaurants ", value: "722513" },
    { key: "722514 Cafeterias, Grill Buffets, and Buffets ", value: "722514" },
    { key: "722515 Snack and Nonalcoholic Beverage Bars ", value: "722515" },
    { key: "81 Other Services (except Public Administration)T", value: "81" },
    { key: "811 Repair and MaintenanceT ", value: "811" },
    { key: "8111 Automotive Repair and MaintenanceT ", value: "8111" },
    { key: "81111 Automotive Mechanical and Electrical Repair and MaintenanceT ", value: "81111" },
    { key: "811111 General Automotive Repair ", value: "811111" },
    { key: "811114 Specialized Automotive Repair ", value: "811114" },
    { key: "81112 Automotive Body, Paint, Interior, and Glass RepairT", value: "81112" },
    { key: "811121 Automotive Body, Paint, and Interior Repair and Maintenance ", value: "811121" },
    { key: "811122 Automotive Glass Replacement Shops ", value: "811122" },
    { key: "81119 Other Automotive Repair and MaintenanceT ", value: "81119" },
    { key: "811191 Automotive Oil Change and Lubrication Shops ", value: "811191" },
    { key: "811192 Car Washes ", value: "811192" },
    { key: "811198 All Other Automotive Repair and Maintenance ", value: "811198" },
    { key: "8112 Electronic and Precision Equipment Repair and MaintenanceT ", value: "8112" },
    { key: "81121 Electronic and Precision Equipment Repair and MaintenanceT ", value: "81121" },
    { key: "811210 Electronic and Precision Equipment Repair and Maintenance ", value: "811210" },
    { key: "8113 Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and MaintenanceT ", value: "8113" },
    { key: "81131 Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and MaintenanceT ", value: "81131" },
    { key: "811310 Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance ", value: "811310" },
    { key: "8114 Personal and Household Goods Repair and MaintenanceT ", value: "8114" },
    { key: "81141 Home and Garden Equipment and Appliance Repair and MaintenanceT ", value: "81141" },
    { key: "811411 Home and Garden Equipment Repair and Maintenance ", value: "811411" },
    { key: "811412 Appliance Repair and Maintenance ", value: "811412" },
    { key: "81142 Reupholstery and Furniture RepairT ", value: "81142" },
    { key: "811420 Reupholstery and Furniture Repair", value: "811420" },
    { key: "81143 Footwear and Leather Goods RepairT", value: "81143" },
    { key: "811430 Footwear and Leather Goods Repair", value: "811430" },
    { key: "81149 Other Personal and Household Goods Repair and MaintenanceT ", value: "81149" },
    { key: "811490 Other Personal and Household Goods Repair and Maintenance ", value: "811490" },
    { key: "812 Personal and Laundry ServicesT", value: "812" },
    { key: "8121 Personal Care Services ", value: "8121" },
    { key: "81211 Hair, Nail, and Skin Care Services ", value: "81211" },
    { key: "812111 Barber Shops ", value: "812111" },
    { key: "812112 Beauty Salons ", value: "812112" },
    { key: "812113 Nail Salons ", value: "812113" },
    { key: "81219 Other Personal Care Services ", value: "81219" },
    { key: "812191 Diet and Weight Reducing Centers ", value: "812191" },
    { key: "812199 Other Personal Care Services ", value: "812199" },
    { key: "8122 Death Care Services ", value: "8122" },
    { key: "81221 Funeral Homes and Funeral Services ", value: "81221" },
    { key: "812210 Funeral Homes and Funeral Services ", value: "812210" },
    { key: "81222 Cemeteries and Crematories ", value: "81222" },
    { key: "812220 Cemeteries and Crematories ", value: "812220" },
    { key: "8123 Drycleaning and Laundry Services ", value: "8123" },
    { key: "81231 Coin-Operated Laundries and Drycleaners ", value: "81231" },
    { key: "812310 Coin-Operated Laundries and Drycleaners ", value: "812310" },
    { key: "81232 Drycleaning and Laundry Services (except Coin-Operated) ", value: "81232" },
    { key: "812320 Drycleaning and Laundry Services (except Coin-Operated) ", value: "812320" },
    { key: "81233 Linen and Uniform Supply ", value: "81233" },
    { key: "812331 Linen Supply ", value: "812331" },
    { key: "812332 Industrial Launderers ", value: "812332" },
    { key: "8129 Other Personal Services ", value: "8129" },
    { key: "81291 Pet Care (except Veterinary) Services ", value: "81291" },
    { key: "812910 Pet Care (except Veterinary) Services ", value: "812910" },
    { key: "81292 Photofinishing ", value: "81292" },
    { key: "812921 Photofinishing Laboratories (except One-Hour) ", value: "812921" },
    { key: "812922 One-Hour Photofinishing ", value: "812922" },
    { key: "81293 Parking Lots and Garages ", value: "81293" },
    { key: "812930 Parking Lots and Garages ", value: "812930" },
    { key: "81299 All Other Personal Services ", value: "81299" },
    { key: "812990 All Other Personal Services ", value: "812990" },
    { key: "813 Religious, Grantmaking, Civic, Professional, and Similar OrganizationsT", value: "813" },
    { key: "8131 Religious Organizations ", value: "8131" },
    { key: "81311 Religious Organizations ", value: "81311" },
    { key: "813110 Religious Organizations ", value: "813110" },
    { key: "8132 Grantmaking and Giving Services ", value: "8132" },
    { key: "81321 Grantmaking and Giving Services ", value: "81321" },
    { key: "813211 Grantmaking Foundations ", value: "813211" },
    { key: "813212 Voluntary Health Organizations ", value: "813212" },
    { key: "813219 Other Grantmaking and Giving Services ", value: "813219" },
    { key: "8133 Social Advocacy Organizations ", value: "8133" },
    { key: "81331 Social Advocacy Organizations ", value: "81331" },
    { key: "813311 Human Rights Organizations ", value: "813311" },
    { key: "813312 Environment, Conservation and Wildlife Organizations ", value: "813312" },
    { key: "813319 Other Social Advocacy Organizations ", value: "813319" },
    { key: "8134 Civic and Social Organizations ", value: "8134" },
    { key: "81341 Civic and Social Organizations ", value: "81341" },
    { key: "813410 Civic and Social Organizations ", value: "813410" },
    { key: "8139 Business, Professional, Labor, Political, and Similar Organizations ", value: "8139" },
    { key: "81391 Business Associations ", value: "81391" },
    { key: "813910 Business Associations ", value: "813910" },
    { key: "81392 Professional Organizations ", value: "81392" },
    { key: "813920 Professional Organizations ", value: "813920" },
    { key: "81393 Labor Unions and Similar Labor Organizations ", value: "81393" },
    { key: "813930 Labor Unions and Similar Labor Organizations ", value: "813930" },
    { key: "81394 Political Organizations ", value: "81394" },
    { key: "813940 Political Organizations ", value: "813940" },
    { key: "81399 Other Similar Organizations (except Business, Professional, Labor, and Political Organizations) ", value: "81399" },
    { key: "813990 Other Similar Organizations (except Business, Professional, Labor, and Political Organizations) ", value: "813990" },
    { key: "814 Private HouseholdsT ", value: "814" },
    { key: "8141 Private HouseholdsT ", value: "8141" },
    { key: "81411 Private HouseholdsT ", value: "81411" },
    { key: "814110 Private Households", value: "814110" },
    { key: "92 Public AdministrationT", value: "92" },
    { key: "921 Executive, Legislative, and Other General Government Support ", value: "921" },
    { key: "9211 Executive, Legislative, and Other General Government Support ", value: "9211" },
    { key: "92111 Executive Offices ", value: "92111" },
    { key: "921110 Executive Offices ", value: "921110" },
    { key: "92112 Legislative Bodies ", value: "92112" },
    { key: "921120 Legislative Bodies ", value: "921120" },
    { key: "92113 Public Finance Activities ", value: "92113" },
    { key: "921130 Public Finance Activities ", value: "921130" },
    { key: "92114 Executive and Legislative Offices, Combined ", value: "92114" },
    { key: "921140 Executive and Legislative Offices, Combined ", value: "921140" },
    { key: "92115 American Indian and Alaska Native Tribal Governments ", value: "92115" },
    { key: "921150 American Indian and Alaska Native Tribal Governments ", value: "921150" },
    { key: "92119 Other General Government Support ", value: "92119" },
    { key: "921190 Other General Government Support ", value: "921190" },
    { key: "922 Justice, Public Order, and Safety Activities ", value: "922" },
    { key: "9221 Justice, Public Order, and Safety Activities ", value: "9221" },
    { key: "92211 Courts ", value: "92211" },
    { key: "922110 Courts ", value: "922110" },
    { key: "92212 Police Protection ", value: "92212" },
    { key: "922120 Police Protection ", value: "922120" },
    { key: "92213 Legal Counsel and Prosecution ", value: "92213" },
    { key: "922130 Legal Counsel and Prosecution ", value: "922130" },
    { key: "92214 Correctional Institutions ", value: "92214" },
    { key: "922140 Correctional Institutions ", value: "922140" },
    { key: "92215 Parole Offices and Probation Offices ", value: "92215" },
    { key: "922150 Parole Offices and Probation Offices ", value: "922150" },
    { key: "92216 Fire Protection ", value: "92216" },
    { key: "922160 Fire Protection ", value: "922160" },
    { key: "92219 Other Justice, Public Order, and Safety Activities ", value: "92219" },
    { key: "922190 Other Justice, Public Order, and Safety Activities ", value: "922190" },
    { key: "923 Administration of Human Resource Programs ", value: "923" },
    { key: "9231 Administration of Human Resource Programs ", value: "9231" },
    { key: "92311 Administration of Education Programs ", value: "92311" },
    { key: "923110 Administration of Education Programs ", value: "923110" },
    { key: "92312 Administration of Public Health Programs ", value: "92312" },
    { key: "923120 Administration of Public Health Programs ", value: "923120" },
    { key: "92313 Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs) ", value: "92313" },
    { key: "923130 Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs) ", value: "923130" },
    { key: "92314 Administration of Veterans' Affairs ", value: "92314" },
    { key: "923140 Administration of Veterans' Affairs ", value: "923140" },
    { key: "924 Administration of Environmental Quality Programs ", value: "924" },
    { key: "9241 Administration of Environmental Quality Programs ", value: "9241" },
    { key: "92411 Administration of Air and Water Resource and Solid Waste Management Programs ", value: "92411" },
    { key: "924110 Administration of Air and Water Resource and Solid Waste Management Programs ", value: "924110" },
    { key: "92412 Administration of Conservation Programs ", value: "92412" },
    { key: "924120 Administration of Conservation Programs ", value: "924120" },
    { key: "925 Administration of Housing Programs, Urban Planning, and Community Development ", value: "925" },
    { key: "9251 Administration of Housing Programs, Urban Planning, and Community Development ", value: "9251" },
    { key: "92511 Administration of Housing Programs ", value: "92511" },
    { key: "925110 Administration of Housing Programs ", value: "925110" },
    { key: "92512 Administration of Urban Planning and Community and Rural Development ", value: "92512" },
    { key: "925120 Administration of Urban Planning and Community and Rural Development ", value: "925120" },
    { key: "926 Administration of Economic Programs ", value: "926" },
    { key: "9261 Administration of Economic Programs ", value: "9261" },
    { key: "92611 Administration of General Economic Programs ", value: "92611" },
    { key: "926110 Administration of General Economic Programs ", value: "926110" },
    { key: "92612 Regulation and Administration of Transportation Programs ", value: "92612" },
    { key: "926120 Regulation and Administration of Transportation Programs ", value: "926120" },
    { key: "92613 Regulation and Administration of Communications, Electric, Gas, and Other Utilities ", value: "92613" },
    { key: "926130 Regulation and Administration of Communications, Electric, Gas, and Other Utilities ", value: "926130" },
    { key: "92614 Regulation of Agricultural Marketing and Commodities ", value: "92614" },
    { key: "926140 Regulation of Agricultural Marketing and Commodities ", value: "926140" },
    { key: "92615 Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors ", value: "92615" },
    { key: "926150 Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors ", value: "926150" },
    { key: "927 Space Research and Technology ", value: "927" },
    { key: "9271 Space Research and Technology ", value: "9271" },
    { key: "92711 Space Research and Technology ", value: "92711" },
    { key: "927110 Space Research and Technology ", value: "927110" },
    { key: "928 National Security and International Affairs ", value: "928" },
    { key: "9281 National Security and International Affairs ", value: "9281" },
    { key: "92811 National Security ", value: "92811" },
    { key: "928110 National Security ", value: "928110" },
    { key: "92812 International Affairs ", value: "92812" },
    { key: "928120 International Affairs ", value: "928120" },
  ],
  // exclusively 6-digits
  "NatureOfBusiness6": [{ key: "111110 Soybean Farming", value: "111110" },
  { key: "111120 Oilseed (except Soybean) Farming ", value: "111120" },
  { key: "111130 Dry Pea and Bean Farming ", value: "111130" },
  { key: "111140 Wheat Farming", value: "111140" },
  { key: "111150 Corn Farming ", value: "111150" },
  { key: "111160 Rice Farming", value: "111160" },
  { key: "111191 Oilseed and Grain Combination Farming ", value: "111191" },
  { key: "111199 All Other Grain Farming ", value: "111199" },
  { key: "111211 Potato Farming ", value: "111211" },
  { key: "111219 Other Vegetable (except Potato) and Melon Farming ", value: "111219" },
  { key: "111310 Orange Groves", value: "111310" },
  { key: "111320 Citrus (except Orange) Groves ", value: "111320" },
  { key: "111331 Apple Orchards ", value: "111331" },
  { key: "111332 Grape Vineyards ", value: "111332" },
  { key: "111333 Strawberry Farming ", value: "111333" },
  { key: "111334 Berry (except Strawberry) Farming ", value: "111334" },
  { key: "111335 Tree Nut Farming ", value: "111335" },
  { key: "111336 Fruit and Tree Nut Combination Farming ", value: "111336" },
  { key: "111339 Other Noncitrus Fruit Farming ", value: "111339" },
  { key: "111411 Mushroom Production ", value: "111411" },
  { key: "111419 Other Food Crops Grown Under Cover ", value: "111419" },
  { key: "111421 Nursery and Tree Production ", value: "111421" },
  { key: "111422 Floriculture Production ", value: "111422" },
  { key: "111910 Tobacco Farming", value: "111910" },
  { key: "111920 Cotton Farming", value: "111920" },
  { key: "111930 Sugarcane Farming", value: "111930" },
  { key: "111940 Hay Farming ", value: "111940" },
  { key: "111991 Sugar Beet Farming ", value: "111991" },
  { key: "111992 Peanut Farming ", value: "111992" },
  { key: "111998 All Other Miscellaneous Crop Farming ", value: "111998" },
  { key: "112111 Beef Cattle Ranching and Farming ", value: "112111" },
  { key: "112112 Cattle Feedlots ", value: "112112" },
  { key: "112120 Dairy Cattle and Milk Production", value: "112120" },
  { key: "112130 Dual-Purpose Cattle Ranching and Farming ", value: "112130" },
  { key: "112210 Hog and Pig Farming ", value: "112210" },
  { key: "112310 Chicken Egg Production ", value: "112310" },
  { key: "112320 Broilers and Other Meat Type Chicken Production ", value: "112320" },
  { key: "112330 Turkey Production", value: "112330" },
  { key: "112340 Poultry Hatcheries", value: "112340" },
  { key: "112390 Other Poultry Production ", value: "112390" },
  { key: "112410 Sheep Farming", value: "112410" },
  { key: "112420 Goat Farming", value: "112420" },
  { key: "112511 Finfish Farming and Fish Hatcheries ", value: "112511" },
  { key: "112512 Shellfish Farming ", value: "112512" },
  { key: "112519 Other Aquaculture ", value: "112519" },
  { key: "112910 Apiculture", value: "112910" },
  { key: "112920 Horses and Other Equine Production", value: "112920" },
  { key: "112930 Fur-Bearing Animal and Rabbit Production", value: "112930" },
  { key: "112990 All Other Animal Production ", value: "112990" },
  { key: "113110 Timber Tract Operations", value: "113110" },
  { key: "113210 Forest Nurseries and Gathering of Forest Products ", value: "113210" },
  { key: "113310 Logging ", value: "113310" },
  { key: "114111 Finfish Fishing ", value: "114111" },
  { key: "114112 Shellfish Fishing ", value: "114112" },
  { key: "114119 Other Marine Fishing ", value: "114119" },
  { key: "114210 Hunting and Trapping", value: "114210" },
  { key: "115111 Cotton Ginning ", value: "115111" },
  { key: "115112 Soil Preparation, Planting, and Cultivating ", value: "115112" },
  { key: "115113 Crop Harvesting, Primarily by Machine ", value: "115113" },
  { key: "115114 Postharvest Crop Activities (except Cotton Ginning) ", value: "115114" },
  { key: "115115 Farm Labor Contractors and Crew Leaders ", value: "115115" },
  { key: "115116 Farm Management Services ", value: "115116" },
  { key: "115210 Support Activities for Animal Production", value: "115210" },
  { key: "115310 Support Activities for Forestry", value: "115310" },
  { key: "211120 Crude Petroleum Extraction ", value: "211120" },
  { key: "211130 Natural Gas Extraction ", value: "211130" },
  { key: "212114 Surface Coal Mining", value: "212114" },
  { key: "212115 Underground Coal Mining", value: "212115" },
  { key: "212210 Iron Ore Mining", value: "212210" },
  { key: "212220 Gold Ore and Silver Ore Mining", value: "212220" },
  { key: "212230 Copper, Nickel, Lead, and Zinc Mining", value: "212230" },
  { key: "212290 Other Metal Ore Mining", value: "212290" },
  { key: "212311 Dimension Stone Mining and Quarrying ", value: "212311" },
  { key: "212312 Crushed and Broken Limestone Mining and Quarrying ", value: "212312" },
  { key: "212313 Crushed and Broken Granite Mining and Quarrying ", value: "212313" },
  { key: "212319 Other Crushed and Broken Stone Mining and Quarrying ", value: "212319" },
  { key: "212321 Construction Sand and Gravel Mining ", value: "212321" },
  { key: "212322 Industrial Sand Mining ", value: "212322" },
  { key: "212323 Kaolin, Clay, and Ceramic and Refractory Minerals Mining ", value: "212323" },
  { key: "212390 Other Nonmetallic Mineral Mining and Quarrying ", value: "212390" },
  { key: "213111 Drilling Oil and Gas Wells", value: "213111" },
  { key: "213112 Support Activities for Oil and Gas Operations ", value: "213112" },
  { key: "213113 Support Activities for Coal Mining ", value: "213113" },
  { key: "213114 Support Activities for Metal Mining ", value: "213114" },
  { key: "213115 Support Activities for Nonmetallic Minerals (except Fuels) Mining ", value: "213115" },
  { key: "221111 Hydroelectric Power Generation ", value: "221111" },
  { key: "221112 Fossil Fuel Electric Power Generation ", value: "221112" },
  { key: "221113 Nuclear Electric Power Generation ", value: "221113" },
  { key: "221114 Solar Electric Power Generation ", value: "221114" },
  { key: "221115 Wind Electric Power Generation ", value: "221115" },
  { key: "221116 Geothermal Electric Power Generation ", value: "221116" },
  { key: "221117 Biomass Electric Power Generation ", value: "221117" },
  { key: "221118 Other Electric Power Generation ", value: "221118" },
  { key: "221121 Electric Bulk Power Transmission and Control ", value: "221121" },
  { key: "221122 Electric Power Distribution ", value: "221122" },
  { key: "221210 Natural Gas Distribution ", value: "221210" },
  { key: "221310 Water Supply and Irrigation Systems ", value: "221310" },
  { key: "221320 Sewage Treatment Facilities ", value: "221320" },
  { key: "221330 Steam and Air-Conditioning Supply ", value: "221330" },
  { key: "236115 New Single-Family Housing Construction (except For-Sale Builders) ", value: "236115" },
  { key: "236116 New Multifamily Housing Construction (except For-Sale Builders) ", value: "236116" },
  { key: "236117 New Housing For-Sale Builders ", value: "236117" },
  { key: "236118 Residential Remodelers ", value: "236118" },
  { key: "236210 Industrial Building Construction ", value: "236210" },
  { key: "236220 Commercial and Institutional Building Construction ", value: "236220" },
  { key: "237110 Water and Sewer Line and Related Structures Construction ", value: "237110" },
  { key: "237120 Oil and Gas Pipeline and Related Structures Construction ", value: "237120" },
  { key: "237130 Power and Communication Line and Related Structures Construction ", value: "237130" },
  { key: "237210 Land Subdivision ", value: "237210" },
  { key: "237310 Highway, Street, and Bridge Construction ", value: "237310" },
  { key: "237990 Other Heavy and Civil Engineering Construction ", value: "237990" },
  { key: "238110 Poured Concrete Foundation and Structure Contractors ", value: "238110" },
  { key: "238120 Structural Steel and Precast Concrete Contractors ", value: "238120" },
  { key: "238130 Framing Contractors ", value: "238130" },
  { key: "238140 Masonry Contractors ", value: "238140" },
  { key: "238150 Glass and Glazing Contractors ", value: "238150" },
  { key: "238160 Roofing Contractors ", value: "238160" },
  { key: "238170 Siding Contractors ", value: "238170" },
  { key: "238190 Other Foundation, Structure, and Building Exterior Contractors ", value: "238190" },
  { key: "238210 Electrical Contractors and Other Wiring Installation Contractors", value: "238210" },
  { key: "238220 Plumbing, Heating, and Air-Conditioning Contractors ", value: "238220" },
  { key: "238290 Other Building Equipment Contractors ", value: "238290" },
  { key: "238310 Drywall and Insulation Contractors ", value: "238310" },
  { key: "238320 Painting and Wall Covering Contractors", value: "238320" },
  { key: "238330 Flooring Contractors", value: "238330" },
  { key: "238340 Tile and Terrazzo Contractors", value: "238340" },
  { key: "238350 Finish Carpentry Contractors", value: "238350" },
  { key: "238390 Other Building Finishing Contractors", value: "238390" },
  { key: "238910 Site Preparation Contractors", value: "238910" },
  { key: "238990 All Other Specialty Trade Contractors", value: "238990" },
  { key: "311111 Dog and Cat Food Manufacturing ", value: "311111" },
  { key: "311119 Other Animal Food Manufacturing ", value: "311119" },
  { key: "311211 Flour Milling ", value: "311211" },
  { key: "311212 Rice Milling ", value: "311212" },
  { key: "311213 Malt Manufacturing ", value: "311213" },
  { key: "311221 Wet Corn Milling and Starch Manufacturing ", value: "311221" },
  { key: "311224 Soybean and Other Oilseed Processing ", value: "311224" },
  { key: "311225 Fats and Oils Refining and Blending ", value: "311225" },
  { key: "311230 Breakfast Cereal Manufacturing", value: "311230" },
  { key: "311313 Beet Sugar Manufacturing ", value: "311313" },
  { key: "311314 Cane Sugar Manufacturing ", value: "311314" },
  { key: "311340 Nonchocolate Confectionery Manufacturing", value: "311340" },
  { key: "311351 Chocolate and Confectionery Manufacturing from Cacao Beans ", value: "311351" },
  { key: "311352 Confectionery Manufacturing from Purchased Chocolate ", value: "311352" },
  { key: "311411 Frozen Fruit, Juice, and Vegetable Manufacturing ", value: "311411" },
  { key: "311412 Frozen Specialty Food Manufacturing ", value: "311412" },
  { key: "311421 Fruit and Vegetable Canning ", value: "311421" },
  { key: "311422 Specialty Canning ", value: "311422" },
  { key: "311423 Dried and Dehydrated Food Manufacturing ", value: "311423" },
  { key: "311511 Fluid Milk Manufacturing ", value: "311511" },
  { key: "311512 Creamery Butter Manufacturing ", value: "311512" },
  { key: "311513 Cheese Manufacturing ", value: "311513" },
  { key: "311514 Dry, Condensed, and Evaporated Dairy Product Manufacturing ", value: "311514" },
  { key: "311520 Ice Cream and Frozen Dessert Manufacturing", value: "311520" },
  { key: "311611 Animal (except Poultry) Slaughtering ", value: "311611" },
  { key: "311612 Meat Processed from Carcasses ", value: "311612" },
  { key: "311613 Rendering and Meat Byproduct Processing ", value: "311613" },
  { key: "311615 Poultry Processing ", value: "311615" },
  { key: "311710 Seafood Product Preparation and Packaging", value: "311710" },
  { key: "311811 Retail Bakeries ", value: "311811" },
  { key: "311812 Commercial Bakeries ", value: "311812" },
  { key: "311813 Frozen Cakes, Pies, and Other Pastries Manufacturing ", value: "311813" },
  { key: "311821 Cookie and Cracker Manufacturing ", value: "311821" },
  { key: "311824 Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour ", value: "311824" },
  { key: "311830 Tortilla Manufacturing", value: "311830" },
  { key: "311911 Roasted Nuts and Peanut Butter Manufacturing ", value: "311911" },
  { key: "311919 Other Snack Food Manufacturing ", value: "311919" },
  { key: "311920 Coffee and Tea Manufacturing ", value: "311920" },
  { key: "311930 Flavoring Syrup and Concentrate Manufacturing", value: "311930" },
  { key: "311941 Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing ", value: "311941" },
  { key: "311942 Spice and Extract Manufacturing ", value: "311942" },
  { key: "311991 Perishable Prepared Food Manufacturing ", value: "311991" },
  { key: "311999 All Other Miscellaneous Food Manufacturing ", value: "311999" },
  { key: "312111 Soft Drink Manufacturing ", value: "312111" },
  { key: "312112 Bottled Water Manufacturing ", value: "312112" },
  { key: "312113 Ice Manufacturing ", value: "312113" },
  { key: "312120 Breweries", value: "312120" },
  { key: "312130 Wineries ", value: "312130" },
  { key: "312140 Distilleries ", value: "312140" },
  { key: "312230 Tobacco Manufacturing ", value: "312230" },
  { key: "313110 Fiber, Yarn, and Thread Mills ", value: "313110" },
  { key: "313210 Broadwoven Fabric Mills", value: "313210" },
  { key: "313220 Narrow Fabric Mills and Schiffli Machine Embroidery", value: "313220" },
  { key: "313230 Nonwoven Fabric Mills", value: "313230" },
  { key: "313240 Knit Fabric Mills", value: "313240" },
  { key: "313310 Textile and Fabric Finishing Mills ", value: "313310" },
  { key: "313320 Fabric Coating Mills", value: "313320" },
  { key: "314110 Carpet and Rug Mills", value: "314110" },
  { key: "314120 Curtain and Linen Mills", value: "314120" },
  { key: "314910 Textile Bag and Canvas Mills ", value: "314910" },
  { key: "314994 Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills ", value: "314994" },
  { key: "314999 All Other Miscellaneous Textile Product Mills ", value: "314999" },
  { key: "315120 Apparel Knitting Mills", value: "315120" },
  { key: "315210 Cut and Sew Apparel Contractors ", value: "315210" },
  { key: "315250 Cut and Sew Apparel Manufacturing (except Contractors) ", value: "315250" },
  { key: "315990 Apparel Accessories and Other Apparel Manufacturing ", value: "315990" },
  { key: "316110 Leather and Hide Tanning and Finishing", value: "316110" },
  { key: "316210 Footwear Manufacturing ", value: "316210" },
  { key: "316990 Other Leather and Allied Product Manufacturing ", value: "316990" },
  { key: "321113 Sawmills ", value: "321113" },
  { key: "321114 Wood Preservation ", value: "321114" },
  { key: "321211 Hardwood Veneer and Plywood Manufacturing ", value: "321211" },
  { key: "321212 Softwood Veneer and Plywood Manufacturing ", value: "321212" },
  { key: "321215 Engineered Wood Member Manufacturing ", value: "321215" },
  { key: "321219 Reconstituted Wood Product Manufacturing ", value: "321219" },
  { key: "321911 Wood Window and Door Manufacturing ", value: "321911" },
  { key: "321912 Cut Stock, Resawing Lumber, and Planing ", value: "321912" },
  { key: "321918 Other Millwork (including Flooring) ", value: "321918" },
  { key: "321920 Wood Container and Pallet Manufacturing", value: "321920" },
  { key: "321991 Manufactured Home (Mobile Home) Manufacturing ", value: "321991" },
  { key: "321992 Prefabricated Wood Building Manufacturing ", value: "321992" },
  { key: "321999 All Other Miscellaneous Wood Product Manufacturing ", value: "321999" },
  { key: "322110 Pulp Mills ", value: "322110" },
  { key: "322120 Paper Mills ", value: "322120" },
  { key: "322130 Paperboard Mills ", value: "322130" },
  { key: "322211 Corrugated and Solid Fiber Box Manufacturing ", value: "322211" },
  { key: "322212 Folding Paperboard Box Manufacturing ", value: "322212" },
  { key: "322219 Other Paperboard Container Manufacturing ", value: "322219" },
  { key: "322220 Paper Bag and Coated and Treated Paper Manufacturing", value: "322220" },
  { key: "322230 Stationery Product Manufacturing", value: "322230" },
  { key: "322291 Sanitary Paper Product Manufacturing ", value: "322291" },
  { key: "322299 All Other Converted Paper Product Manufacturing ", value: "322299" },
  { key: "323111 Commercial Printing (except Screen and Books) ", value: "323111" },
  { key: "323113 Commercial Screen Printing ", value: "323113" },
  { key: "323117 Books Printing ", value: "323117" },
  { key: "323120 Support Activities for Printing", value: "323120" },
  { key: "324110 Petroleum Refineries", value: "324110" },
  { key: "324121 Asphalt Paving Mixture and Block Manufacturing ", value: "324121" },
  { key: "324122 Asphalt Shingle and Coating Materials Manufacturing ", value: "324122" },
  { key: "324191 Petroleum Lubricating Oil and Grease Manufacturing ", value: "324191" },
  { key: "324199 All Other Petroleum and Coal Products Manufacturing ", value: "324199" },
  { key: "325110 Petrochemical Manufacturing", value: "325110" },
  { key: "325120 Industrial Gas Manufacturing", value: "325120" },
  { key: "325130 Synthetic Dye and Pigment Manufacturing", value: "325130" },
  { key: "325180 Other Basic Inorganic Chemical Manufacturing ", value: "325180" },
  { key: "325193 Ethyl Alcohol Manufacturing ", value: "325193" },
  { key: "325194 Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing ", value: "325194" },
  { key: "325199 All Other Basic Organic Chemical Manufacturing ", value: "325199" },
  { key: "325211 Plastics Material and Resin Manufacturing ", value: "325211" },
  { key: "325212 Synthetic Rubber Manufacturing ", value: "325212" },
  { key: "325220 Artificial and Synthetic Fibers and Filaments Manufacturing", value: "325220" },
  { key: "325311 Nitrogenous Fertilizer Manufacturing ", value: "325311" },
  { key: "325312 Phosphatic Fertilizer Manufacturing ", value: "325312" },
  { key: "325314 Fertilizer (Mixing Only) Manufacturing ", value: "325314" },
  { key: "325315 Compost Manufacturing", value: "325315" },
  { key: "325320 Pesticide and Other Agricultural Chemical Manufacturing", value: "325320" },
  { key: "325411 Medicinal and Botanical Manufacturing ", value: "325411" },
  { key: "325412 Pharmaceutical Preparation Manufacturing ", value: "325412" },
  { key: "325413 In-Vitro Diagnostic Substance Manufacturing ", value: "325413" },
  { key: "325414 Biological Product (except Diagnostic) Manufacturing ", value: "325414" },
  { key: "325510 Paint and Coating Manufacturing", value: "325510" },
  { key: "325520 Adhesive Manufacturing", value: "325520" },
  { key: "325611 Soap and Other Detergent Manufacturing ", value: "325611" },
  { key: "325612 Polish and Other Sanitation Good Manufacturing ", value: "325612" },
  { key: "325613 Surface Active Agent Manufacturing ", value: "325613" },
  { key: "325620 Toilet Preparation Manufacturing", value: "325620" },
  { key: "325910 Printing Ink Manufacturing", value: "325910" },
  { key: "325920 Explosives Manufacturing", value: "325920" },
  { key: "325991 Custom Compounding of Purchased Resins ", value: "325991" },
  { key: "325992 Photographic Film, Paper, Plate, Chemical, and Copy Toner Manufacturing ", value: "325992" },
  { key: "325998 All Other Miscellaneous Chemical Product and Preparation Manufacturing ", value: "325998" },
  { key: "326111 Plastics Bag and Pouch Manufacturing ", value: "326111" },
  { key: "326112 Plastics Packaging Film and Sheet (including Laminated) Manufacturing ", value: "326112" },
  { key: "326113 Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing ", value: "326113" },
  { key: "326121 Unlaminated Plastics Profile Shape Manufacturing ", value: "326121" },
  { key: "326122 Plastics Pipe and Pipe Fitting Manufacturing ", value: "326122" },
  { key: "326130 Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing", value: "326130" },
  { key: "326140 Polystyrene Foam Product Manufacturing", value: "326140" },
  { key: "326150 Urethane and Other Foam Product (except Polystyrene) Manufacturing", value: "326150" },
  { key: "326160 Plastics Bottle Manufacturing", value: "326160" },
  { key: "326191 Plastics Plumbing Fixture Manufacturing ", value: "326191" },
  { key: "326199 All Other Plastics Product Manufacturing ", value: "326199" },
  { key: "326211 Tire Manufacturing (except Retreading) ", value: "326211" },
  { key: "326212 Tire Retreading ", value: "326212" },
  { key: "326220 Rubber and Plastics Hoses and Belting Manufacturing", value: "326220" },
  { key: "326291 Rubber Product Manufacturing for Mechanical Use ", value: "326291" },
  { key: "326299 All Other Rubber Product Manufacturing ", value: "326299" },
  { key: "327110 Pottery, Ceramics, and Plumbing Fixture Manufacturing ", value: "327110" },
  { key: "327120 Clay Building Material and Refractories Manufacturing ", value: "327120" },
  { key: "327211 Flat Glass Manufacturing ", value: "327211" },
  { key: "327212 Other Pressed and Blown Glass and Glassware Manufacturing ", value: "327212" },
  { key: "327213 Glass Container Manufacturing ", value: "327213" },
  { key: "327215 Glass Product Manufacturing Made of Purchased Glass ", value: "327215" },
  { key: "327310 Cement Manufacturing", value: "327310" },
  { key: "327320 Ready-Mix Concrete Manufacturing", value: "327320" },
  { key: "327331 Concrete Block and Brick Manufacturing ", value: "327331" },
  { key: "327332 Concrete Pipe Manufacturing ", value: "327332" },
  { key: "327390 Other Concrete Product Manufacturing ", value: "327390" },
  { key: "327410 Lime Manufacturing", value: "327410" },
  { key: "327420 Gypsum Product Manufacturing", value: "327420" },
  { key: "327910 Abrasive Product Manufacturing", value: "327910" },
  { key: "327991 Cut Stone and Stone Product Manufacturing ", value: "327991" },
  { key: "327992 Ground or Treated Mineral and Earth Manufacturing ", value: "327992" },
  { key: "327993 Mineral Wool Manufacturing ", value: "327993" },
  { key: "327999 All Other Miscellaneous Nonmetallic Mineral Product Manufacturing ", value: "327999" },
  { key: "331110 Iron and Steel Mills and Ferroalloy Manufacturing ", value: "331110" },
  { key: "331210 Iron and Steel Pipe and Tube Manufacturing from Purchased Steel", value: "331210" },
  { key: "331221 Rolled Steel Shape Manufacturing ", value: "331221" },
  { key: "331222 Steel Wire Drawing ", value: "331222" },
  { key: "331313 Alumina Refining and Primary Aluminum Production ", value: "331313" },
  { key: "331314 Secondary Smelting and Alloying of Aluminum ", value: "331314" },
  { key: "331315 Aluminum Sheet, Plate, and Foil Manufacturing ", value: "331315" },
  { key: "331318 Other Aluminum Rolling, Drawing, and Extruding ", value: "331318" },
  { key: "331410 Nonferrous Metal (except Aluminum) Smelting and Refining ", value: "331410" },
  { key: "331420 Copper Rolling, Drawing, Extruding, and Alloying", value: "331420" },
  { key: "331491 Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding ", value: "331491" },
  { key: "331492 Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum) ", value: "331492" },
  { key: "331511 Iron Foundries ", value: "331511" },
  { key: "331512 Steel Investment Foundries ", value: "331512" },
  { key: "331513 Steel Foundries (except Investment) ", value: "331513" },
  { key: "331523 Nonferrous Metal Die-Casting Foundries ", value: "331523" },
  { key: "331524 Aluminum Foundries (except Die-Casting) ", value: "331524" },
  { key: "331529 Other Nonferrous Metal Foundries (except Die-Casting) ", value: "331529" },
  { key: "332111 Iron and Steel Forging ", value: "332111" },
  { key: "332112 Nonferrous Forging ", value: "332112" },
  { key: "332114 Custom Roll Forming ", value: "332114" },
  { key: "332117 Powder Metallurgy Part Manufacturing ", value: "332117" },
  { key: "332119 Metal Crown, Closure, and Other Metal Stamping (except Automotive) ", value: "332119" },
  { key: "332215 Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing ", value: "332215" },
  { key: "332216 Saw Blade and Handtool Manufacturing ", value: "332216" },
  { key: "332311 Prefabricated Metal Building and Component Manufacturing ", value: "332311" },
  { key: "332312 Fabricated Structural Metal Manufacturing ", value: "332312" },
  { key: "332313 Plate Work Manufacturing ", value: "332313" },
  { key: "332321 Metal Window and Door Manufacturing ", value: "332321" },
  { key: "332322 Sheet Metal Work Manufacturing ", value: "332322" },
  { key: "332323 Ornamental and Architectural Metal Work Manufacturing ", value: "332323" },
  { key: "332410 Power Boiler and Heat Exchanger Manufacturing", value: "332410" },
  { key: "332420 Metal Tank (Heavy Gauge) Manufacturing", value: "332420" },
  { key: "332431 Metal Can Manufacturing ", value: "332431" },
  { key: "332439 Other Metal Container Manufacturing ", value: "332439" },
  { key: "332510 Hardware Manufacturing", value: "332510" },
  { key: "332613 Spring Manufacturing ", value: "332613" },
  { key: "332618 Other Fabricated Wire Product Manufacturing ", value: "332618" },
  { key: "332710 Machine Shops", value: "332710" },
  { key: "332721 Precision Turned Product Manufacturing ", value: "332721" },
  { key: "332722 Bolt, Nut, Screw, Rivet, and Washer Manufacturing ", value: "332722" },
  { key: "332811 Metal Heat Treating ", value: "332811" },
  { key: "332812 Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers ", value: "332812" },
  { key: "332813 Electroplating, Plating, Polishing, Anodizing, and Coloring ", value: "332813" },
  { key: "332911 Industrial Valve Manufacturing ", value: "332911" },
  { key: "332912 Fluid Power Valve and Hose Fitting Manufacturing ", value: "332912" },
  { key: "332913 Plumbing Fixture Fitting and Trim Manufacturing ", value: "332913" },
  { key: "332919 Other Metal Valve and Pipe Fitting Manufacturing ", value: "332919" },
  { key: "332991 Ball and Roller Bearing Manufacturing", value: "332991" },
  { key: "332992 Small Arms Ammunition Manufacturing ", value: "332992" },
  { key: "332993 Ammunition (except Small Arms) Manufacturing ", value: "332993" },
  { key: "332994 Small Arms, Ordnance, and Ordnance Accessories Manufacturing ", value: "332994" },
  { key: "332996 Fabricated Pipe and Pipe Fitting Manufacturing ", value: "332996" },
  { key: "332999 All Other Miscellaneous Fabricated Metal Product Manufacturing ", value: "332999" },
  { key: "333111 Farm Machinery and Equipment Manufacturing ", value: "333111" },
  { key: "333112 Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing ", value: "333112" },
  { key: "333120 Construction Machinery Manufacturing", value: "333120" },
  { key: "333131 Mining Machinery and Equipment Manufacturing ", value: "333131" },
  { key: "333132 Oil and Gas Field Machinery and Equipment Manufacturing ", value: "333132" },
  { key: "333241 Food Product Machinery Manufacturing ", value: "333241" },
  { key: "333242 Semiconductor Machinery Manufacturing ", value: "333242" },
  { key: "333243 Sawmill, Woodworking, and Paper Machinery Manufacturing ", value: "333243" },
  { key: "333248 All Other Industrial Machinery Manufacturing ", value: "333248" },
  { key: "333310 Commercial and Service Industry Machinery Manufacturing ", value: "333310" },
  { key: "333413 Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing ", value: "333413" },
  { key: "333414 Heating Equipment (except Warm Air Furnaces) Manufacturing ", value: "333414" },
  { key: "333415 Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing ", value: "333415" },
  { key: "333511 Industrial Mold Manufacturing ", value: "333511" },
  { key: "333514 Special Die and Tool, Die Set, Jig, and Fixture Manufacturing ", value: "333514" },
  { key: "333515 Cutting Tool and Machine Tool Accessory Manufacturing ", value: "333515" },
  { key: "333517 Machine Tool Manufacturing ", value: "333517" },
  { key: "333519 Rolling Mill and Other Metalworking Machinery Manufacturing ", value: "333519" },
  { key: "333611 Turbine and Turbine Generator Set Units Manufacturing ", value: "333611" },
  { key: "333612 Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing ", value: "333612" },
  { key: "333613 Mechanical Power Transmission Equipment Manufacturing ", value: "333613" },
  { key: "333618 Other Engine Equipment Manufacturing ", value: "333618" },
  { key: "333912 Air and Gas Compressor Manufacturing ", value: "333912" },
  { key: "333914 Measuring, Dispensing, and Other Pumping Equipment Manufacturing ", value: "333914" },
  { key: "333921 Elevator and Moving Stairway Manufacturing ", value: "333921" },
  { key: "333922 Conveyor and Conveying Equipment Manufacturing ", value: "333922" },
  { key: "333923 Overhead Traveling Crane, Hoist, and Monorail System Manufacturing ", value: "333923" },
  { key: "333924 Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing ", value: "333924" },
  { key: "333991 Power-Driven Handtool Manufacturing ", value: "333991" },
  { key: "333992 Welding and Soldering Equipment Manufacturing ", value: "333992" },
  { key: "333993 Packaging Machinery Manufacturing ", value: "333993" },
  { key: "333994 Industrial Process Furnace and Oven Manufacturing ", value: "333994" },
  { key: "333995 Fluid Power Cylinder and Actuator Manufacturing ", value: "333995" },
  { key: "333996 Fluid Power Pump and Motor Manufacturing ", value: "333996" },
  { key: "333998 All Other Miscellaneous General Purpose Machinery Manufacturing ", value: "333998" },
  { key: "334111 Electronic Computer Manufacturing ", value: "334111" },
  { key: "334112 Computer Storage Device Manufacturing ", value: "334112" },
  { key: "334118 Computer Terminal and Other Computer Peripheral Equipment Manufacturing ", value: "334118" },
  { key: "334210 Telephone Apparatus Manufacturing", value: "334210" },
  { key: "334220 Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing", value: "334220" },
  { key: "334290 Other Communications Equipment Manufacturing", value: "334290" },
  { key: "334310 Audio and Video Equipment Manufacturing", value: "334310" },
  { key: "334412 Bare Printed Circuit Board Manufacturing  ", value: "334412" },
  { key: "334413 Semiconductor and Related Device Manufacturing ", value: "334413" },
  { key: "334416 Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing ", value: "334416" },
  { key: "334417 Electronic Connector Manufacturing ", value: "334417" },
  { key: "334418 Printed Circuit Assembly (Electronic Assembly) Manufacturing ", value: "334418" },
  { key: "334419 Other Electronic Component Manufacturing ", value: "334419" },
  { key: "334510 Electromedical and Electrotherapeutic Apparatus Manufacturing ", value: "334510" },
  { key: "334511 Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing ", value: "334511" },
  { key: "334512 Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use ", value: "334512" },
  { key: "334513 Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables ", value: "334513" },
  { key: "334514 Totalizing Fluid Meter and Counting Device Manufacturing ", value: "334514" },
  { key: "334515 Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals ", value: "334515" },
  { key: "334516 Analytical Laboratory Instrument Manufacturing ", value: "334516" },
  { key: "334517 Irradiation Apparatus Manufacturing ", value: "334517" },
  { key: "334519 Other Measuring and Controlling Device Manufacturing ", value: "334519" },
  { key: "334610 Manufacturing and Reproducing Magnetic and Optical Media ", value: "334610" },
  { key: "335131 Residential Electric Lighting Fixture Manufacturing ", value: "335131" },
  { key: "335132 Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing ", value: "335132" },
  { key: "335139 Electric Lamp Bulb and Other Lighting Equipment Manufacturing ", value: "335139" },
  { key: "335210 Small Electrical Appliance Manufacturing", value: "335210" },
  { key: "335220 Major Household Appliance Manufacturing ", value: "335220" },
  { key: "335311 Power, Distribution, and Specialty Transformer Manufacturing ", value: "335311" },
  { key: "335312 Motor and Generator Manufacturing ", value: "335312" },
  { key: "335313 Switchgear and Switchboard Apparatus Manufacturing ", value: "335313" },
  { key: "335314 Relay and Industrial Control Manufacturing ", value: "335314" },
  { key: "335910 Battery Manufacturing ", value: "335910" },
  { key: "335921 Fiber Optic Cable Manufacturing ", value: "335921" },
  { key: "335929 Other Communication and Energy Wire Manufacturing ", value: "335929" },
  { key: "335931 Current-Carrying Wiring Device Manufacturing ", value: "335931" },
  { key: "335932 Noncurrent-Carrying Wiring Device Manufacturing ", value: "335932" },
  { key: "335991 Carbon and Graphite Product Manufacturing ", value: "335991" },
  { key: "335999 All Other Miscellaneous Electrical Equipment and Component Manufacturing ", value: "335999" },
  { key: "336110 Automobile and Light Duty Motor Vehicle Manufacturing ", value: "336110" },
  { key: "336120 Heavy Duty Truck Manufacturing", value: "336120" },
  { key: "336211 Motor Vehicle Body Manufacturing ", value: "336211" },
  { key: "336212 Truck Trailer Manufacturing ", value: "336212" },
  { key: "336213 Motor Home Manufacturing ", value: "336213" },
  { key: "336214 Travel Trailer and Camper Manufacturing ", value: "336214" },
  { key: "336310 Motor Vehicle Gasoline Engine and Engine Parts Manufacturing", value: "336310" },
  { key: "336320 Motor Vehicle Electrical and Electronic Equipment Manufacturing", value: "336320" },
  { key: "336330 Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing", value: "336330" },
  { key: "336340 Motor Vehicle Brake System Manufacturing", value: "336340" },
  { key: "336350 Motor Vehicle Transmission and Power Train Parts Manufacturing", value: "336350" },
  { key: "336360 Motor Vehicle Seating and Interior Trim Manufacturing", value: "336360" },
  { key: "336370 Motor Vehicle Metal Stamping", value: "336370" },
  { key: "336390 Other Motor Vehicle Parts Manufacturing", value: "336390" },
  { key: "336411 Aircraft Manufacturing ", value: "336411" },
  { key: "336412 Aircraft Engine and Engine Parts Manufacturing ", value: "336412" },
  { key: "336413 Other Aircraft Parts and Auxiliary Equipment Manufacturing ", value: "336413" },
  { key: "336414 Guided Missile and Space Vehicle Manufacturing ", value: "336414" },
  { key: "336415 Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing ", value: "336415" },
  { key: "336419 Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing ", value: "336419" },
  { key: "336510 Railroad Rolling Stock Manufacturing", value: "336510" },
  { key: "336611 Ship Building and Repairing ", value: "336611" },
  { key: "336612 Boat Building ", value: "336612" },
  { key: "336991 Motorcycle, Bicycle, and Parts Manufacturing ", value: "336991" },
  { key: "336992 Military Armored Vehicle, Tank, and Tank Component Manufacturing ", value: "336992" },
  { key: "336999 All Other Transportation Equipment Manufacturing ", value: "336999" },
  { key: "337110 Wood Kitchen Cabinet and Countertop Manufacturing", value: "337110" },
  { key: "337121 Upholstered Household Furniture Manufacturing ", value: "337121" },
  { key: "337122 Nonupholstered Wood Household Furniture Manufacturing ", value: "337122" },
  { key: "337126 Household Furniture (except Wood and Upholstered) Manufacturing ", value: "337126" },
  { key: "337127 Institutional Furniture Manufacturing ", value: "337127" },
  { key: "337211 Wood Office Furniture Manufacturing ", value: "337211" },
  { key: "337212 Custom Architectural Woodwork and Millwork Manufacturing ", value: "337212" },
  { key: "337214 Office Furniture (except Wood) Manufacturing ", value: "337214" },
  { key: "337215 Showcase, Partition, Shelving, and Locker Manufacturing ", value: "337215" },
  { key: "337910 Mattress Manufacturing", value: "337910" },
  { key: "337920 Blind and Shade Manufacturing", value: "337920" },
  { key: "339112 Surgical and Medical Instrument Manufacturing ", value: "339112" },
  { key: "339113 Surgical Appliance and Supplies Manufacturing ", value: "339113" },
  { key: "339114 Dental Equipment and Supplies Manufacturing ", value: "339114" },
  { key: "339115 Ophthalmic Goods Manufacturing ", value: "339115" },
  { key: "339116 Dental Laboratories ", value: "339116" },
  { key: "339910 Jewelry and Silverware Manufacturing ", value: "339910" },
  { key: "339920 Sporting and Athletic Goods Manufacturing", value: "339920" },
  { key: "339930 Doll, Toy, and Game Manufacturing", value: "339930" },
  { key: "339940 Office Supplies (except Paper) Manufacturing", value: "339940" },
  { key: "339950 Sign Manufacturing", value: "339950" },
  { key: "339991 Gasket, Packing, and Sealing Device Manufacturing ", value: "339991" },
  { key: "339992 Musical Instrument Manufacturing ", value: "339992" },
  { key: "339993 Fastener, Button, Needle, and Pin Manufacturing ", value: "339993" },
  { key: "339994 Broom, Brush, and Mop Manufacturing ", value: "339994" },
  { key: "339995 Burial Casket Manufacturing ", value: "339995" },
  { key: "339999 All Other Miscellaneous Manufacturing ", value: "339999" },
  { key: "423110 Automobile and Other Motor Vehicle Merchant Wholesalers ", value: "423110" },
  { key: "423120 Motor Vehicle Supplies and New Parts Merchant Wholesalers ", value: "423120" },
  { key: "423130 Tire and Tube Merchant Wholesalers ", value: "423130" },
  { key: "423140 Motor Vehicle Parts (Used) Merchant Wholesalers ", value: "423140" },
  { key: "423210 Furniture Merchant Wholesalers ", value: "423210" },
  { key: "423220 Home Furnishing Merchant Wholesalers ", value: "423220" },
  { key: "423310 Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers ", value: "423310" },
  { key: "423320 Brick, Stone, and Related Construction Material Merchant Wholesalers ", value: "423320" },
  { key: "423330 Roofing, Siding, and Insulation Material Merchant Wholesalers ", value: "423330" },
  { key: "423390 Other Construction Material Merchant Wholesalers ", value: "423390" },
  { key: "423410 Photographic Equipment and Supplies Merchant Wholesalers ", value: "423410" },
  { key: "423420 Office Equipment Merchant Wholesalers ", value: "423420" },
  { key: "423430 Computer and Computer Peripheral Equipment and Software Merchant Wholesalers ", value: "423430" },
  { key: "423440 Other Commercial Equipment Merchant Wholesalers ", value: "423440" },
  { key: "423450 Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers ", value: "423450" },
  { key: "423460 Ophthalmic Goods Merchant Wholesalers ", value: "423460" },
  { key: "423490 Other Professional Equipment and Supplies Merchant Wholesalers ", value: "423490" },
  { key: "423510 Metal Service Centers and Other Metal Merchant Wholesalers ", value: "423510" },
  { key: "423520 Coal and Other Mineral and Ore Merchant Wholesalers ", value: "423520" },
  { key: "423610 Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers ", value: "423610" },
  { key: "423620 Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers ", value: "423620" },
  { key: "423690 Other Electronic Parts and Equipment Merchant Wholesalers ", value: "423690" },
  { key: "423710 Hardware Merchant Wholesalers ", value: "423710" },
  { key: "423720 Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers ", value: "423720" },
  { key: "423730 Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers ", value: "423730" },
  { key: "423740 Refrigeration Equipment and Supplies Merchant Wholesalers ", value: "423740" },
  { key: "423810 Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers ", value: "423810" },
  { key: "423820 Farm and Garden Machinery and Equipment Merchant Wholesalers ", value: "423820" },
  { key: "423830 Industrial Machinery and Equipment Merchant Wholesalers ", value: "423830" },
  { key: "423840 Industrial Supplies Merchant Wholesalers", value: "423840" },
  { key: "423850 Service Establishment Equipment and Supplies Merchant Wholesalers ", value: "423850" },
  { key: "423860 Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers ", value: "423860" },
  { key: "423910 Sporting and Recreational Goods and Supplies Merchant Wholesalers ", value: "423910" },
  { key: "423920 Toy and Hobby Goods and Supplies Merchant Wholesalers ", value: "423920" },
  { key: "423930 Recyclable Material Merchant Wholesalers ", value: "423930" },
  { key: "423940 Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers ", value: "423940" },
  { key: "423990 Other Miscellaneous Durable Goods Merchant Wholesalers ", value: "423990" },
  { key: "424110 Printing and Writing Paper Merchant Wholesalers ", value: "424110" },
  { key: "424120 Stationery and Office Supplies Merchant Wholesalers ", value: "424120" },
  { key: "424130 Industrial and Personal Service Paper Merchant Wholesalers ", value: "424130" }, 
  { key: "424210 Drugs and Druggists' Sundries Merchant Wholesalers ", value: "424210" },
  { key: "424310 Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers ", value: "424310" },
  { key: "424340 Footwear Merchant Wholesalers ", value: "424340" },
  { key: "424350 Clothing and Clothing Accessories Merchant Wholesalers", value: "424350" },
  { key: "424410 General Line Grocery Merchant Wholesalers ", value: "424410" },
  { key: "424420 Packaged Frozen Food Merchant Wholesalers ", value: "424420" },
  { key: "424430 Dairy Product (except Dried or Canned) Merchant Wholesalers ", value: "424430" },
  { key: "424440 Poultry and Poultry Product Merchant Wholesalers ", value: "424440" },
  { key: "424450 Confectionery Merchant Wholesalers ", value: "424450" },
  { key: "424460 Fish and Seafood Merchant Wholesalers ", value: "424460" },
  { key: "424470 Meat and Meat Product Merchant Wholesalers ", value: "424470" },
  { key: "424480 Fresh Fruit and Vegetable Merchant Wholesalers ", value: "424480" },
  { key: "424490 Other Grocery and Related Products Merchant Wholesalers ", value: "424490" },
  { key: "424510 Grain and Field Bean Merchant Wholesalers ", value: "424510" },
  { key: "424520 Livestock Merchant Wholesalers ", value: "424520" },
  { key: "424590 Other Farm Product Raw Material Merchant Wholesalers ", value: "424590" },
  { key: "424610 Plastics Materials and Basic Forms and Shapes Merchant Wholesalers ", value: "424610" },
  { key: "424690 Other Chemical and Allied Products Merchant Wholesalers ", value: "424690" },
  { key: "424710 Petroleum Bulk Stations and Terminals ", value: "424710" },
  { key: "424720 Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals) ", value: "424720" },
  { key: "424810 Beer and Ale Merchant Wholesalers ", value: "424810" },
  { key: "424820 Wine and Distilled Alcoholic Beverage Merchant Wholesalers ", value: "424820" },
  { key: "424910 Farm Supplies Merchant Wholesalers ", value: "424910" },
  { key: "424920 Book, Periodical, and Newspaper Merchant Wholesalers ", value: "424920" },
  { key: "424930 Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers ", value: "424930" },
  { key: "424940 Tobacco Product and Electronic Cigarette Merchant Wholesalers ", value: "424940" },
  { key: "424950 Paint, Varnish, and Supplies Merchant Wholesalers ", value: "424950" },
  { key: "424990 Other Miscellaneous Nondurable Goods Merchant Wholesalers ", value: "424990" },
  { key: "425120 Wholesale Trade Agents and Brokers ", value: "425120" },
  { key: "441110 New Car Dealers ", value: "441110" },
  { key: "441120 Used Car Dealers ", value: "441120" },
  { key: "441210 Recreational Vehicle Dealers ", value: "441210" },
  { key: "441222 Boat Dealers ", value: "441222" },
  { key: "441227 Motorcycle, ATV, and All Other Motor Vehicle Dealers ", value: "441227" },
  { key: "441330 Automotive Parts and Accessories Retailers ", value: "441330" },
  { key: "441340 Tire Dealers ", value: "441340" },
  { key: "444110 Home Centers ", value: "444110" },
  { key: "444120 Paint and Wallpaper Retailers ", value: "444120" },
  { key: "444140 Hardware Retailers ", value: "444140" },
  { key: "444180 Other Building Material Dealers ", value: "444180" },
  { key: "444230 Outdoor Power Equipment Retailers ", value: "444230" },
  { key: "444240 Nursery, Garden Center, and Farm Supply Retailers ", value: "444240" },
  { key: "445110 Supermarkets and Other Grocery Retailers (except Convenience Retailers) ", value: "445110" },
  { key: "445131 Convenience Retailers ", value: "445131" },
  { key: "445132 Vending Machine Operators ", value: "445132" },
  { key: "445230 Fruit and Vegetable Retailers ", value: "445230" },
  { key: "445240 Meat Retailers ", value: "445240" },
  { key: "445250 Fish and Seafood Retailers ", value: "445250" },
  { key: "445291 Baked Goods Retailers ", value: "445291" },
  { key: "445292 Confectionery and Nut Retailers ", value: "445292" },
  { key: "445298 All Other Specialty Food Retailers ", value: "445298" },
  { key: "445320 Beer, Wine, and Liquor Retailers ", value: "445320" },
  { key: "449110 Furniture Retailers ", value: "449110" },
  { key: "449121 Floor Covering Retailers ", value: "449121" },
  { key: "449122 Window Treatment Retailers ", value: "449122" },
  { key: "449129 All Other Home Furnishings Retailers ", value: "449129" },
  { key: "449210 Electronics and Appliance Retailers ", value: "449210" },
  { key: "455110 Department Stores ", value: "455110" },
  { key: "455211 Warehouse Clubs and Supercenters ", value: "455211" },
  { key: "455219 All Other General Merchandise Retailers ", value: "455219" },
  { key: "456110 Pharmacies and Drug Retailers ", value: "456110" },
  { key: "456120 Cosmetics, Beauty Supplies, and Perfume Retailers ", value: "456120" },
  { key: "456130 Optical Goods Retailers ", value: "456130" },
  { key: "456191 Food (Health) Supplement Retailers ", value: "456191" },
  { key: "456199 All Other Health and Personal Care Retailers ", value: "456199" },
  { key: "457110 Gasoline Stations with Convenience Stores ", value: "457110" },
  { key: "457120 Other Gasoline Stations ", value: "457120" },
  { key: "457210 Fuel Dealers ", value: "457210" },
  { key: "458110 Clothing and Clothing Accessories Retailers ", value: "458110" },
  { key: "458210 Shoe Retailers ", value: "458210" },
  { key: "458310 Jewelry Retailers ", value: "458310" },
  { key: "458320 Luggage and Leather Goods Retailers ", value: "458320" },
  { key: "459110 Sporting Goods Retailers ", value: "459110" },
  { key: "459120 Hobby, Toy, and Game Retailers ", value: "459120" },
  { key: "459130 Sewing, Needlework, and Piece Goods Retailers ", value: "459130" },
  { key: "459140 Musical Instrument and Supplies Retailers ", value: "459140" },
  { key: "459210 Book Retailers and News Dealers ", value: "459210" },
  { key: "459310 Florists ", value: "459310" },
  { key: "459410 Office Supplies and Stationery Retailers ", value: "459410" },
  { key: "459420 Gift, Novelty, and Souvenir Retailers ", value: "459420" },
  { key: "459510 Used Merchandise Retailers ", value: "459510" },
  { key: "459910 Pet and Pet Supplies Retailers ", value: "459910" },
  { key: "459920 Art Dealers ", value: "459920" },
  { key: "459930 Manufactured (Mobile) Home Dealers ", value: "459930" },
  { key: "459991 Tobacco, Electronic Cigarette, and Other Smoking Supplies Retailers ", value: "459991" },
  { key: "459999 All Other Miscellaneous Retailers ", value: "459999" },
  { key: "481111 Scheduled Passenger Air Transportation ", value: "481111" },
  { key: "481112 Scheduled Freight Air Transportation ", value: "481112" },
  { key: "481211 Nonscheduled Chartered Passenger Air Transportation ", value: "481211" },
  { key: "481212 Nonscheduled Chartered Freight Air Transportation ", value: "481212" },
  { key: "481219 Other Nonscheduled Air Transportation ", value: "481219" },
  { key: "482111 Line-Haul Railroads ", value: "482111" },
  { key: "482112 Short Line Railroads ", value: "482112" },
  { key: "483111 Deep Sea Freight Transportation ", value: "483111" },
  { key: "483112 Deep Sea Passenger Transportation ", value: "483112" },
  { key: "483113 Coastal and Great Lakes Freight Transportation ", value: "483113" },
  { key: "483114 Coastal and Great Lakes Passenger Transportation ", value: "483114" },
  { key: "483211 Inland Water Freight Transportation ", value: "483211" },
  { key: "483212 Inland Water Passenger Transportation ", value: "483212" },
  { key: "484110 General Freight Trucking, Local ", value: "484110" },
  { key: "484121 General Freight Trucking, Long-Distance, Truckload ", value: "484121" },
  { key: "484122 General Freight Trucking, Long-Distance, Less Than Truckload ", value: "484122" },
  { key: "484210 Used Household and Office Goods Moving", value: "484210" },
  { key: "484220 Specialized Freight (except Used Goods) Trucking, Local ", value: "484220" },
  { key: "484230 Specialized Freight (except Used Goods) Trucking, Long-Distance ", value: "484230" },
  { key: "485111 Mixed Mode Transit Systems ", value: "485111" },
  { key: "485112 Commuter Rail Systems ", value: "485112" },
  { key: "485113 Bus and Other Motor Vehicle Transit Systems ", value: "485113" },
  { key: "485119 Other Urban Transit Systems ", value: "485119" },
  { key: "485210 Interurban and Rural Bus Transportation", value: "485210" },
  { key: "485310 Taxi and Ridesharing Services ", value: "485310" },
  { key: "485320 Limousine Service", value: "485320" },
  { key: "485410 School and Employee Bus Transportation", value: "485410" },
  { key: "485510 Charter Bus Industry", value: "485510" },
  { key: "485991 Special Needs Transportation ", value: "485991" },
  { key: "485999 All Other Transit and Ground Passenger Transportation ", value: "485999" },
  { key: "486110 Pipeline Transportation of Crude Oil", value: "486110" },
  { key: "486210 Pipeline Transportation of Natural Gas", value: "486210" },
  { key: "486910 Pipeline Transportation of Refined Petroleum Products", value: "486910" },
  { key: "486990 All Other Pipeline Transportation", value: "486990" },
  { key: "487110 Scenic and Sightseeing Transportation, Land", value: "487110" },
  { key: "487210 Scenic and Sightseeing Transportation, Water", value: "487210" },
  { key: "487990 Scenic and Sightseeing Transportation, Other", value: "487990" },
  { key: "488111 Air Traffic Control", value: "488111" },
  { key: "488119 Other Airport Operations ", value: "488119" },
  { key: "488190 Other Support Activities for Air Transportation", value: "488190" },
  { key: "488210 Support Activities for Rail Transportation", value: "488210" },
  { key: "488310 Port and Harbor Operations", value: "488310" },
  { key: "488320 Marine Cargo Handling", value: "488320" },
  { key: "488330 Navigational Services to Shipping ", value: "488330" },
  { key: "488390 Other Support Activities for Water Transportation", value: "488390" },
  { key: "488410 Motor Vehicle Towing", value: "488410" },
  { key: "488490 Other Support Activities for Road Transportation ", value: "488490" },
  { key: "488510 Freight Transportation Arrangement ", value: "488510" },
  { key: "488991 Packing and Crating ", value: "488991" },
  { key: "488999 All Other Support Activities for Transportation ", value: "488999" },
  { key: "491110 Postal Service", value: "491110" },
  { key: "492110 Couriers and Express Delivery Services", value: "492110" },
  { key: "492210 Local Messengers and Local Delivery", value: "492210" },
  { key: "493110 General Warehousing and Storage ", value: "493110" },
  { key: "493120 Refrigerated Warehousing and Storage", value: "493120" },
  { key: "493130 Farm Product Warehousing and Storage", value: "493130" },
  { key: "493190 Other Warehousing and Storage", value: "493190" },
  { key: "512110 Motion Picture and Video Production ", value: "512110" },
  { key: "512120 Motion Picture and Video Distribution", value: "512120" },
  { key: "512131 Motion Picture Theaters (except Drive-Ins) ", value: "512131" },
  { key: "512132 Drive-In Motion Picture Theaters ", value: "512132" },
  { key: "512191 Teleproduction and Other Postproduction Services ", value: "512191" },
  { key: "512199 Other Motion Picture and Video Industries ", value: "512199" },
  { key: "512230 Music Publishers", value: "512230" },
  { key: "512240 Sound Recording Studios", value: "512240" },
  { key: "512250 Record Production and Distribution", value: "512250" },
  { key: "512290 Other Sound Recording Industries", value: "512290" },
  { key: "513110 Newspaper Publishers ", value: "513110" },
  { key: "513120 Periodical Publishers ", value: "513120" },
  { key: "513130 Book Publishers ", value: "513130" },
  { key: "513140 Directory and Mailing List Publishers ", value: "513140" },
  { key: "513191 Greeting Card Publishers ", value: "513191" },
  { key: "513199 All Other Publishers ", value: "513199" },
  { key: "513210 Software Publishers", value: "513210" },
  { key: "516110 Radio Broadcasting Stations ", value: "516110" },
  { key: "516120 Television Broadcasting Stations", value: "516120" },
  { key: "516210 Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers", value: "516210" },
  { key: "517111 Wired Telecommunications Carriers ", value: "517111" },
  { key: "517112 Wireless Telecommunications Carriers (except Satellite)", value: "517112" },
  { key: "517121 Telecommunications Resellers", value: "517121" },
  { key: "517122 Agents for Wireless Telecommunications Services", value: "517122" },
  { key: "517410 Satellite Telecommunications", value: "517410" },
  { key: "517810 All Other Telecommunications ", value: "517810" },
  { key: "518210 Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services", value: "518210" },
  { key: "519210 Libraries and Archives ", value: "519210" },
  { key: "519290 Web Search Portals and All Other Information Services", value: "519290" },
  { key: "521110 Monetary Authorities-Central Bank", value: "521110" },
  { key: "522110 Commercial Banking ", value: "522110" },
  { key: "522130 Credit Unions ", value: "522130" },
  { key: "522180 Savings Institutions and Other Depository Credit Intermediation ", value: "522180" },
  { key: "522210 Credit Card Issuing ", value: "522210" },
  { key: "522220 Sales Financing ", value: "522220" },
  { key: "522291 Consumer Lending ", value: "522291" },
  { key: "522292 Real Estate Credit ", value: "522292" },
  { key: "522299 International, Secondary Market, and All Other Nondepository Credit Intermediation ", value: "522299" },
  { key: "522310 Mortgage and Nonmortgage Loan Brokers ", value: "522310" },
  { key: "522320 Financial Transactions Processing, Reserve, and Clearinghouse Activities ", value: "522320" },
  { key: "522390 Other Activities Related to Credit Intermediation ", value: "522390" },
  { key: "523150 Investment Banking and Securities Intermediation ", value: "523150" },
  { key: "523160 Commodity Contracts Intermediation ", value: "523160" },
  { key: "523210 Securities and Commodity Exchanges", value: "523210" },
  { key: "523910 Miscellaneous Intermediation ", value: "523910" },
  { key: "523940 Portfolio Management and Investment Advice ", value: "523940" },
  { key: "523991 Trust, Fiduciary, and Custody Activities ", value: "523991" },
  { key: "523999 Miscellaneous Financial Investment Activities ", value: "523999" },
  { key: "524113 Direct Life Insurance Carriers ", value: "524113" },
  { key: "524114 Direct Health and Medical Insurance Carriers ", value: "524114" },
  { key: "524126 Direct Property and Casualty Insurance Carriers ", value: "524126" },
  { key: "524127 Direct Title Insurance Carriers ", value: "524127" },
  { key: "524128 Other Direct Insurance (except Life, Health, and Medical) Carriers ", value: "524128" },
  { key: "524130 Reinsurance Carriers ", value: "524130" },
  { key: "524210 Insurance Agencies and Brokerages ", value: "524210" },
  { key: "524291 Claims Adjusting ", value: "524291" },
  { key: "524292 Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds ", value: "524292" },
  { key: "524298 All Other Insurance Related Activities ", value: "524298" },
  { key: "525110 Pension Funds ", value: "525110" },
  { key: "525120 Health and Welfare Funds ", value: "525120" },
  { key: "525190 Other Insurance Funds ", value: "525190" },
  { key: "525910 Open-End Investment Funds ", value: "525910" },
  { key: "525920 Trusts, Estates, and Agency Accounts ", value: "525920" },
  { key: "525990 Other Financial Vehicles ", value: "525990" },
  { key: "531110 Lessors of Residential Buildings and Dwellings ", value: "531110" },
  { key: "531120 Lessors of Nonresidential Buildings (except Miniwarehouses) ", value: "531120" },
  { key: "531130 Lessors of Miniwarehouses and Self-Storage Units ", value: "531130" },
  { key: "531190 Lessors of Other Real Estate Property ", value: "531190" },
  { key: "531210 Offices of Real Estate Agents and Brokers", value: "531210" },
  { key: "531311 Residential Property Managers ", value: "531311" },
  { key: "531312 Nonresidential Property Managers ", value: "531312" },
  { key: "531320 Offices of Real Estate Appraisers ", value: "531320" },
  { key: "531390 Other Activities Related to Real Estate ", value: "531390" },
  { key: "532111 Passenger Car Rental ", value: "532111" },
  { key: "532112 Passenger Car Leasing ", value: "532112" },
  { key: "532120 Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing ", value: "532120" },
  { key: "532210 Consumer Electronics and Appliances Rental", value: "532210" },
  { key: "532281 Formal Wear and Costume Rental", value: "532281" },
  { key: "532282 Video Tape and Disc Rental", value: "532282" },
  { key: "532283 Home Health Equipment Rental ", value: "532283" },
  { key: "532284 Recreational Goods Rental ", value: "532284" },
  { key: "532289 All Other Consumer Goods Rental ", value: "532289" },
  { key: "532310 General Rental Centers", value: "532310" },
  { key: "532411 Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing ", value: "532411" },
  { key: "532412 Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing ", value: "532412" },
  { key: "532420 Office Machinery and Equipment Rental and Leasing", value: "532420" },
  { key: "532490 Other Commercial and Industrial Machinery and Equipment Rental and Leasing ", value: "532490" },
  { key: "533110 Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)", value: "533110" },
  { key: "541110 Offices of Lawyers", value: "541110" },
  { key: "541120 Offices of Notaries", value: "541120" },
  { key: "541191 Title Abstract and Settlement Offices ", value: "541191" },
  { key: "541199 All Other Legal Services ", value: "541199" },
  { key: "541211 Offices of Certified Public Accountants ", value: "541211" },
  { key: "541213 Tax Preparation Services ", value: "541213" },
  { key: "541214 Payroll Services ", value: "541214" },
  { key: "541219 Other Accounting Services ", value: "541219" },
  { key: "541310 Architectural Services", value: "541310" },
  { key: "541320 Landscape Architectural Services", value: "541320" },
  { key: "541330 Engineering Services", value: "541330" },
  { key: "541340 Drafting Services", value: "541340" },
  { key: "541350 Building Inspection Services", value: "541350" },
  { key: "541360 Geophysical Surveying and Mapping Services", value: "541360" },
  { key: "541370 Surveying and Mapping (except Geophysical) Services", value: "541370" },
  { key: "541380 Testing Laboratories and Services", value: "541380" },
  { key: "541410 Interior Design Services", value: "541410" },
  { key: "541420 Industrial Design Services", value: "541420" },
  { key: "541430 Graphic Design Services", value: "541430" },
  { key: "541490 Other Specialized Design Services", value: "541490" },
  { key: "541511 Custom Computer Programming Services ", value: "541511" },
  { key: "541512 Computer Systems Design Services ", value: "541512" },
  { key: "541513 Computer Facilities Management Services ", value: "541513" },
  { key: "541519 Other Computer Related Services", value: "541519" },
  { key: "541611 Administrative Management and General Management Consulting Services ", value: "541611" },
  { key: "541612 Human Resources Consulting Services ", value: "541612" },
  { key: "541613 Marketing Consulting Services ", value: "541613" },
  { key: "541614 Process, Physical Distribution, and Logistics Consulting Services ", value: "541614" },
  { key: "541618 Other Management Consulting Services ", value: "541618" },
  { key: "541620 Environmental Consulting Services", value: "541620" },
  { key: "541690 Other Scientific and Technical Consulting Services", value: "541690" },
  { key: "541713 Research and Development in Nanotechnology ", value: "541713" },
  { key: "541714 Research and Development in Biotechnology (except Nanobiotechnology)", value: "541714" },
  { key: "541715 Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology) ", value: "541715" },
  { key: "541720 Research and Development in the Social Sciences and Humanities ", value: "541720" },
  { key: "541810 Advertising Agencies", value: "541810" },
  { key: "541820 Public Relations Agencies", value: "541820" },
  { key: "541830 Media Buying Agencies", value: "541830" },
  { key: "541840 Media Representatives", value: "541840" },
  { key: "541850 Indoor and Outdoor Display Advertising", value: "541850" },
  { key: "541860 Direct Mail Advertising", value: "541860" },
  { key: "541870 Advertising Material Distribution Services", value: "541870" },
  { key: "541890 Other Services Related to Advertising ", value: "541890" },
  { key: "541910 Marketing Research and Public Opinion Polling", value: "541910" },
  { key: "541921 Photography Studios, Portrait ", value: "541921" },
  { key: "541922 Commercial Photography ", value: "541922" },
  { key: "541930 Translation and Interpretation Services", value: "541930" },
  { key: "541940 Veterinary Services ", value: "541940" },
  { key: "541990 All Other Professional, Scientific, and Technical Services", value: "541990" },
  { key: "551111 Offices of Bank Holding Companies ", value: "551111" },
  { key: "551112 Offices of Other Holding Companies ", value: "551112" },
  { key: "551114 Corporate, Subsidiary, and Regional Managing Offices ", value: "551114" },
  { key: "561110 Office Administrative Services", value: "561110" },
  { key: "561210 Facilities Support Services", value: "561210" },
  { key: "561311 Employment Placement Agencies ", value: "561311" },
  { key: "561312 Executive Search Services ", value: "561312" },
  { key: "561320 Temporary Help Services", value: "561320" },
  { key: "561330 Professional Employer Organizations", value: "561330" },
  { key: "561410 Document Preparation Services", value: "561410" },
  { key: "561421 Telephone Answering Services ", value: "561421" },
  { key: "561422 Telemarketing Bureaus and Other Contact Centers ", value: "561422" },
  { key: "561431 Private Mail Centers ", value: "561431" },
  { key: "561439 Other Business Service Centers (including Copy Shops) ", value: "561439" },
  { key: "561440 Collection Agencies", value: "561440" },
  { key: "561450 Credit Bureaus", value: "561450" },
  { key: "561491 Repossession Services ", value: "561491" },
  { key: "561492 Court Reporting and Stenotype Services ", value: "561492" },
  { key: "561499 All Other Business Support Services ", value: "561499" },
  { key: "561510 Travel Agencies", value: "561510" },
  { key: "561520 Tour Operators", value: "561520" },
  { key: "561591 Convention and Visitors Bureaus ", value: "561591" },
  { key: "561599 All Other Travel Arrangement and Reservation Services ", value: "561599" },
  { key: "561611 Investigation and Personal Background Check Services ", value: "561611" },
  { key: "561612 Security Guards and Patrol Services ", value: "561612" },
  { key: "561613 Armored Car Services ", value: "561613" },
  { key: "561621 Security Systems Services (except Locksmiths) ", value: "561621" },
  { key: "561622 Locksmiths ", value: "561622" },
  { key: "561710 Exterminating and Pest Control Services", value: "561710" },
  { key: "561720 Janitorial Services ", value: "561720" },
  { key: "561730 Landscaping Services", value: "561730" },
  { key: "561740 Carpet and Upholstery Cleaning Services", value: "561740" },
  { key: "561790 Other Services to Buildings and Dwellings ", value: "561790" },
  { key: "561910 Packaging and Labeling Services", value: "561910" },
  { key: "561920 Convention and Trade Show Organizers", value: "561920" },
  { key: "561990 All Other Support Services", value: "561990" },
  { key: "562111 Solid Waste Collection ", value: "562111" },
  { key: "562112 Hazardous Waste Collection ", value: "562112" },
  { key: "562119 Other Waste Collection ", value: "562119" },
  { key: "562211 Hazardous Waste Treatment and Disposal ", value: "562211" },
  { key: "562212 Solid Waste Landfill ", value: "562212" },
  { key: "562213 Solid Waste Combustors and Incinerators ", value: "562213" },
  { key: "562219 Other Nonhazardous Waste Treatment and Disposal ", value: "562219" },
  { key: "562910 Remediation Services ", value: "562910" },
  { key: "562920 Materials Recovery Facilities ", value: "562920" },
  { key: "562991 Septic Tank and Related Services ", value: "562991" },
  { key: "562998 All Other Miscellaneous Waste Management Services ", value: "562998" },
  { key: "611110 Elementary and Secondary Schools ", value: "611110" },
  { key: "611210 Junior Colleges ", value: "611210" },
  { key: "611310 Colleges, Universities, and Professional Schools ", value: "611310" },
  { key: "611410 Business and Secretarial Schools ", value: "611410" },
  { key: "611420 Computer Training ", value: "611420" },
  { key: "611430 Professional and Management Development Training ", value: "611430" },
  { key: "611511 Cosmetology and Barber Schools ", value: "611511" },
  { key: "611512 Flight Training ", value: "611512" },
  { key: "611513 Apprenticeship Training ", value: "611513" },
  { key: "611519 Other Technical and Trade Schools ", value: "611519" },
  { key: "611610 Fine Arts Schools ", value: "611610" },
  { key: "611620 Sports and Recreation Instruction ", value: "611620" },
  { key: "611630 Language Schools ", value: "611630" },
  { key: "611691 Exam Preparation and Tutoring ", value: "611691" },
  { key: "611692 Automobile Driving Schools ", value: "611692" },
  { key: "611699 All Other Miscellaneous Schools and Instruction ", value: "611699" },
  { key: "611710 Educational Support Services", value: "611710" },
  { key: "621111 Offices of Physicians (except Mental Health Specialists) ", value: "621111" },
  { key: "621112 Offices of Physicians, Mental Health Specialists ", value: "621112" },
  { key: "621210 Offices of Dentists ", value: "621210" },
  { key: "621310 Offices of Chiropractors ", value: "621310" },
  { key: "621320 Offices of Optometrists", value: "621320" },
  { key: "621330 Offices of Mental Health Practitioners (except Physicians) ", value: "621330" },
  { key: "621340 Offices of Physical, Occupational and Speech Therapists, and Audiologists ", value: "621340" },
  { key: "621391 Offices of Podiatrists ", value: "621391" },
  { key: "621399 Offices of All Other Miscellaneous Health Practitioners ", value: "621399" },
  { key: "621410 Family Planning Centers ", value: "621410" },
  { key: "621420 Outpatient Mental Health and Substance Abuse Centers ", value: "621420" },
  { key: "621491 HMO Medical Centers ", value: "621491" },
  { key: "621492 Kidney Dialysis Centers ", value: "621492" },
  { key: "621493 Freestanding Ambulatory Surgical and Emergency Centers ", value: "621493" },
  { key: "621498 All Other Outpatient Care Centers ", value: "621498" },
  { key: "621511 Medical Laboratories ", value: "621511" },
  { key: "621512 Diagnostic Imaging Centers ", value: "621512" },
  { key: "621610 Home Health Care Services", value: "621610" },
  { key: "621910 Ambulance Services ", value: "621910" },
  { key: "621991 Blood and Organ Banks ", value: "621991" },
  { key: "621999 All Other Miscellaneous Ambulatory Health Care Services ", value: "621999" },
  { key: "622110 General Medical and Surgical Hospitals ", value: "622110" },
  { key: "622210 Psychiatric and Substance Abuse Hospitals ", value: "622210" },
  { key: "622310 Specialty (except Psychiatric and Substance Abuse) Hospitals ", value: "622310" },
  { key: "623110 Nursing Care Facilities (Skilled Nursing Facilities) ", value: "623110" },
  { key: "623210 Residential Intellectual and Developmental Disability Facilities ", value: "623210" },
  { key: "623220 Residential Mental Health and Substance Abuse Facilities ", value: "623220" },
  { key: "623311 Continuing Care Retirement Communities ", value: "623311" },
  { key: "623312 Assisted Living Facilities for the Elderly ", value: "623312" },
  { key: "623990 Other Residential Care Facilities ", value: "623990" },
  { key: "624110 Child and Youth Services ", value: "624110" },
  { key: "624120 Services for the Elderly and Persons with Disabilities ", value: "624120" },
  { key: "624190 Other Individual and Family Services ", value: "624190" },
  { key: "624210 Community Food Services ", value: "624210" },
  { key: "624221 Temporary Shelters ", value: "624221" },
  { key: "624229 Other Community Housing Services ", value: "624229" },
  { key: "624230 Emergency and Other Relief Services ", value: "624230" },
  { key: "624310 Vocational Rehabilitation Services ", value: "624310" },
  { key: "624410 Child Care Services ", value: "624410" },
  { key: "711110 Theater Companies and Dinner Theaters ", value: "711110" },
  { key: "711120 Dance Companies ", value: "711120" },
  { key: "711130 Musical Groups and Artists ", value: "711130" },
  { key: "711190 Other Performing Arts Companies ", value: "711190" },
  { key: "711211 Sports Teams and Clubs ", value: "711211" },
  { key: "711212 Racetracks ", value: "711212" },
  { key: "711219 Other Spectator Sports ", value: "711219" },
  { key: "711310 Promoters of Performing Arts, Sports, and Similar Events with Facilities ", value: "711310" },
  { key: "711320 Promoters of Performing Arts, Sports, and Similar Events without Facilities ", value: "711320" },
  { key: "711410 Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures", value: "711410" },
  { key: "711510 Independent Artists, Writers, and Performers ", value: "711510" },
  { key: "712110 Museums ", value: "712110" },
  { key: "712120 Historical Sites", value: "712120" },
  { key: "712130 Zoos and Botanical Gardens ", value: "712130" },
  { key: "712190 Nature Parks and Other Similar Institutions", value: "712190" },
  { key: "713110 Amusement and Theme Parks ", value: "713110" },
  { key: "713120 Amusement Arcades", value: "713120" },
  { key: "713210 Casinos (except Casino Hotels)", value: "713210" },
  { key: "713290 Other Gambling Industries ", value: "713290" },
  { key: "713910 Golf Courses and Country Clubs", value: "713910" },
  { key: "713920 Skiing Facilities", value: "713920" },
  { key: "713930 Marinas", value: "713930" },
  { key: "713940 Fitness and Recreational Sports Centers ", value: "713940" },
  { key: "713950 Bowling Centers", value: "713950" },
  { key: "713990 All Other Amusement and Recreation Industries ", value: "713990" },
  { key: "721110 Hotels (except Casino Hotels) and Motels ", value: "721110" },
  { key: "721120 Casino Hotels", value: "721120" },
  { key: "721191 Bed-and-Breakfast Inns ", value: "721191" },
  { key: "721199 All Other Traveler Accommodation ", value: "721199" },
  { key: "721211 RV (Recreational Vehicle) Parks and Campgrounds ", value: "721211" },
  { key: "721214 Recreational and Vacation Camps (except Campgrounds) ", value: "721214" }, 
  { key: "721310 Rooming and Boarding Houses, Dormitories, and Workers' Camps ", value: "721310" },
  { key: "722310 Food Service Contractors", value: "722310" },
  { key: "722320 Caterers", value: "722320" },
  { key: "722330 Mobile Food Services", value: "722330" },
  { key: "722410 Drinking Places (Alcoholic Beverages) ", value: "722410" },
  { key: "722511 Full-Service Restaurants ", value: "722511" },
  { key: "722513 Limited-Service Restaurants ", value: "722513" },
  { key: "722514 Cafeterias, Grill Buffets, and Buffets ", value: "722514" },
  { key: "722515 Snack and Nonalcoholic Beverage Bars ", value: "722515" },
  { key: "811111 General Automotive Repair ", value: "811111" },
  { key: "811114 Specialized Automotive Repair ", value: "811114" },
  { key: "811121 Automotive Body, Paint, and Interior Repair and Maintenance ", value: "811121" },
  { key: "811122 Automotive Glass Replacement Shops ", value: "811122" },
  { key: "811191 Automotive Oil Change and Lubrication Shops ", value: "811191" },
  { key: "811192 Car Washes ", value: "811192" },
  { key: "811198 All Other Automotive Repair and Maintenance ", value: "811198" },
  { key: "811210 Electronic and Precision Equipment Repair and Maintenance ", value: "811210" },
  { key: "811310 Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance ", value: "811310" },
  { key: "811411 Home and Garden Equipment Repair and Maintenance ", value: "811411" },
  { key: "811412 Appliance Repair and Maintenance ", value: "811412" },
  { key: "811420 Reupholstery and Furniture Repair", value: "811420" },
  { key: "811430 Footwear and Leather Goods Repair", value: "811430" },
  { key: "811490 Other Personal and Household Goods Repair and Maintenance ", value: "811490" },
  { key: "812111 Barber Shops ", value: "812111" },
  { key: "812112 Beauty Salons ", value: "812112" },
  { key: "812113 Nail Salons ", value: "812113" },
  { key: "812191 Diet and Weight Reducing Centers ", value: "812191" },
  { key: "812199 Other Personal Care Services ", value: "812199" },
  { key: "812210 Funeral Homes and Funeral Services ", value: "812210" },
  { key: "812220 Cemeteries and Crematories ", value: "812220" },
  { key: "812310 Coin-Operated Laundries and Drycleaners ", value: "812310" },
  { key: "812320 Drycleaning and Laundry Services (except Coin-Operated) ", value: "812320" },
  { key: "812331 Linen Supply ", value: "812331" },
  { key: "812332 Industrial Launderers ", value: "812332" },
  { key: "812910 Pet Care (except Veterinary) Services ", value: "812910" },
  { key: "812921 Photofinishing Laboratories (except One-Hour) ", value: "812921" },
  { key: "812922 One-Hour Photofinishing ", value: "812922" },
  { key: "812930 Parking Lots and Garages ", value: "812930" },
  { key: "812990 All Other Personal Services ", value: "812990" },
  { key: "813110 Religious Organizations ", value: "813110" },
  { key: "813211 Grantmaking Foundations ", value: "813211" },
  { key: "813212 Voluntary Health Organizations ", value: "813212" },
  { key: "813219 Other Grantmaking and Giving Services ", value: "813219" },
  { key: "813311 Human Rights Organizations ", value: "813311" },
  { key: "813312 Environment, Conservation and Wildlife Organizations ", value: "813312" },
  { key: "813319 Other Social Advocacy Organizations ", value: "813319" },
  { key: "813410 Civic and Social Organizations ", value: "813410" },
  { key: "813910 Business Associations ", value: "813910" },
  { key: "813920 Professional Organizations ", value: "813920" },
  { key: "813930 Labor Unions and Similar Labor Organizations ", value: "813930" },
  { key: "813940 Political Organizations ", value: "813940" },
  { key: "813990 Other Similar Organizations (except Business, Professional, Labor, and Political Organizations) ", value: "813990" },
  { key: "814110 Private Households", value: "814110" },
  { key: "921110 Executive Offices ", value: "921110" },
  { key: "921120 Legislative Bodies ", value: "921120" },
  { key: "921130 Public Finance Activities ", value: "921130" },
  { key: "921140 Executive and Legislative Offices, Combined ", value: "921140" },
  { key: "921150 American Indian and Alaska Native Tribal Governments ", value: "921150" },
  { key: "921190 Other General Government Support ", value: "921190" },
  { key: "922110 Courts ", value: "922110" },
  { key: "922120 Police Protection ", value: "922120" },
  { key: "922130 Legal Counsel and Prosecution ", value: "922130" },
  { key: "922140 Correctional Institutions ", value: "922140" },
  { key: "922150 Parole Offices and Probation Offices ", value: "922150" },
  { key: "922160 Fire Protection ", value: "922160" },
  { key: "922190 Other Justice, Public Order, and Safety Activities ", value: "922190" },
  { key: "923110 Administration of Education Programs ", value: "923110" },
  { key: "923120 Administration of Public Health Programs ", value: "923120" },
  { key: "923130 Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs) ", value: "923130" },
  { key: "923140 Administration of Veterans' Affairs ", value: "923140" },
  { key: "924110 Administration of Air and Water Resource and Solid Waste Management Programs ", value: "924110" },
  { key: "924120 Administration of Conservation Programs ", value: "924120" },
  { key: "925110 Administration of Housing Programs ", value: "925110" },
  { key: "925120 Administration of Urban Planning and Community and Rural Development ", value: "925120" },
  { key: "926110 Administration of General Economic Programs ", value: "926110" },
  { key: "926120 Regulation and Administration of Transportation Programs ", value: "926120" },
  { key: "926130 Regulation and Administration of Communications, Electric, Gas, and Other Utilities ", value: "926130" },
  { key: "926140 Regulation of Agricultural Marketing and Commodities ", value: "926140" },
  { key: "926150 Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors ", value: "926150" },
  { key: "927110 Space Research and Technology ", value: "927110" },
  { key: "928110 National Security ", value: "928110" },
  { key: "928120 International Affairs ", value: "928120" },
  ],
  "AccountTypes": [
    { key: 'Savings/ Money Market', value: 'savings' },
    { key: 'Checking', value: 'checking' },
    { key: 'TCD', value: 'tcd' },
    { key: 'Other', value: 'other' }

  ],
  "LoanTypes": [
    { key: 'Loan', value: 'loan' },
    { key: 'Line of Credit', value: 'line' },
    { key: 'Mortgage', value: 'mortgage' },
    { key: 'Business Credit Card', value: 'credit-card' },
    { key: 'Other', value: 'other' },
  ],
  "AssetTypes": [
    { key: 'Checking', value: 'checking' },
    { key: 'Savings', value: 'savings' },
    { key: 'Auto', value: 'auto' },
    { key: 'Real Estate', value: 'real-estate' },
    { key: 'Liability/Debt', value: 'debt' },
    { key: 'Cash Value Life Insurance', value: 'life-insurance' },
    // { key: 'TODO...', value: 'TODO' }
  ],
  "USStates": [
    { key: 'HI', value: 'HI' },
    { key: 'GU', value: 'GU' },
    { key: 'MP', value: 'MP' }
  ],
  "Countries": [
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "China, Hong Kong Special Administrative Region",
    "China, Macao Special Administrative Region",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Curacao",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guinea",
    "French Polynesia",
    "French Southen Territories",
    "Gabon",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea-Bissau",
    "Guinea",
    "Gutana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxemborg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar (Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Macedonia",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "OTHER",
    "Pakistan",
    "Palau",
    "Panama",
    "Papu New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Barthelemy",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Sark",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singaport",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "State of Palestine",
    "Sudan",
    "Sudan, South",
    "Suriname",
    "Svalbard and Jan Mayen Islands",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "The Bahamas",
    "The Gambia",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States Minor Outlying Islands",
    "United States Virgin Islands",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Wallis and Futuna Islands",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "OTHER",
  ],
  "HawaiiZipcodes": [
    96747, 96812, 96703, 96759, 96854, 96746, 96732, 96785, 96827, 96858, 96739, 96825, 96708, 96850,
    96810, 96815, 96821, 96719, 96722, 96790, 96742, 96861, 96766, 96765, 96857, 96778, 96761, 96777,
    96786, 96760, 96860, 96709, 96820, 96772, 96720, 96730, 96781, 96726, 96710, 96830, 96728, 96769,
    96731, 96715, 96835, 96795, 96809, 96734, 96848, 96738, 96802, 96803, 96714, 96743, 96712, 96721,
    96727, 96838, 96849, 96792, 96823, 96797, 96725, 96750, 96817, 96749, 96745, 96784, 96822, 96757,
    96828, 96841, 96863, 96801, 96770, 96780, 96764, 96844, 96763, 96737, 96767, 96717, 96779, 96840,
    96762, 96733, 96704, 96808, 96819, 96816, 96741, 96748, 96706, 96783, 96753, 96807, 96788, 96776,
    96836, 96847, 96716, 96782, 96804, 96713, 96826, 96793, 96839, 96824, 96751, 96859, 96755, 96754,
    96806, 96718, 96705, 96843, 96729, 96789, 96773, 96814, 96837, 96818, 96771, 96707, 96853, 96791,
    96740, 96796, 96846, 96813, 96811, 96744, 96752, 96805, 96756, 96774, 96768, 96701, 96898
  ],
  "GuamZipcodes": [
    96910, 96912, 96913, 96915, 96916, 96917, 96919, 96921, 96923, 96928, 96929, 96931, 96932
  ],
  "SaipanZipcodes": [
    96950
  ],
  "CNMIZipcodes": [
    96951, 96952
  ],
  "AreaCodes": [
    201, 202, 203, 205, 206, 207, 208, 209, 210, 212, 213, 214, 215, 216, 217, 218, 219, 220, 223, 224, 225, 228, 229, 231,
    234, 239, 240, 248, 251, 252, 253, 254, 256, 260, 262, 267, 269, 270, 272, 276, 278, 281, 283,
    301, 302, 303, 304, 305, 307, 308, 309, 310, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 323, 325, 330, 331, 332,
    334, 336, 337, 339, 340, 341, 346, 347, 351, 352, 360, 361, 369, 380, 385, 386,
    401, 402, 404, 405, 406, 407, 408, 409, 410, 412, 413, 414, 415, 417, 419, 423, 424, 425, 430, 432, 434, 435, 440, 442,
    443, 458, 464, 469, 470, 475, 478, 479, 480, 484,
    501, 502, 503, 504, 505, 507, 508, 509, 510, 512, 513, 515, 516, 517, 518, 520, 530, 539, 540, 541, 551, 557, 559, 561,
    562, 563, 564, 567, 570, 571, 573, 574, 575, 580, 585, 586,
    601, 602, 603, 605, 606, 607, 608, 609, 610, 612, 614, 615, 616, 617, 618, 619, 620, 623, 626, 627, 628, 629, 630, 631,
    636, 641, 646, 650, 651, 657, 660, 661, 662, 667, 669, 670, 671, 678, 679, 681, 682, 684, 689,
    701, 702, 703, 704, 706, 707, 708, 712, 713, 714, 715, 716, 717, 718, 719, 720, 724, 725, 727, 731, 732, 734, 737, 740,
    743, 747, 754, 757, 760, 762, 763, 764, 765, 769, 770, 772, 773, 774, 775, 779, 781, 782, 784, 785, 786, 787,
    801, 802, 803, 804, 805, 806, 808, 810, 812, 813, 814, 815, 816, 817, 818, 819, 825, 828, 830, 831, 832, 835, 843, 845,
    847, 848, 850, 856, 857, 858, 859, 860, 862, 863, 864, 865, 870, 872, 873, 878,
    901, 903, 904, 906, 907, 908, 909, 910, 912, 913, 914, 915, 916, 917, 918, 919, 920, 925, 927, 928, 929, 931, 935, 936,
    937, 939, 940, 941, 947, 949, 951, 952, 954, 956, 957, 959, 970, 971, 972, 973, 975, 978, 979, 980, 984, 985, 989,
  ],
  radioInputValues: [
    { text: 'Yes', value: 'yes'},
    { text: 'No', value: 'no'} 
  ],
  radioInputValuesTwo: [
    { text: 'Yes', value: 'Y'},
    { text: 'No', value: 'N'} 
  ],
  radioInputValuesThree: [
    { text: 'Rent', value: 'rent'},
    { text: 'Own', value: 'own'} 
  ],
  residencyRadioValues: [
    { text: 'U.S Citizen', value: 'us'},
    { text: 'Permanent resident', value: 'permanent'},
    { text: 'Non-permanent resident', value: 'non-permanent'}  
  ]

}